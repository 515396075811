/** 
*   Holo Maint - Theme Colors
**/
.users-list-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 500px) {
    .users-list-container {
      padding-top: 12px; }
      .users-list-container .users-list-actionbar button {
        width: 100%; } }
  .users-list-container .doc-tool-sort-button {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center; }
  .users-list-container .doc-tool-sort-button-flip {
    transform: scaleY(-1);
    padding-left: 8px;
    padding-right: 8px;
    text-align: center; }
  .users-list-container .users-list-actionbar {
    display: flex;
    justify-content: space-between; }
  .users-list-container .users-list-items {
    display: flex;
    flex-wrap: wrap; }
    .users-list-container .users-list-items .item-display-container {
      height: 270px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .users-list-container .users-list-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .users-list-container .users-list-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .users-list-container .users-list-items .item-display-container {
          width: 80%; } }

.users-list {
  padding-top: 10px; }
