.text-props {
    position: absolute;
    top: 50%;
    left: 50%;
    // margin: -35px 0 0 -35px;
    transform: translate(-50%, -50%);
    border-style: double;
    padding: 5%;
    font-size: 150%;
}

.pointer:hover {
    cursor: pointer;
}

#text-title-props{
    color:rgb(119, 38, 38)
}
