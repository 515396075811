i.modal-icon {
	padding: 10px;
	font-size: 35px;
}

.modal-center {
	display: flex;
	justify-content: flex-start;
	align-content: center;
	flex-direction: row;
	margin: 0 auto;

	span {
		padding-top: 25px;
	}

	.name {
		margin-left: 7px;
		text-transform: capitalize;
	}
}

.modal-full {
	display: flex;
	justify-content: flex-start;
	align-content: center;
	flex-direction: row;
	margin: 0 auto;
	width: 100%;

	span {
		padding-top: 25px;
	}

	.name {
		margin-left: 7px;
		text-transform: capitalize;
	}
}

.modal-block {
	display: flex;
	justify-content: flex-start;
	align-content: center;
	flex-direction: row;

	span {
		padding-top: 25px;
	}

	.name {
		margin-left: 7px;
		text-transform: capitalize;
	}
}
