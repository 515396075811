.container-parent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
}

.banner-div {
    background-image: url(/images/OLO2DT0.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    width: 45vmin;
    padding: 5%;
}

.landing-page-card-parent {
    display: flex;
    flex-direction: column;
}

.landing-page-card-header {
    padding: 22px;
    background: #606060;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 20%);
}

.landing-page-card-body {
    padding: 3%;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
}

.parent-button {
    padding: 3%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.submit-button-active{
    border: none;
    background: #C21717;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 20%);
    color: white;
    padding: 0.5vw 2vh;
    font-size: 2rem;
}

.submit-button-disable {
    border: none;
    background: #A8A8A8;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 20%);
    color: white;
    padding: 0.5vw 2vh;
    font-size: 2rem;
}

.input-modified {
    width: 40vmin;
    margin-right: 2rem;
}

.div-form {
    margin-bottom: 2rem;
}