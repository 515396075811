/** 
*   Holo Maint - Theme Colors
**/
.layout-navbar-navlink {
  align-items: center;
  color: lightgray;
  display: flex;
  padding: 12px 18px;
  text-decoration: none; }
  .layout-navbar-navlink:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .layout-navbar-navlink.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .layout-navbar-navlink.active .material-icons.app_icon {
      color: #343a40; }
  .layout-navbar-navlink span.layout-navbar-navlink-text {
    white-space: nowrap; }
  .layout-navbar-navlink .material-icons.app_icon {
    margin-right: 18px; }

.edit-company-logo {
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
  top: 138px; }
  .edit-company-logo i.material-icons.app_icon {
    padding: 0;
    width: 25px;
    height: 25px;
    background: dimgray;
    border-radius: 50%;
    font-size: 17px; }
