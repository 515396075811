.licenses-list {
    height: fit-content;
    margin-left: -15px;
    margin-top: 10px;
    min-width:250px;
}

.card-height {
    height: 298px;
}

.scrollable-content {
    padding-right: 3px;
    margin-bottom: 20px;
    height: 298px;
    max-height: 298px;
    overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
    border-style: visible;
    width: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    padding-right: 5px;
    border-radius: 10px;
    margin-bottom: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgb(204, 204, 204);
}

#newLicense {
    margin-top: 10px;
    height: fit-content;
    // width:fit-content;
    // padding:20px;
    animation: fadeIn .6s;
}

.license-card {
    border-color: rgb(209, 209, 209);
    border-style: solid;
    border-width: 1px;
    border-radius: 2%;
    background-color: rgb(246, 246, 246);
}

@keyframes fadeIn {
    0% {
        transform: translate(-1vw,0);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
