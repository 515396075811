/** 
*   Holo Maint - Theme Colors
**/
.ongoing-call-container {
  -webkit-align-items: center;
  border: 1px solid #959595;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  /* position: relative; */
  margin-bottom: 12px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: stretch; }
  .ongoing-call-container .ongoing-call-user-icon {
    border-right: 1px solid #959595;
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .ongoing-call-container .ongoing-call-button-container {
    display: flex;
    flex-direction: column; }
  .ongoing-call-container .ongoing-call-phone-icon-accept {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid #959595;
    background: #b9c900;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    border-radius: 0; }
  .ongoing-call-container .ongoing-call-phone-icon-decline {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid #959595;
    background: #cc0000;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    border-radius: 0; }
  @media screen and (max-width: 500px) {
    .ongoing-call-container {
      height: unset; }
      .ongoing-call-container .ongoing-call-phone-icon-accept {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; }
      .ongoing-call-container .ongoing-call-phone-icon-decline {
        border: 1px solid #959595;
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; }
      .ongoing-call-container .ongoing-call-user-icon {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; } }
  .ongoing-call-container:hover {
    background: rgba(0, 0, 0, 0.05); }
  .ongoing-call-container .ongoing-call-phone-icon-accept:hover {
    background: #b9c900 !important;
    color: white; }
  .ongoing-call-container .ongoing-call-phone-icon-decline:hover {
    background: #cc0000 !important;
    color: white; }
  .ongoing-call-container .ongoing-call-text-container {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    flex-grow: 1; }
    .ongoing-call-container .ongoing-call-text-container .ongoing-call-info-container {
      display: grid;
      grid-template-columns: 1fr;
      flex-grow: 1; }
    .ongoing-call-container .ongoing-call-text-container p {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      margin: 0; }
    .ongoing-call-container .ongoing-call-text-container span {
      font-size: 10px;
      text-transform: uppercase;
      text-align: end; }
