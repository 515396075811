@import "../../scss/custom-variables";

.layout-navbar-navlink {
	align-items: center;
	color: lightgray;
	display: flex;
	padding: 12px 18px;
	text-decoration: none;

	&:hover {
		background: $gray;
		color: $primary;
		text-decoration: none;
	}

	&.active {
		background: $body-bg;
		color: $primary;
		text-decoration: none;

		.material-icons.app_icon {
			color: $primary;
		}
	}

	span.layout-navbar-navlink-text {
		white-space: nowrap;
	}

	.material-icons.app_icon {
		margin-right: 18px;
	}
}

.edit-company-logo {
	background: none;
    border: none;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 25px;
	top: 138px;
	
	i.material-icons.app_icon {
		padding: 0;
    	width: 25px;
    	height: 25px;
    	background: dimgray;
    	border-radius: 50%;
   		font-size: 17px;
	}
}
