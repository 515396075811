.modal-window {
    background: #232323; 
    // position: fixed; 
    margin: 0px; 
    width: fit-content; 
    z-index: 9999; 
    display: flex; 
    align-items: center;
    justify-content: center;
    
    .div {
        width: 100%;
        height: 100%;
        margin-left: -64px;
        margin-top: -128px;
        position: absolute;

        .spinner  {
            margin-left: -20%; 
            margin-top: 35vh; 
            margin-bottom: auto; 
            height: 128px; 
            width: 128px;
        }
    }
    
}