.troubleshooting-answer-edit-node-container {
	background: #fff;
	display: flex;
	flex-direction: column;

	.troubleshooting-answer-edit-node-header {
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		padding: 8px 24px;

		.troubleshooting-answer-edit-node-header-text {
			font-size: 16px;
		}

		.troubleshooting-answer-edit-node-header-buttons {
			margin-left: auto;
		}
	}

	.troubleshooting-answer-edit-node-body {
		padding: 8px 24px;
	}

	.troubleshooting-answer-editor-textarea {
		font-size: 12px;
	}
}
