.app-stretched-image {
	display: block;
	position: relative;
	min-width: 80px;
	min-height: 80px;

	img {
		height: 100%;
		object-fit: contain;
		object-position: center;
		width: 100%;
	}
}
