/** 
*   Holo Maint - Theme Colors
**/
.body-content {
  margin-left: 60px;
  height: 100%;
  transition: margin 500ms cubic-bezier(0.19, 1, 0.22, 1); }
  .body-content.nav-open {
    margin-left: 220px; }
  .body-content.mobile {
    margin-left: 0; }
