/** 
*   Holo Maint - Theme Colors
**/
.products-list-container {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 500px) {
    .products-list-container {
      padding-top: 12px; }
      .products-list-container .products-list-actionbar button {
        width: 100%; } }
  .products-list-container .products-list-actionbar {
    padding: 0 12px; }
  .products-list-container .products-list-items {
    display: flex;
    flex-wrap: wrap; }
    .products-list-container .products-list-items .item-display-container {
      height: 280px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .products-list-container .products-list-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .products-list-container .products-list-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .products-list-container .products-list-items .item-display-container {
          width: 100%; } }

.products-list-container-mobile {
  display: flex;
  flex-direction: column; }

.products-list-container-mobile-active-call {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 12px;
  border-radius: 10px; }

.product_card_header {
  align-items: center;
  display: flex;
  min-height: 62px; }
  .product_card_header .button-container {
    margin-left: auto; }

.search-bar {
  padding: 10px;
  text-align: right;
  margin-right: 15px; }
  @media only screen and (max-device-width: 700px) and (orientation: portrait) {
    .search-bar {
      text-align: center;
      padding: 10px;
      margin-right: 0px; }
      .search-bar .input {
        width: calc(100% - 95px); } }

.qr-code-button {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%; }
  .qr-code-button :focus {
    outline: none;
    -webkit-tap-highlight-color: transparent; }
  .qr-code-button button {
    align-items: center;
    right: 12px;
    bottom: 24px;
    background: #fff;
    border: 4px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    margin: 0 12px;
    outline: none;
    opacity: 1;
    justify-content: center;
    position: fixed;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 64px;
    width: 64px; }
    .qr-code-button button.icon-primary i.material-icons {
      color: #343a40; }
    .qr-code-button button.icon-primary-2 {
      left: calc(100% - 170px); }
    .qr-code-button button i.material-icons {
      color: #343a40;
      font-size: 28px;
      line-height: 1;
      user-select: none; }
