.div-current-model {
    position: fixed; 
    margin: 0;
    width: 100vw; 
    height: 100%;
    z-index:9999;
}

.toast-message {
    min-width: 250px; 
    top: 25%; 
    left: 50%; 
    margin-right: -50%; 
    transform: translate(-50%, -50%); 
    background-color: #00000088; 
    color: #fff; 
    text-align: center; 
    border-radius: 22px; 
    padding: 16px; 
    position: absolute; 
    z-index: 1; 
    font-size: 17px;
}

.div-spinner {
    width: 100%;
    height: 100%;
    margin-left: -64px;
    margin-top: -128px;
    position: absolute;

    .spinner  {
        margin-left: 50%; 
        margin-top: 40vh; 
        margin-bottom: auto; 
        height: 128px; 
        width: 128px;
    }
}

.buttons-container {
    position: absolute; 
    margin: 16px; 
    top: calc(100% - 115px); 
    left: 50%;

    &.hidden {
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }

    .anim-visualizer-model {
        left: -5px; 
        height: 48px; 
        width: 48px;

    }

    .next-button-model {
        left: 50px; 
        height: 48px; 
        width: 48px;

        i.material-icons {
            font-size: 24px;
        }
    }

    .prev-button-model {
        left: -60px; 
        height: 48px; 
        width: 48px;

        i.material-icons {
            font-size: 24px;
        }
    }

    .play-button-model {
        left: -115px; 
        height: 48px; 
        width: 48px;

        i.material-icons {
            font-size: 24px;
        }
    }
}

.voice-button-model {
    margin-left: auto; 
    margin-right: 0px; 
    margin-top: auto; 
    bottom: 96px; 
    height: 48px; 
    width: 48px;

    i.material-icons {
        font-size: 24px;
    }
}

.ar-button-style {
    font-size: 15px; 
    top: calc(100% - -21px); 
    background-color: white; 
    position: absolute; 
    bottom: 20px; 
    border: 1px solid rgb(255, 255, 255); 
    border-radius: 43px; 
    color: rgb(0, 0, 0); 
    font-weight: bold; 
    font: 13px sans-serif bold; 
    text-align: center; 
    outline: none; 
    z-index: 999; 
    cursor: auto; 
    left: calc(100% - 169px); 
    width: 45px; 
    height: 45px;
}

.active-call-current-model {
    position: fixed;
    margin: 0;
    width: 100%; 
    height: 100%;
    z-index:9999;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: white;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: darkgray;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: darkgray;
    cursor: pointer;
  }
