/** 
*   Holo Maint - Theme Colors
**/
.team-item-row {
  background: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex; }
  .team-item-row:active, .team-item-row:hover {
    background: #dedede; }
    .team-item-row:active .material-icons, .team-item-row:hover .material-icons {
      border-right: 1px solid #efefef; }
  .team-item-row .material-icons {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px; }
  .team-item-row span.team-item-row-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .team-item-row span.team-item-row-name {
        font-size: 13px; } }
  .team-item-row span.team-item-row-name-inactive {
    color: #aaaaaa;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .team-item-row span.team-item-row-name-inactive {
        font-size: 13px; } }
  .team-item-row span.file-item-row-date_created {
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    margin-left: auto;
    margin-right: 12px; }
