/** 
*   Holo Maint - Theme Colors
**/
.timeline {
  margin-top: 50px; }

.timeline-block {
  position: relative;
  margin: 45px 0; }
  .timeline-block:first-child {
    margin-top: 0; }
  .timeline-block:last-child:before {
    height: 0; }
  .timeline-block:before {
    content: "";
    position: absolute;
    left: 24px;
    height: calc(100% + 45px);
    width: 4px;
    background: #dedede;
    box-sizing: border-box; }
  .timeline-block:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 60px;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff; }

.timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #dedede;
  text-align: center;
  background-color: #ffffff !important;
  color: #343a40; }
  .timeline-icon .material-icons {
    line-height: 1.9 !important; }

.timeline-content {
  position: relative;
  margin-left: 70px;
  background: #ffffff;
  border-radius: 0.25em;
  padding: 1em; }
  .timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-right: 7px soli #ffffff; }

.app-stretched-image-mobile {
  display: block;
  position: relative;
  top: 50%;
  height: 80px;
  width: 80px;
  min-width: 80px;
  min-height: 80px; }
  .app-stretched-image-mobile .icon {
    height: 100%;
    object-fit: contain;
    object-position: left;
    width: 100%; }

.item-display-container-mobile {
  flex-direction: column; }

.form-control-help {
  box-shadow: none;
  background: #fdfdfd;
  transition: background-color 0.2s ease, padding-left 0.2s linear;
  padding-left: 0.75rem;
  width: 75%;
  overflow-y: scroll;
  min-height: 50vh; }

.form-group label {
  font-weight: 600;
  font-size: 0.9rem; }

.input-group > .form-control {
  height: 50px; }

textarea {
  resize: vertical;
  overflow: auto;
  min-height: 150px; }

label {
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0; }

.form-control-help:disabled {
  background: none;
  border: none;
  padding-left: 0;
  transition: background-color 0.2s ease, padding-left 0.2s linear; }

.product-card-container-mobile {
  display: flex;
  background: #ffffff;
  margin-bottom: 10px;
  position: relative;
  height: 100%; }
  .product-card-container-mobile .product-card-header-mobile {
    display: flex;
    padding-left: 12px;
    padding-top: 20px;
    overflow: hidden; }
    .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      user-select: none; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
  .product-card-container-mobile .product-card-header-product-mobile {
    display: flex;
    padding-left: 12px;
    padding-top: 50px;
    overflow: hidden; }
    .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      user-select: none; }
      .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
      .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
  .product-card-container-mobile .product-card-description-mobile {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
