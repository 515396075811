.product-tab-qrcode-container {
	display: flex;
	flex-direction: column;
	padding: 12px;
	padding-top: 35px;
	position: relative;

	margin-left: 20%;
	margin-right: 20%;
}

.product-tab-qrcode-outer {
	display: block;
	background: #fff;

	.product-tab-qrcode-name {
		display: none;
	}

	.product-tab-qrcode-description {
		text-align: center;
		padding: 20px;
		align-items: center;
		justify-content: center;
		display: flex;
		margin-top: 12px;
		font-size: 100%;
	}

	&.overlay {
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99999;

		.product-tab-qrcode-name {
			display: block;
			margin-bottom: 12px;
			font-size: 300%;
		}

		.product-tab-qrcode-description {
			display: none;
		}

		.product-tab-qrcode-container {
			height: 70% !important;
			width: 70%;
		}

		.app_icon_button.no-print {
			display: none;
		}

		.app_icon_button.done-print {
			display: block;
			position: absolute;
			top: 12px;
			right: 12px;
		}

		@media print {
			.app_icon_button.done-print {
				display: none;
			}
		}
	}

	.app_icon_button {
		display: block;
		height: auto;
		width: auto;
		padding: 12px 0;
		margin: 0 auto;

		&.done-print {
			display: none;
		}

		i.material-icons {
			font-size: 48px;
			line-height: 48px;
		}
	}
}

.product-tab-qrcode-canvas {
	height: 100%;
	width: 100%;
}

.product-tab-qrcode-border {
	// Setting up image width.
	//width: auto;

	// Setting up image height.
	//height: 100%;

	padding: 10%;

	border-right: 6px solid #28a745;
	border-top: 6px solid #28a745;
	border-left: 6px solid #28a745;
	border-bottom: 6px solid #28a745;
}

@media print {
}
