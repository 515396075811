/** 
*   Holo Maint - Theme Colors
**/
.companies-list-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 500px) {
    .companies-list-container {
      padding-top: 12px; }
      .companies-list-container .companies-list-actionbar button {
        width: 100%; } }
  .companies-list-container .companies-list-actionbar {
    display: flex;
    justify-content: space-between; }
  .companies-list-container .companies-list-items {
    display: flex;
    flex-wrap: wrap; }
    .companies-list-container .companies-list-items .item-display-container {
      height: 270px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .companies-list-container .companies-list-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .companies-list-container .companies-list-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .companies-list-container .companies-list-items .item-display-container {
          width: 80%; } }

.company-detail-container .company-unselected {
  margin-top: 10px;
  height: 600px;
  color: #eee;
  text-align: center;
  justify-content: center;
  border-bottom: 24px solid #dedede;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .company-detail-container .company-unselected i.material-icons.app_icon.company-unselected-icon {
    font-size: 60px; }

.app-detail-container .app-unselected {
  margin-top: 10px;
  height: 300px;
  color: #eee;
  text-align: center;
  justify-content: center;
  border-bottom: 24px solid #dedede;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .app-detail-container .app-unselected i.material-icons.app_icon.app-unselected-icon {
    font-size: 60px; }
