@import "./breakpoints";

html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	overscroll-behavior-y: contain;
}

.material-icons {
	line-height: 1.5 !important;
}

#root {
	height: 100%;
}

.flex-row {
	align-items: center;
	display: flex;
}

[class^="css-"] {
	z-index: 99999 !important;
}

.gutter-right.gutter-right.gutter-right {
	padding-right: 12px;

	@media screen and (max-width: $break-mobile) {
		padding-right: 0;
	}
}

.col-no-gutters.col-no-gutters.col-no-gutters {
	padding-right: 0px;
	padding-left: 0px;
}

.hide-mobile {
	@media screen and (max-width: $break-mobile) {
		display: none;
	}
}

.hide-desktop {
	display: none;
	@media screen and (max-width: $break-mobile) {
		display: block;
	}
}

.button-enabled {
	align-items: center;
	right: 12px;
	bottom: 24px;
	background: #fff;
	border: 4px solid $light;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	margin: 0 12px;
	outline: none;
	opacity: 1;
	justify-content: center;
	position: fixed;
	transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

	height: 64px;
	width: 64px;

	&.icon-primary {
		i.material-icons {
			color: $primary;
		}
	}

	i.material-icons {
		color: $primary;
		font-size: 28px;
		line-height: 1;
		user-select: none;
	}
}

.button-disabled {
	align-items: center;
	right: 12px;
	bottom: 24px;
	background: #fff;
	border: 4px solid $light;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	margin: 0 12px;
	outline: none;
	opacity: 1;
	justify-content: center;
	position: fixed;
	transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

	height: 64px;
	width: 64px;

	&.icon-primary {
		i.material-icons {
			color: $primary;
		}
	}

	i.material-icons {
		color: $primary;
		font-size: 28px;
		line-height: 1;
		user-select: none;
	}
}

.padding-top {
	padding-top: 12px;
}

.padding-top-mobile {
	@media screen and (max-width: $break-mobile) {
		padding-top: 12px;
	}
}

.padding-top-desktop {
	padding-top: 12px;

	@media screen and (max-width: $break-mobile) {
		padding-top: 0;
	}
}
