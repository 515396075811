@import "../../scss/custom-variables";
@import "../../scss/breakpoints";


.modal-ticket-container {
		animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-fill-mode: forwards;
		background: #232323;
		width: 100%;
		height: calc(100vh - 120px);
		position: fixed;
		top: 60px;
		bottom: 60px;
		right: 0;
		z-index: 999;

		&.hidden {
			// animation: hidecam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
			visibility: hidden;
			opacity: 0;
			z-index: -1;
		}

		button {
			align-items: center;
			background: #fff;
			border: 2px solid $light;
			border-radius: 100%;
			cursor: pointer;
			display: flex;
			outline: none;
			justify-content: center;
			padding: 0;
			opacity: 1;
			z-index: 99999;

			position: absolute;
			top: 20px;
			right: 20px;

			height: 26px;
			width: 26px;
			transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

			i.material-icons {
				color: $primary;
				font-size: 18px;
				line-height: 1;
			}
		}
	}
