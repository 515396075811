$animation: background-color 0.2s ease, padding-left 0.2s linear;
// Form Styles
.form-group {
	label {
		font-weight: $label-font-weight;
		font-size: 0.9rem;
	}
}

.form-control {
	box-shadow: none;
	background: #fdfdfd;
	transition: $animation;
	padding-left: 0.75rem;
}

.input-group > .form-control {
	height: 50px;
}

textarea {
	resize: vertical;
	overflow: auto;
	min-height: 150px;
}

label {
	font-weight: $label-font-weight;
	font-size: 0.9rem;
	margin: 0;
}

.form-control:disabled {
	background: none;
	border: none;
	padding-left: 0;
	transition: $animation;
}
