MobileImage.app-stretched-image {
	display: block;
	position: relative;
	min-width: 80px;
	min-height: 80px;

	img {
		height: 100%;
		object-fit: contain;
		object-position: left;
		width: 100%;
	}
}

MobileImage.app-stretched-image-product {
	display: block;
	position: relative;
	min-width: 150px;
	min-height: 150px;

	img {
		height: 100%;
		object-fit: contain;
		object-position: left;
		width: 100%;
	}
}
