@import "../../scss/breakpoints";

.troubleshooting-question-editor-container {
	background: #fff;

	@media screen and (max-width: $break-mobile) {
		display: none;
	}

	.troubleshooting-question-editor-header {
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		padding: 8px 24px;

		.troubleshooting-question-editor-buttons {
			margin-left: auto;
		}
	}

	.troubleshooting-question-editor-body {
		padding: 8px 24px;

		.troubleshooting-question-editor-body-placeholder {
			padding: 24px 0;
			text-align: center;
		}

		.troubleshooting-form-group-container{
			:not():last-child {
				div {
					margin-bottom: 1rem;
				}
			}
		}
	}

	.troubleshooting-question-editor-textarea {
		font-size: 12px;
	}

	

	.troubleshooting-question-editor-actions {
		align-items: center;
		display: flex;
		padding: 0 24px 24px;

		.troubleshooting-question-editor-savebutton {
			margin-left: auto;
		}
	}
}
