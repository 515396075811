@import "../../scss/custom-variables";
@import "../../scss/breakpoints";

@keyframes whiteFlash {
	0% {
		display: block;
		opacity: 1;
	}

	95% {
		opacity: 0;
	}

	100% {
		display: none;
	}
}

@keyframes pulsate {
	0% {
		opacity: 1;
	}

	25% {
		opacity: 0.5;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@keyframes showcam {
	from {
		opacity: 0;
		transform: translateY(-15px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes hidecam {
	from {
		opacity: 1;
		transform: translateY(0);
	}

	to {
		opacity: 0;
		transform: translateY(15px);
	}
}

.change-product-button {
	background: lightgrey;
	border: none;
	border-radius: 5px;
}

.dropup {
	position: relative;
	display: inline-block;
}

.dropup-content {
	display: none;
	position: absolute;
	// background-color: #f1f1f1;
	min-width: 160px;
	bottom: 100%;
	z-index: 1;
}

.dropup-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropup-content a:hover {
	background-color: #ccc;
}

.dropup:hover .dropup-content {
	display: block;
}

.dropdown-resolution-expert {
	position: inherit;
	display: inline-block;
}

.dropdown-resolution-expert-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	left: 0px;
	top: calc(100% - 8px);
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 9999999;
}

.dropdown-resolution-expert a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-resolution-expert-content a:hover {
	background-color: #ccc;
}

.dropdown-resolution-expert:hover .dropdown-resolution-expert-content {
	display: block;
}

.remote_view_hidden {
	visibility: hidden;
}

.div-touch-element {
	display: none;

	@media screen and (max-width: $break-mobile-landscape) {
		display: flex;
	}
}

.landscape-warning {
	display: none;

	@media only screen and (max-device-width: 700px) and (orientation: portrait) {
		align-items: center;
		background: #fff;
		display: flex;
		position: fixed;
		flex-direction: column;
		justify-content: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999999;

		.app_icon {
			font-size: 48px;
		}
	}
}

.active-call-dropzone {
	margin-top: 18px;
	margin-bottom: 18px;
	height: 95px;

	@media screen and (max-width: $break-mobile-landscape) {
		display: none;
	}
}

.active-call-users {
	align-items: center;
	background: #fff;
	border-radius: 100%;
	display: flex;
	margin: 0 12px;
	justify-content: center;
	position: relative;
	height: 64px;
	width: 64px;
	position: absolute;
	z-index: 10;
	top: 30px;
	left: 18px;

	@media screen and (max-width: 700px) {
		height: 50px;
		width: 50px;
		top: 15px;
		left: 5px;
	}

	.badge {
		position: absolute;
		top: 0;
		right: -3px;
		border-radius: 50%;
		height: 25px;
		width: 25px;
		padding: 6px;
		@media screen and (max-width: 700px) {
			height: 20px;
			width: 20px;
			padding: 3px;
			right: -5px;
		}
	}
	.material-icons {
		font-size: 35px;
		color: $primary;
		@media screen and (max-width: 700px) {
			font-size: 30px;
			color: $primary;
		}
	}
}

.active-call-page-container {
	background: $light;
	display: flex;
	flex-direction: row;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 91337;

	&.minimized {
		border-radius: 4px;
		top: auto;
		right: 24px;
		left: auto;
		bottom: 24px;
		height: 180px;
		width: 320px;

		div {
			display: none;
		}

		.active-call-returntocall {
			align-items: center;
			justify-content: center;
			background: rgba(0, 0, 0, 0.5);
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: opacity 200ms;

			.app_icon {
				color: $white;
				font-size: 36px;
				line-height: 1;
				user-select: none;
			}
		}

		&:hover {
			.active-call-returntocall {
				opacity: 1;
			}
		}

		.active-call-page-remotevideo-container {
			display: block;
			margin: 0;
		}

		.active-call-page-left-column {
			display: flex;
		}
	}

	&.annotation-mode {
		.active-call-page-remotevideo-container {
			position: absolute;
			top: 64px + 16px;
			right: 18px;
			max-height: 170px;
			pointer-events: none;
			width: 230px;
		}

		.active-call-page-remotevideo-menu {
			display: none;
		}

		.active-call-page-localvideo-container {
			display: none;
		}
	}

	.active-call-page-annotation-container {
		background: rgba(0, 0, 0, 0.75);
		border-radius: 4px;
		display: none;
		flex: 1;
		position: relative;
		margin: 18px;
		overflow: hidden;

		&.annotation-mode {
			display: block;

			.flash-container {
				animation: whiteFlash 1500ms;
				animation-fill-mode: forwards;
				background: #fff;
				pointer-events: none;
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				opacity: 0;
				height: 100%;
				width: 100%;
			}
		}

		.active-call-page-annotation-size {
			animation: showcam 500ms;
			animation-fill-mode: forwards;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 18px;
			position: absolute;
			right: 0;
			top: 32px;

			button {
				border-radius: 100%;
				height: 48px;
				cursor: pointer;
				outline: 0;
				width: 48px;
				margin-bottom: 12px;
			}
		}

		.active-call-page-annotation-sizePreview {
			animation: showcam 500ms;
			animation-fill-mode: forwards;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 18px;
			position: absolute;
			right: 350px;
			top: 16px;
			align-items: center;
			.material-icons {
				align-self: center;
				line-height: 0;
			}
		}

		.active-call-page-annotation-colors {
			animation: showcam 500ms;
			animation-fill-mode: forwards;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 18px;
			position: absolute;
			right: 0;
			top: 0;

			button {
				border-radius: 100%;
				height: 48px;
				cursor: pointer;
				outline: 0;
				width: 48px;
				margin-bottom: 12px;

				&.color-1 {
					background: $success;
					border: 4px solid $success;
				}

				&.color-2 {
					background: $warning;
					border: 4px solid $warning;
				}

				&.color-3 {
					background: $danger;
					border: 4px solid $danger;
				}

				&.active {
					border: 4px solid $white;
				}
			}
		}

		.active-call-page-annotation-menu {
			animation: showcam 500ms;
			animation-fill-mode: forwards;
			align-items: center;
			display: flex;
			position: absolute;
			bottom: 0;
			left: 0;
			justify-content: space-between;
			right: 0;
			padding: 18px;

			.active-call-page-annotation-menu-section {
				align-items: center;
				display: flex;
				justify-content: space-evenly;

				button {
					align-items: center;
					background: #fff;
					border: 4px solid $light;
					border-radius: 100%;
					cursor: pointer;
					display: flex;
					margin: 0 12px;
					outline: none;
					justify-content: center;
					position: relative;
					transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

					height: 64px;
					width: 64px;

					@media screen and (max-width: 1050px) {
						height: 46px;
						width: 46px;
						opacity: 1;
					}

					&.active {
						border: 4px solid $success;
					}

					&.lg {
						height: 86px;
						width: 86px;

						@media screen and (max-width: 1050px) {
							height: 64px;
							width: 64px;
							opacity: 1;
						}
						i.material-icons {
							font-size: 36px;
						}
					}

					&.icon-red {
						i.material-icons {
							color: $danger;
						}
					}

					&.icon-green {
						i.material-icons {
							color: $success;
						}
					}

					i.material-icons {
						color: $primary;
						font-size: 28px;
						line-height: 1;
					}
				}
			}
		}

		.active-call-page-annotation-canvas {
			border-radius: 4px;
			cursor: crosshair;
			display: block;
			height: 100%;
			object-position: center;
			object-fit: contain;
			width: 100%;
		}
	}

	.fullscreen-info {
		animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-fill-mode: forwards;
		position: absolute;
		top: 18px;
		left: 18px;
		width: 350px;
	}
}

.modal-window-drag-anchor {
	position: absolute;
	bottom: 0;
	z-index: 10000;
	right: 0;
	cursor: grab;
}

.modal-window-drag-anchor:active {
	cursor: grabbing;
}

.active-call-page-left-column {
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
	width: calc(100% - 350px);
}

.active-call-page-3d-modal-container {
	animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
	background-color: #232323;
	width: 100%;
	height: 100%;
	left: 0;
	// height: calc(100vh - 60px);
	position: absolute;
	text-align: center;
	top: 0; // top: 60px;
	right: 0;
	z-index: 999999;

	&.hidden {
		// animation: hidecam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
		visibility: hidden;
		opacity: 0;
		z-index: -1;
	}

	button {
		align-items: center;
		background: #fff;
		border: 2px solid $light;
		border-radius: 100%;
		cursor: pointer;
		display: flex;
		outline: none;
		justify-content: center;
		padding: 0;
		opacity: 1;
		z-index: 99999;

		position: absolute;
		top: 20px;
		right: 20px;

		height: 26px;
		width: 26px;
		transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

		i.material-icons {
			color: $primary;
			font-size: 18px;
			line-height: 1;
			user-select: none;
		}
	}
}

.active-call-page-modal-container {
	animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
	background-color: #232323;
	width: 400px;
	height: fit-content;
	left: 0;
	// height: calc(100vh - 60px);
	position: absolute;
	top: 25%; // top: 60px;
	right: 0;
	z-index: 999;
	border-radius: 10px;

	@media screen and (max-width: $break-mobile-landscape) {
		animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-fill-mode: forwards;
		background-color: #232323;
		width: 100%;
		left: auto;
		height: 100%;
		position: absolute;
		top: 0px;
		right: 0;
		z-index: 999;
	}

	&.hidden {
		// animation: hidecam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
		visibility: hidden;
		opacity: 0;
		z-index: -1;
	}

	button {
		align-items: center;
		background: #fff;
		border: 2px solid $light;
		border-radius: 100%;
		cursor: pointer;
		display: flex;
		outline: none;
		justify-content: center;
		padding: 0;
		opacity: 1;
		z-index: 99999;

		position: absolute;
		top: 20px;
		right: 20px;

		height: 26px;
		width: 26px;
		transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

		i.material-icons {
			color: $primary;
			font-size: 18px;
			line-height: 1;
			user-select: none;
		}
	}
}

.active-call-page-remotevideo-container {
	background: rgba(0, 0, 0, 0.75);
	border-radius: 4px;
	display: block;
	flex: 1;
	position: relative;
	margin: 18px;
	overflow: hidden;

	@media screen and (max-width: 700px) {
		margin: 0px;
	}

	&:hover {
		.active-call-page-remotevideo-menu {
			.active-call-page-remotevideo-menu-section {
				button {
					opacity: 1;
					margin: 0px 7px;
				}
			}
		}
	}

	.active-call-page-remotevideo-menu {
		align-items: center;
		display: flex;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		justify-content: space-between;
		right: 0;
		padding: 18px;

		@media screen and (max-width: 700px) {
			padding: 10px;
		}

		.active-call-page-remotevideo-menu-section {
			align-items: center;
			display: flex;
			justify-content: space-evenly;

			button {
				align-items: center;
				background: #fff;
				border: 4px solid $light;
				border-radius: 100%;
				cursor: pointer;
				display: flex;
				margin: 0 7px;
				outline: none;
				opacity: 0;
				justify-content: center;
				position: relative;
				transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

				height: 64px;
				width: 64px;

				@media screen and (max-width: 1050px) {
					height: 46px;
					width: 46px;
					opacity: 1;
				}

				.recorded-videos {
					background: #f1f1f1;
					border-radius: 100%;
					display: flex;
					position: absolute;
					align-items: center;
					left: 4px;
					top: -4px;
					height: 18px;
					width: 18px;
					justify-content: center;
					font-size: 12px;

					@media screen and (max-width: 700px) {
						border: 1px solid #e0e0e0;
						left: 0px;
						top: -4px;
					}
				}

				.pulse-recording {
					animation: pulsate 3s;
					animation-iteration-count: infinite;
					background: $danger;
					border-radius: 100%;
					position: absolute;
					right: 4px;
					top: -4px;
					height: 14px;
					width: 14px;

					@media screen and (max-width: 700px) {
						height: 8px;
						width: 8px;
					}
				}

				&.lg {
					height: 86px;
					width: 86px;

					@media screen and (max-width: 1050px) {
						height: 64px;
						width: 64px;
					}

					i.material-icons {
						font-size: 36px;

						@media screen and (max-width: 700px) {
							font-size: 36px;
						}
					}
				}

				&.icon-red {
					i.material-icons {
						color: $danger;
					}
				}

				i.material-icons {
					color: $primary;
					font-size: 28px;
					line-height: 1;

					@media screen and (max-width: 700px) {
						font-size: 20px;
					}
				}
			}
		}
	}

	video.active-call-page-remotevideo-element {
		border-radius: 4px;
		height: 100%;
		object-position: center;
		object-fit: contain;
		width: 100%;
	}

	video.active-call-page-remotevideo-element-second-vis {
		border-radius: 4px;
		height: 50%;
		object-position: center;
		object-fit: contain;
		width: 100%;
	}

	video.active-call-page-localvideo-element-third-vis {
		max-width: 100%;
		height: 100%;
		object-position: center;
		object-fit: contain;
		// width: 100%;
	}

	.wrapper-one-participant {
		display: grid;
		grid-template-areas:
			"operator expert"
			"participant participant";
		text-align: center;
		overflow-y: auto;
		height: 100%;
		width: 100%;

		.item1 {
			grid-area: operator;
		}

		.item2 {
			grid-area: expert;
		}

		.item3 {
			grid-area: participant;
		}
	}

	.wrapper-two-participants {
		display: grid;
		grid-template-areas:
			"operator expert"
			"participant participant2";
		text-align: center;
		overflow-y: auto;
		height: 100%;
		width: 100%;

		.item1 {
			grid-area: operator;
		}

		.item2 {
			grid-area: expert;
		}

		.item3 {
			grid-area: participant;
		}

		.item4 {
			grid-area: participant2;
		}
	}

	.wrapper-three-participants {
		display: grid;
		grid-template-areas:
			"operator operator expert expert participant participant"
			"B participant2 participant2 participant3 participant3 A";
		text-align: center;
		overflow-y: auto;
		height: 100%;
		width: 100%;

		.item1 {
			grid-area: operator;
		}

		.item2 {
			grid-area: expert;
		}

		.item3 {
			grid-area: participant;
		}

		.item4 {
			grid-area: participant2;
		}

		.item5 {
			grid-area: participant3;
		}
	}

	.wrapper-four-participants {
		display: grid;
		grid-template-areas:
			"operator expert participant"
			"participant2  participant3 participant4";
		text-align: center;
		overflow-y: auto;
		height: 100%;
		width: 100%;

		.item1 {
			grid-area: operator;
		}

		.item2 {
			grid-area: expert;
		}

		.item3 {
			grid-area: participant;
		}

		.item4 {
			grid-area: participant2;
		}

		.item5 {
			grid-area: participant3;
		}

		.item6 {
			grid-area: participant4;
		}
	}

	.active-call-page-localvideo-container {
		animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-fill-mode: forwards;
		background: transparent;
		max-height: 80%;
		position: absolute;
		top: 18px;
		right: 18px;
		width: 200px;
		left: calc(100% - 200px);
		overflow-y: auto;
		//transform: rotateY(180deg);

		@media screen and (max-width: 700px) {
			max-height: 80px;
		}

		&.hidden {
			animation: hidecam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
			opacity: 0;
		}

		&:hover {
			button {
				opacity: 1;
			}
		}

		.active-call-page-localvideo-placeholder {
			align-items: center;
			background: transparent;
			border-radius: 4px;
			justify-content: center;
			display: flex;
			position: absolute;
			font-size: 36px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}

		button {
			align-items: center;
			background: #fff;
			border: 2px solid $light;
			border-radius: 100%;
			cursor: pointer;
			display: flex;
			outline: none;
			justify-content: center;
			padding: 0;
			opacity: 0;

			position: absolute;
			top: 8px;
			right: 8px;
			left: 165px;
			height: 26px;
			width: 26px;
			transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

			i.material-icons {
				color: $primary;
				font-size: 18px;
				line-height: 1;
			}
		}

		.active-call-page-localvideo-active-pulse {
			align-items: center;
			border-radius: 100%;
			display: flex;
			top: 8px;
			left: 8px;
			margin: 0;
			justify-content: center;
			padding: 0;
			position: absolute;
			height: 26px;
			width: 26px;

			@media screen and (max-width: 700px) {
				height: 18px;
				width: 18px;
			}

			.pulse__inner {
				animation: pulsate 3s;
				animation-iteration-count: infinite;
				background: $danger;
				border-radius: 100%;
				position: absolute;
				height: 80%;
				opacity: 1;
				width: 80%;
				@media screen and (max-width: 1050px) {
					opacity: 0;
					animation: none;
				}
			}
		}

		.active-call-page-localvideo-closebutton {
			align-items: center;
			background: rgba(0, 0, 0, 0.2);
			display: flex;
			border-radius: 100%;
			position: absolute;
			justify-content: center;
			padding: 0;
			margin: 0;
			top: 8px;
			right: 8px;
			height: 26px;
			width: 26px;

			&:hover {
				background: rgba(0, 0, 0, 0.4);
				i.material-icons {
					color: rgba(255, 255, 255, 0.75);
				}
			}

			i.material-icons {
				color: rgba(255, 255, 255, 0.56);
				font-size: 18px;
				line-height: 1;
			}
		}

		.active-call-page-localvideo-element {
			border-radius: 4px;
			max-height: 150px;
			object-fit: contain;
			//transform: rotateY(180deg);
			object-position: right;

			@media screen and (max-width: 700px) {
				max-height: 80px;
			}

			&.hidden {
				display: none;
			}
		}
	}
}
.camera-handler-div {
	margin-top: 102px;
	margin-left: 23px;
	width: 50px;
	position: absolute;
	z-index: 9999;

	button {
		align-items: center;
		background: #fff;
		border: 4px solid #dedede;
		border-radius: 100%;
		cursor: pointer;
		display: -webkit-flex;
		display: flex;
		margin: 0 7px;
		outline: none;
		opacity: 1;
		-webkit-justify-content: center;
		justify-content: center;
		position: relative;
		transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
		height: 64px;
		width: 64px;
		margin-bottom: 5px;

		i.material-icons {
			color: $primary;
			font-size: 28px;
			line-height: 1;
			user-select: none;
		}
	}
}
.sizePreview {
	animation: showcam 500ms;
	animation-fill-mode: forwards;
	width: 32px;
	height: 32px;
	right: 350px;
	bottom: 10px;
	position: relative;
}

.slider {
	-webkit-appearance: none;
	display: flex;
	width: 316px;
	height: 15px;
	border-radius: 5px;
	background: #ffffff;
	outline: none;
	opacity: 0.7;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: rgb(110, 150, 184);
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: rgb(110, 150, 184);
	cursor: pointer;
}

.active-call-page-footer {
	height: 250px;
}

#toggle_fullscreen {
	@media screen and (max-width: $break-mobile-landscape) {
		display: none;
	}
}

.active-call-page-right-menu {
	display: flex;
	flex-direction: column;
	margin-top: 18px;
	margin-right: 18px;
	width: 300px;

	@media screen and (max-width: $break-mobile-landscape) {
		display: flex;
		flex-direction: column;
		margin-top: 18px;
		margin-right: 18px;
		width: 300px;
		overflow-y: auto;

		&.hidden {
			display: none;
		}
	}
}
