@import "../../scss/breakpoints";

.app-image-dropzone-container {
	border: 4px dashed transparent;
	display: block;

	&.is-dropping {
		border: 4px dashed #e0e0e0;
	}

	img {
		min-height: 225px;
		position: relative;
		object-fit: contain;
		object-position: center;
		width: 100%;
		max-width: 100%;

		@media screen and (max-width: $break-large) {
			height: 150px;
		}

		@media screen and (max-width: $break-medium) {
			height: 100px;
		}
	}
}
