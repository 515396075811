@import "../../scss/breakpoints";

.animated {
	transition: height 2s;
	height: auto;
	width: 100%;
	max-width: 100%;
}

.company-detail-container {
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: $break-mobile) {
		padding-top: 12px;

		.company-list-actionbar {
			button {
				width: 100%;
			}
		}
	}

	.company-detail-actionbar {
		display: flex;
		justify-content: space-between;
	}

	.company-detail-items {
		display: flex;
		flex-wrap: wrap;

		.item-display-container {
			height: 270px;
			width: 25%;

			@media screen and (max-width: $break-large) {
				width: 33.3%;
			}

			@media screen and (max-width: $break-medium) {
				width: 50%;
			}

			@media screen and (max-width: $break-small) {
				width: 80%;
			}
		}
	}
}

.company-details {
	margin-top: 10px;

	.company-details-adress {
		margin-top: 35px;
	}

	.company-details-permits {
		margin-top: 35px;
	}

	.company-details-user {
		margin-top: 35px;
	}
}
