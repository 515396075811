/** 
*   Holo Maint - Theme Colors
**/
.mobile-navlink {
  align-items: center;
  color: #dedede;
  display: flex;
  padding: 12px 18px;
  text-decoration: none; }
  .mobile-navlink:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .mobile-navlink.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .mobile-navlink.active .material-icons.app_icon {
      color: #343a40; }
  .mobile-navlink span.mobile-navlink-text {
    white-space: nowrap; }
  .mobile-navlink span.mobile-navlink-landscape-text {
    flex-direction: row;
    white-space: nowrap; }
  .mobile-navlink .material-icons.app_icon {
    margin-right: 18px; }

.mobile-operator-navlink {
  align-items: center;
  flex-direction: column;
  color: #dedede;
  display: flex;
  padding: 12px 9px;
  text-decoration: none;
  font-size: 8px;
  background-color: transparent; }
  .mobile-operator-navlink:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .mobile-operator-navlink.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .mobile-operator-navlink.active .material-icons.app_icon {
      color: #343a40; }
  .mobile-operator-navlink span.mobile-navlink-text {
    white-space: nowrap; }
  .mobile-operator-navlink span.mobile-navlink-landscape-text {
    flex-direction: row;
    white-space: nowrap; }
  .mobile-operator-navlink .material-icons.app_icon {
    margin-right: 18px; }

.mobile-navlink-landscape {
  align-items: center;
  flex-direction: row;
  color: #dedede;
  display: flex;
  padding: 12px 9px;
  text-decoration: none;
  font-size: 12px;
  background-color: transparent; }
  .mobile-navlink-landscape:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .mobile-navlink-landscape.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .mobile-navlink-landscape.active .material-icons.app_icon {
      color: #343a40; }
