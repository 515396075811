.loading-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	min-height: 300px;

	.spinner {
		border-width: 0.4em;
		height: 3rem;
		margin-bottom: 24px;
		width: 3rem;
	}
}
