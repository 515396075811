@import "../../scss/custom-variables";

.dashboard-queue-placeholder {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	i.app_icon {
		font-size: 48px;
	}
}

.queue-card {
	margin-top: 15px;
	@media screen and (max-width: 500px) {
		margin-top: 12px;
	}
}

.camera-warning-card {
	display: flex;
	padding: 24px;
	align-content: center;
	justify-content: center;
	text-align: center;
	background-color: $danger !important;
	color: $white;
}

.temporary-div {
	margin-left: calc(100% - 155px);
}

.drop-camera {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
  	position: absolute;
  	background-color: #f1f1f1;
  	min-width: 160px;
  	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  	z-index: 1;
}

.dropdown-content div {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }

.dropdown-content div:hover {background-color: #ddd;}
  
.drop-camera:hover .dropdown-content {display: block;}
  
.drop-camera:hover .dropbtn {background-color: #3e8e41;}

.dashboard-queue-status-indicator {
	font-size: 64px;
	font-weight: bold;

	&.red {
		color: $danger;
	}

	&.yellow {
		color: $warning;
	}

	&.green {
		color: $success;
	}
}

.dashboard-peer {
	align-items: center;
	border: 1px solid $gray;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	margin: 12px;
	height: 46px;
	position: relative;

	&:hover {
		background: $light;
	}

	.dashboard-peer-icon-container {
		align-items: center;
		border-right: 1px solid $gray;
		display: flex;
		padding: 0 12px;
		height: 100%;

		.app_icon {
			line-height: 1;
		}
	}

	span {
		margin-left: 12px;
	}
}
