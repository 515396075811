@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

.users-item-row {
	background: $white;
	// border: 1px solid #e0e0e0;
	margin-bottom: 10px;
	// cursor: pointer;
	display: flex;
	justify-content: center;
    align-items: center;

	// &:active,
	// &:hover {
	// 	background: $light;
	// 	.material-icons {
	// 		border-right: 1px solid $body-bg;
	// 	}
	// }
	.material-icons {
		text-align: center;
		font-size: 24px;
		padding: 15px 15px;
		height: 100%;
		border-right: 1px solid $light;
		min-width: 66px;
	}
	.material-icons-closed {
		text-align: center;
		font-size: 24px;
		padding: 15px 15px;
		height: 100%;
		border-right: 1px solid $light;
		min-width: 66px;
		border-bottom: 1px solid $light;
	}

	div.users-item-row-info {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
		margin-left: 12px;
		padding: 12px 12px;

		@media screen and (max-width: $break-mobile) {
			font-size: 13px;
		}
	}

	div.blocked-users-item-row-info {
		color: rgb(170, 170, 170);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
		margin-left: 12px;
		padding: 12px 12px;

		@media screen and (max-width: $break-mobile) {
			font-size: 13px;
		}
	}

	p.users-item-row-name {
		margin: 0;
	}

	span.users-item-row-team {
		margin: 0;
		font-style: italic;
		font-size: 14px;
	}

	div.users-item-row-role {
		font-size: 11px;
	}

	.users-item-row-action {
		margin-left: auto;
		padding-top: 8px;
		padding-right: 24px;

		.btn-group {
			button.btn {
				background: none;
				border: 0;
				box-shadow: none;
				outline: 0;
				padding: 0;

				&:focus {
					box-shadow: none;
				}

				i.material-icons {
					line-height: 1;
					border-right: none;
					width: 38px;
					min-width: 38px;
				}
			}
		}
	}

	.users-edit-row-action {
		margin-left: auto;
		display: flex;
		flex-wrap: wrap;
		align-self: stretch;
		flex: 1;

		.btn-group {
			justify-self: auto;
			button.btn {
				background: $white;
				margin-left: auto;
				flex-wrap: wrap;
				align-self: stretch;

				&:focus {
					box-shadow: none;
				}

				i.material-icons {
					line-height: 1;
					border-right: none;
					width: 38px;
					min-width: 38px;
				}
			}
		}
	}

	.users-tool-button {
		margin-left: auto;
		vertical-align: middle;
		text-align: center;
		display: flex;
		cursor:pointer;
	}

	.users-tool-bot-button {
		margin-left: auto;
		vertical-align: bottom;
		text-align: bottom;
		display: flex;

		cursor:pointer;
	}

	.users-tool-save-button {
		padding-top: 16px;
		padding-bottom: 16px;
		flex-wrap: wrap;
		margin-left: 12px;
		padding-right: 32px;
		align-self: stretch;
		button.btn {
			width: 100%;
			padding: 18px;
			padding-left: 24px;
			padding-right: 24px;
			flex-wrap: wrap;

			&:focus {
				box-shadow: none;
			}

			i.material-icons {
				border-right: none;
				text-align: center;
				font-size: 24px;
			}
		}
	}

	.users-tool-reset-button {
		padding-bottom: 16px;
		flex-wrap: wrap;
		align-self: stretch;
		box-shadow: none;
		font-size: 16px;
		button.btn {
			//width: 100%;
			//padding: 12px;
			//flex-wrap: wrap;
			padding: 0px;
			min-width: auto;
			padding-left: 4px;
			padding-right: 4px;

			&:focus {
				box-shadow: none;
			}

			i.material-icons {
				line-height: 1;
				border-right: none;
				//width: 38px;
				//min-width: 38px;
			}
		}
	}

	.users-tool-reset-button-toggled {
		
		padding-bottom: 16px;
		flex-wrap: wrap;
		align-self: stretch;
		box-shadow: none;
		font-size: 16px;
		button.btn {
			//width: 100%;
			//padding: 12px;
			flex-wrap: wrap;
			background: $white;
			padding: 0px;
			min-width: auto;
			padding-left: 4px;
			padding-right: 4px;

			&:focus {
				box-shadow: none;
			}

			i.material-icons {
				line-height: 1;
				border-right: none;
				//width: 38px;
				//min-width: 38px;
			}
		}
	}

	span.file-item-row-date_created {
		color: rgba(0, 0, 0, 0.56);
		font-size: 12px;
		margin-left: auto;
		margin-right: 12px;
	}
}
