/*
* Custom Dropzone Style 
*/

.dropzone {
	min-height: 140px;
	// border: 1px dashed $primary;
	background: $white;
	padding: 20px 20px;

	&.active {
		border-color: $success;
		color: $success;
	}

	.material-icons {
		font-size: 8rem;
		padding-top: 5rem;
		line-height: 0;
	}
	.dropzone-image-container {
		width: 100px;
		height: 100px;
		margin: 15px;
		float: left;
		position: relative;
	}

	.dropzone-thmbnail-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dropzone-thmbnail-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.spinner-center {
		position: absolute;
		left: 45px;
		top: 45px;
		color: #fff;
	}
}

.dropzone {
	&.dz-clickable {
		cursor: pointer;
	}

	&.dz-drag-hover {
		border: solid;
		color: -10;
	}

	.dz-message {
		font-size: 16px;
		text-align: center;
		margin: 2em 0;
	}

	.dz-fallback {
		display: none;
	}
}

.dropzone.dz-clickable * {
	cursor: default;
}
