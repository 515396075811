@import "../../scss/custom-variables";

.app-placeholder-page {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	justify-content: center;

	i.material-icons.app_icon {
		color: $gray;
		font-size: 96px;
	}
}
