@import "../../scss/breakpoints";

.item_detail_tab_display_container {
	margin-top: 18px;
}

.nav-tabs.custom-nav {
	background: #fff;
	border: none;

	@media screen and (max-width: $break-mobile) {
		.nav-item {
			text-align: center;
			width: 100%;
		}
	}
}

.nav-tabs .nav-link.custom-nav-link.custom-nav-link {
	background: none;
	border: none;
	border-radius: 0;
	color: rgba(0, 0, 0, 0.56);
	cursor: pointer;
	font-size: 14px;
	font-weight: bold;
	padding: 12px 24px;

	&:hover {
		border: none;
		background: #f3f3f3;
	}

	&.active.active.active {
		border: none;
		background: #dedede;
		color: rgba(0, 0, 0, 0.87);
	}

	@media screen and (max-width: $break-mobile) {
		font-size: 11px;
		text-transform: uppercase;
		padding: 12px;
	}
}
