.page-number {
    margin-left: calc(100% - 140px);
}

.div-buttons {
    margin-left: calc(50% - 65px);
    margin-top: -70px;
}

.date-filter-class {
    margin-left: calc(100% - 460px);
}

.button {
    background-color: #bfbfbf;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}