@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

.user-create-container {
	width: 100%;
	flex: auto;
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: $break-mobile) {
		padding-top: 12px;

		.user-create-actionbar {
			button {
				width: 100%;
			}
		}
	}

	.user-create-actionbar {
		display: flex;
		justify-content: space-between;
	}

	.usre-create-items {
		display: flex;
		flex-wrap: wrap;

		.item-display-container {
			height: 270px;
			width: 25%;

			@media screen and (max-width: $break-large) {
				width: 33.3%;
			}

			@media screen and (max-width: $break-medium) {
				width: 50%;
			}

			@media screen and (max-width: $break-small) {
				width: 80%;
			}
		}
	}

	.user-create-form-container {
		margin-top: 10px;
	}
}
