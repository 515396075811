@import "../../scss/custom-variables";
@import "../../scss/breakpoints";

.products-list-container {
	display: flex;
	flex-direction: column;

	@media screen and (max-width: $break-mobile) {
		padding-top: 12px;

		.products-list-actionbar {
			button {
				width: 100%;
			}
		}
	}

	.products-list-actionbar {
		padding: 0 12px;
	}

	.products-list-items {
		display: flex;
		flex-wrap: wrap;

		.item-display-container {
			height: 280px;
			width: 25%;

			@media screen and (max-width: $break-large) {
				width: 33.3%;
			}

			@media screen and (max-width: $break-medium) {
				width: 50%;
			}

			@media screen and (max-width: $break-small) {
				width: 100%;
			}
		}
	}
}

.products-list-container-mobile {
	display: flex;
	flex-direction: column;
}

.products-list-container-mobile-active-call {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 12px;
	border-radius: 10px;
}

.product_card_header {
	align-items: center;
	display: flex;
	min-height: 62px;

	.button-container {
		margin-left: auto;
	}
}

.search-bar {
	padding: 10px;
    text-align: right;
	margin-right: 15px;
	
	@media only screen and (max-device-width: 700px) and (orientation: portrait) {
		text-align: center;
		padding: 10px;
		margin-right: 0px;
		.input {
			width: calc(100% - 95px);
		}
	}
}

.qr-code-button {
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	width: 100%;

	:focus {
		outline: none;
		-webkit-tap-highlight-color: transparent;
	}

	button {
		align-items: center;
		right: 12px;
		bottom: 24px;
		background: #fff;
		border: 4px solid $light;
		border-radius: 100%;
		cursor: pointer;
		display: flex;
		margin: 0 12px;
		outline: none;
		opacity: 1;
		justify-content: center;
		position: fixed;
		transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);

		height: 64px;
		width: 64px;

		&.icon-primary {
			i.material-icons {
				color: $primary;
			}
		}

		&.icon-primary-2 {
			left: calc(100% - 170px);
		}

		i.material-icons {
			color: $primary;
			font-size: 28px;
			line-height: 1;
			user-select: none;
		}
	}
}
