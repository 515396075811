/** 
*   Holo Maint - Theme Colors
**/
.btn-model > * {
  pointer-events: none; }

.modal-doc-container {
  animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  background: #232323;
  width: 100%;
  height: calc(100% - 120px);
  position: fixed;
  top: 60px;
  bottom: 60px;
  right: 0;
  z-index: 999; }
  .modal-doc-container.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -1; }
  .modal-doc-container :focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0); }
  .modal-doc-container button {
    align-items: center;
    background: #fff;
    border: 2px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    outline: none;
    justify-content: center;
    padding: 0;
    opacity: 1;
    z-index: 99999;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 26px;
    width: 26px;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .modal-doc-container button i.material-icons {
      color: #343a40;
      font-size: 18px;
      line-height: 1;
      user-select: none; }
