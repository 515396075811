/** 
*   Holo Maint - Theme Colors
**/
.app-page-header-container {
  background: #dedede;
  padding: 12px;
  margin-bottom: 12px; }
  @media screen and (max-width: 500px) {
    .app-page-header-container {
      display: none; } }
