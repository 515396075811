@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto:500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,300i,400,400i,600,600i,700,700i,800,800i);
.error-page-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center; }
  .error-page-container i.material-icons.app_icon {
    font-size: 96px; }

.file-item-row {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 50px;
  margin-bottom: 10px; }
  .file-item-row .file-item-row-image-thumb {
    object-fit: cover;
    object-position: center;
    height: 50px;
    width: 50px; }
  .file-item-row:hover {
    background: #f3f3f3; }
  .file-item-row:active {
    background: #dedede; }
  .file-item-row:first-child {
    border-top: 1px solid #e0e0e0; }
  .file-item-row .material-icons {
    line-height: 50px;
    font-size: 24px;
    padding: 0 13px; }
  .file-item-row span.file-item-row-filename {
    border-left: 1px solid #e0e0e0;
    overflow: hidden;
    white-space: nowrap;
    line-height: 50px;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding-left: 12px; }
    @media screen and (max-width: 500px) {
      .file-item-row span.file-item-row-filename {
        font-size: 13px; } }
  .file-item-row span.file-item-row-date_created {
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    margin-left: 12px;
    margin-right: 12px; }

.app_icon {
  outline: 0; }

.file-list-container {
  margin-top: 12px; }

/** 
*   Holo Maint - Theme Colors
**/
.app_icon_button {
  background: #ffffff;
  border: none;
  cursor: pointer;
  height: 34px;
  outline: none;
  width: 34px; }
  .app_icon_button:hover {
    background: #dedede; }
  .app_icon_button .material-icons {
    line-height: 1.4; }
  .app_icon_button .app_icon {
    color: rgba(0, 0, 0, 0.56); }
  .app_icon_button:hover > .app_icon {
    color: rgba(0, 0, 0, 0.87); }
  .app_icon_button:active {
    outline: none; }
  .app_icon_button:focus {
    outline: none; }

@-webkit-keyframes spin-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner {
  color: #6c757d;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin-rotate 0.75s linear infinite;
          animation: spin-rotate 0.75s linear infinite; }

.btn.icon-text-button {
  display: -webkit-flex;
  display: flex; }
  .btn.icon-text-button span {
    padding: 7px; }

.app-image-dropzone-container {
  border: 4px dashed transparent;
  display: block; }
  .app-image-dropzone-container.is-dropping {
    border: 4px dashed #e0e0e0; }
  .app-image-dropzone-container img {
    min-height: 225px;
    position: relative;
    object-fit: contain;
    object-position: center;
    width: 100%;
    max-width: 100%; }
    @media screen and (max-width: 1440px) {
      .app-image-dropzone-container img {
        height: 150px; } }
    @media screen and (max-width: 920px) {
      .app-image-dropzone-container img {
        height: 100px; } }

.loading-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 300px; }
  .loading-container .spinner {
    border-width: 0.4em;
    height: 3rem;
    margin-bottom: 24px;
    width: 3rem; }

/** 
*   Holo Maint - Theme Colors
**/
.app-placeholder-page {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center; }
  .app-placeholder-page i.material-icons.app_icon {
    color: #959595;
    font-size: 96px; }

/** 
*   Holo Maint - Theme Colors
**/
.required-label-star {
  color: #959595;
  font-size: 14px; }

.input-group.searchbar-input-group.hide-mobile {
  display: none; }

.input-group.searchbar-input-group .input-group-prepend .input-group-text .material-icons {
  line-height: 1; }

.app-stretched-image {
  display: block;
  position: relative;
  min-width: 80px;
  min-height: 80px; }
  .app-stretched-image img {
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%; }

MobileImage.app-stretched-image {
  display: block;
  position: relative;
  min-width: 80px;
  min-height: 80px; }
  MobileImage.app-stretched-image img {
    height: 100%;
    object-fit: contain;
    object-position: left;
    width: 100%; }

MobileImage.app-stretched-image-product {
  display: block;
  position: relative;
  min-width: 150px;
  min-height: 150px; }
  MobileImage.app-stretched-image-product img {
    height: 100%;
    object-fit: contain;
    object-position: left;
    width: 100%; }

/** 
*   Holo Maint - Theme Colors
**/
@-webkit-keyframes enterAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes enterAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.app-toast-container {
  position: fixed;
  top: 0;
  right: 0;
  padding: 18px;
  z-index: 2391938; }

.app-toast-box {
  -webkit-animation-name: enterAnimation;
          animation-name: enterAnimation;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background: #343a40;
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.2), 0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12);
  margin-bottom: 18px;
  min-width: 230px;
  max-width: 230px;
  width: 230px; }
  .app-toast-box.info {
    border-left: 4px solid #009ee0; }
  .app-toast-box.success {
    border-left: 4px solid #b9c900; }
  .app-toast-box.warning {
    border-left: 4px solid #ffc107; }
  .app-toast-box.error {
    border-left: 4px solid #cc0000; }
  .app-toast-box .app-toast-top-layer {
    -webkit-align-items: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.5);
    display: -webkit-flex;
    display: flex;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase; }
    .app-toast-box .app-toast-top-layer .app-toast-top-layer-dismiss {
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      margin-left: auto;
      padding: 0; }
      .app-toast-box .app-toast-top-layer .app-toast-top-layer-dismiss:hover i.app_icon {
        color: rgba(255, 255, 255, 0.56); }
      .app-toast-box .app-toast-top-layer .app-toast-top-layer-dismiss i.app_icon {
        color: rgba(255, 255, 255, 0.3);
        line-height: 1.5;
        font-size: 18px; }
  .app-toast-box .app-toast-body {
    color: #fff;
    font-size: 13px;
    padding: 8px 8px; }

/** 
*   Holo Maint - Theme Colors
**/
.app-dropzone-rejected-files {
  margin-top: 12px; }

.app-dropzone-outer {
  background: #ffffff;
  border-radius: 4px;
  padding: 18px; }
  .app-dropzone-outer .app-dropzone-container {
    border: 4px dashed transparent;
    border-radius: 4px;
    height: 64px; }
    @media screen and (max-width: 500px) {
      .app-dropzone-outer .app-dropzone-container {
        min-height: 100px; } }
    .app-dropzone-outer .app-dropzone-container.is-dropping {
      border: 4px dashed #343a40; }
    .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview {
      border: 1px solid #dedede;
      position: relative;
      margin-bottom: 12px; }
      .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-thumbnail {
        border-bottom: 1px solid #dedede;
        height: 65px;
        position: relative;
        width: 100%; }
        @media screen and (max-width: 500px) {
          .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-thumbnail {
            height: 35px; } }
        .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-thumbnail .app-dropzone-file-preview-progress {
          background: #cc0000;
          height: 12px;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: width 500ms cubic-bezier(0.19, 1, 0.22, 1);
          width: 0px; }
        .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-thumbnail .app-dropzone-file-preview-thumbnail-img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%; }
        .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-thumbnail .app-dropzone-file-preview-thumbnail-icon {
          -webkit-align-items: center;
                  align-items: center;
          display: -webkit-flex;
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          -webkit-justify-content: center;
                  justify-content: center; }
          .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-thumbnail .app-dropzone-file-preview-thumbnail-icon i.app_icon {
            font-size: 36px; }
      .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-body {
        padding: 12px 12px 12px 12px;
        position: relative; }
        .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-body .app-dropzone-file-preview-filename {
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .app-dropzone-outer .app-dropzone-container .app-dropzone-file-preview .app-dropzone-file-preview-body .app-dropzone-file-preview-filesize {
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
    .app-dropzone-outer .app-dropzone-container .app-dropzone-info {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1 1;
              flex: 1 1;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      height: 46px;
      font-size: 12px; }
      .app-dropzone-outer .app-dropzone-container .app-dropzone-info i.material-icons {
        font-size: 30px; }
        @media screen and (max-width: 500px) {
          .app-dropzone-outer .app-dropzone-container .app-dropzone-info i.material-icons {
            font-size: 32px; } }
      .app-dropzone-outer .app-dropzone-container .app-dropzone-info .span {
        font-size: 12px; }

/** 
*   Holo Maint - Theme Colors
**/
.app-upload-button-rejected-files {
  margin-top: 12px; }

.app-upload-button-outer {
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 18px; }
  .app-upload-button-outer .app-upload-button-container {
    border: 4px dashed transparent;
    border-radius: 4px;
    min-height: 210px; }
    @media screen and (max-width: 500px) {
      .app-upload-button-outer .app-upload-button-container {
        min-height: 100px; } }
    .app-upload-button-outer .app-upload-button-container.is-dropping {
      border: 4px dashed #343a40; }
    .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview {
      border: 1px solid #dedede;
      position: relative;
      margin-bottom: 12px; }
      .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-thumbnail {
        border-bottom: 1px solid #dedede;
        height: 100px;
        position: relative;
        width: 100%; }
        @media screen and (max-width: 500px) {
          .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-thumbnail {
            height: 35px; } }
        .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-thumbnail .app-upload-button-file-preview-progress {
          background: #cc0000;
          height: 12px;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: width 500ms cubic-bezier(0.19, 1, 0.22, 1);
          width: 0px; }
        .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-thumbnail .app-upload-button-file-preview-thumbnail-img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%; }
        .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-thumbnail .app-upload-button-file-preview-thumbnail-icon {
          -webkit-align-items: center;
                  align-items: center;
          display: -webkit-flex;
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          -webkit-justify-content: center;
                  justify-content: center; }
          .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-thumbnail .app-upload-button-file-preview-thumbnail-icon i.app_icon {
            font-size: 36px; }
      .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-body {
        padding: 12px 12px 12px 12px;
        position: relative; }
        .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-body .app-upload-button-file-preview-filename {
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .app-upload-button-outer .app-upload-button-container .app-upload-button-file-preview .app-upload-button-file-preview-body .app-upload-button-file-preview-filesize {
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
    .app-upload-button-outer .app-upload-button-container .app-upload-button-info {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1 1;
              flex: 1 1;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center; }
      .app-upload-button-outer .app-upload-button-container .app-upload-button-info i.material-icons {
        font-size: 96px; }
        @media screen and (max-width: 500px) {
          .app-upload-button-outer .app-upload-button-container .app-upload-button-info i.material-icons {
            font-size: 32px; } }

/** 
*   Holo Maint - Theme Colors
**/
@-webkit-keyframes whiteFlash {
  0% {
    display: block;
    opacity: 1; }
  95% {
    opacity: 0; }
  100% {
    display: none; } }
@keyframes whiteFlash {
  0% {
    display: block;
    opacity: 1; }
  95% {
    opacity: 0; }
  100% {
    display: none; } }

@-webkit-keyframes pulsate {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes pulsate {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@-webkit-keyframes showcam {
  from {
    opacity: 0;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes showcam {
  from {
    opacity: 0;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes hidecam {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); } }

@keyframes hidecam {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); } }

.change-product-button {
  background: lightgrey;
  border: none;
  border-radius: 5px; }

.dropup {
  position: relative;
  display: inline-block; }

.dropup-content {
  display: none;
  position: absolute;
  min-width: 160px;
  bottom: 100%;
  z-index: 1; }

.dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.dropup-content a:hover {
  background-color: #ccc; }

.dropup:hover .dropup-content {
  display: block; }

.dropdown-resolution-expert {
  position: inherit;
  display: inline-block; }

.dropdown-resolution-expert-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  left: 0px;
  top: calc(100% - 8px);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999999; }

.dropdown-resolution-expert a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.dropdown-resolution-expert-content a:hover {
  background-color: #ccc; }

.dropdown-resolution-expert:hover .dropdown-resolution-expert-content {
  display: block; }

.remote_view_hidden {
  visibility: hidden; }

.div-touch-element {
  display: none; }
  @media screen and (max-width: 900px) {
    .div-touch-element {
      display: -webkit-flex;
      display: flex; } }

.landscape-warning {
  display: none; }
  @media only screen and (max-device-width: 700px) and (orientation: portrait) {
    .landscape-warning {
      -webkit-align-items: center;
              align-items: center;
      background: #fff;
      display: -webkit-flex;
      display: flex;
      position: fixed;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999999; }
      .landscape-warning .app_icon {
        font-size: 48px; } }

.active-call-dropzone {
  margin-top: 18px;
  margin-bottom: 18px;
  height: 95px; }
  @media screen and (max-width: 900px) {
    .active-call-dropzone {
      display: none; } }

.active-call-users {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  margin: 0 12px;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  height: 64px;
  width: 64px;
  position: absolute;
  z-index: 10;
  top: 30px;
  left: 18px; }
  @media screen and (max-width: 700px) {
    .active-call-users {
      height: 50px;
      width: 50px;
      top: 15px;
      left: 5px; } }
  .active-call-users .badge {
    position: absolute;
    top: 0;
    right: -3px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 6px; }
    @media screen and (max-width: 700px) {
      .active-call-users .badge {
        height: 20px;
        width: 20px;
        padding: 3px;
        right: -5px; } }
  .active-call-users .material-icons {
    font-size: 35px;
    color: #343a40; }
    @media screen and (max-width: 700px) {
      .active-call-users .material-icons {
        font-size: 30px;
        color: #343a40; } }

.active-call-page-container {
  background: #dedede;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 91337; }
  .active-call-page-container.minimized {
    border-radius: 4px;
    top: auto;
    right: 24px;
    left: auto;
    bottom: 24px;
    height: 180px;
    width: 320px; }
    .active-call-page-container.minimized div {
      display: none; }
    .active-call-page-container.minimized .active-call-returntocall {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      display: -webkit-flex;
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 200ms; }
      .active-call-page-container.minimized .active-call-returntocall .app_icon {
        color: #ffffff;
        font-size: 36px;
        line-height: 1;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }
    .active-call-page-container.minimized:hover .active-call-returntocall {
      opacity: 1; }
    .active-call-page-container.minimized .active-call-page-remotevideo-container {
      display: block;
      margin: 0; }
    .active-call-page-container.minimized .active-call-page-left-column {
      display: -webkit-flex;
      display: flex; }
  .active-call-page-container.annotation-mode .active-call-page-remotevideo-container {
    position: absolute;
    top: 80px;
    right: 18px;
    max-height: 170px;
    pointer-events: none;
    width: 230px; }
  .active-call-page-container.annotation-mode .active-call-page-remotevideo-menu {
    display: none; }
  .active-call-page-container.annotation-mode .active-call-page-localvideo-container {
    display: none; }
  .active-call-page-container .active-call-page-annotation-container {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    display: none;
    -webkit-flex: 1 1;
            flex: 1 1;
    position: relative;
    margin: 18px;
    overflow: hidden; }
    .active-call-page-container .active-call-page-annotation-container.annotation-mode {
      display: block; }
      .active-call-page-container .active-call-page-annotation-container.annotation-mode .flash-container {
        -webkit-animation: whiteFlash 1500ms;
                animation: whiteFlash 1500ms;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        background: #fff;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        opacity: 0;
        height: 100%;
        width: 100%; }
    .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-size {
      -webkit-animation: showcam 500ms;
              animation: showcam 500ms;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 18px;
      position: absolute;
      right: 0;
      top: 32px; }
      .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-size button {
        border-radius: 100%;
        height: 48px;
        cursor: pointer;
        outline: 0;
        width: 48px;
        margin-bottom: 12px; }
    .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-sizePreview {
      -webkit-animation: showcam 500ms;
              animation: showcam 500ms;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 18px;
      position: absolute;
      right: 350px;
      top: 16px;
      -webkit-align-items: center;
              align-items: center; }
      .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-sizePreview .material-icons {
        -webkit-align-self: center;
                align-self: center;
        line-height: 0; }
    .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-colors {
      -webkit-animation: showcam 500ms;
              animation: showcam 500ms;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 18px;
      position: absolute;
      right: 0;
      top: 0; }
      .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-colors button {
        border-radius: 100%;
        height: 48px;
        cursor: pointer;
        outline: 0;
        width: 48px;
        margin-bottom: 12px; }
        .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-colors button.color-1 {
          background: #b9c900;
          border: 4px solid #b9c900; }
        .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-colors button.color-2 {
          background: #ffc107;
          border: 4px solid #ffc107; }
        .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-colors button.color-3 {
          background: #cc0000;
          border: 4px solid #cc0000; }
        .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-colors button.active {
          border: 4px solid #ffffff; }
    .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu {
      -webkit-animation: showcam 500ms;
              animation: showcam 500ms;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      right: 0;
      padding: 18px; }
      .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section {
        -webkit-align-items: center;
                align-items: center;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly; }
        .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button {
          -webkit-align-items: center;
                  align-items: center;
          background: #fff;
          border: 4px solid #dedede;
          border-radius: 100%;
          cursor: pointer;
          display: -webkit-flex;
          display: flex;
          margin: 0 12px;
          outline: none;
          -webkit-justify-content: center;
                  justify-content: center;
          position: relative;
          transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
          height: 64px;
          width: 64px; }
          @media screen and (max-width: 1050px) {
            .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button {
              height: 46px;
              width: 46px;
              opacity: 1; } }
          .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button.active {
            border: 4px solid #b9c900; }
          .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button.lg {
            height: 86px;
            width: 86px; }
            @media screen and (max-width: 1050px) {
              .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button.lg {
                height: 64px;
                width: 64px;
                opacity: 1; } }
            .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button.lg i.material-icons {
              font-size: 36px; }
          .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button.icon-red i.material-icons {
            color: #cc0000; }
          .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button.icon-green i.material-icons {
            color: #b9c900; }
          .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-menu .active-call-page-annotation-menu-section button i.material-icons {
            color: #343a40;
            font-size: 28px;
            line-height: 1; }
    .active-call-page-container .active-call-page-annotation-container .active-call-page-annotation-canvas {
      border-radius: 4px;
      cursor: crosshair;
      display: block;
      height: 100%;
      object-position: center;
      object-fit: contain;
      width: 100%; }
  .active-call-page-container .fullscreen-info {
    -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
            animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    position: absolute;
    top: 18px;
    left: 18px;
    width: 350px; }

.modal-window-drag-anchor {
  position: absolute;
  bottom: 0;
  z-index: 10000;
  right: 0;
  cursor: -webkit-grab;
  cursor: grab; }

.modal-window-drag-anchor:active {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.active-call-page-left-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  position: relative;
  width: calc(100% - 350px); }

.active-call-page-3d-modal-container {
  -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background-color: #232323;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  z-index: 999999; }
  .active-call-page-3d-modal-container.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -1; }
  .active-call-page-3d-modal-container button {
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    border: 2px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    outline: none;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
    opacity: 1;
    z-index: 99999;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 26px;
    width: 26px;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .active-call-page-3d-modal-container button i.material-icons {
      color: #343a40;
      font-size: 18px;
      line-height: 1;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }

.active-call-page-modal-container {
  -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background-color: #232323;
  width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  position: absolute;
  top: 25%;
  right: 0;
  z-index: 999;
  border-radius: 10px; }
  @media screen and (max-width: 900px) {
    .active-call-page-modal-container {
      -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
              animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      background-color: #232323;
      width: 100%;
      left: auto;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0;
      z-index: 999; } }
  .active-call-page-modal-container.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -1; }
  .active-call-page-modal-container button {
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    border: 2px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    outline: none;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
    opacity: 1;
    z-index: 99999;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 26px;
    width: 26px;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .active-call-page-modal-container button i.material-icons {
      color: #343a40;
      font-size: 18px;
      line-height: 1;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }

.active-call-page-remotevideo-container {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  display: block;
  -webkit-flex: 1 1;
          flex: 1 1;
  position: relative;
  margin: 18px;
  overflow: hidden; }
  @media screen and (max-width: 700px) {
    .active-call-page-remotevideo-container {
      margin: 0px; } }
  .active-call-page-remotevideo-container:hover .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button {
    opacity: 1;
    margin: 0px 7px; }
  .active-call-page-remotevideo-container .active-call-page-remotevideo-menu {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    right: 0;
    padding: 18px; }
    @media screen and (max-width: 700px) {
      .active-call-page-remotevideo-container .active-call-page-remotevideo-menu {
        padding: 10px; } }
    .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly; }
      .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button {
        -webkit-align-items: center;
                align-items: center;
        background: #fff;
        border: 4px solid #dedede;
        border-radius: 100%;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        margin: 0 7px;
        outline: none;
        opacity: 0;
        -webkit-justify-content: center;
                justify-content: center;
        position: relative;
        transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
        height: 64px;
        width: 64px; }
        @media screen and (max-width: 1050px) {
          .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button {
            height: 46px;
            width: 46px;
            opacity: 1; } }
        .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button .recorded-videos {
          background: #f1f1f1;
          border-radius: 100%;
          display: -webkit-flex;
          display: flex;
          position: absolute;
          -webkit-align-items: center;
                  align-items: center;
          left: 4px;
          top: -4px;
          height: 18px;
          width: 18px;
          -webkit-justify-content: center;
                  justify-content: center;
          font-size: 12px; }
          @media screen and (max-width: 700px) {
            .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button .recorded-videos {
              border: 1px solid #e0e0e0;
              left: 0px;
              top: -4px; } }
        .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button .pulse-recording {
          -webkit-animation: pulsate 3s;
                  animation: pulsate 3s;
          -webkit-animation-iteration-count: infinite;
                  animation-iteration-count: infinite;
          background: #cc0000;
          border-radius: 100%;
          position: absolute;
          right: 4px;
          top: -4px;
          height: 14px;
          width: 14px; }
          @media screen and (max-width: 700px) {
            .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button .pulse-recording {
              height: 8px;
              width: 8px; } }
        .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button.lg {
          height: 86px;
          width: 86px; }
          @media screen and (max-width: 1050px) {
            .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button.lg {
              height: 64px;
              width: 64px; } }
          .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button.lg i.material-icons {
            font-size: 36px; }
            @media screen and (max-width: 700px) {
              .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button.lg i.material-icons {
                font-size: 36px; } }
        .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button.icon-red i.material-icons {
          color: #cc0000; }
        .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button i.material-icons {
          color: #343a40;
          font-size: 28px;
          line-height: 1; }
          @media screen and (max-width: 700px) {
            .active-call-page-remotevideo-container .active-call-page-remotevideo-menu .active-call-page-remotevideo-menu-section button i.material-icons {
              font-size: 20px; } }
  .active-call-page-remotevideo-container video.active-call-page-remotevideo-element {
    border-radius: 4px;
    height: 100%;
    object-position: center;
    object-fit: contain;
    width: 100%; }
  .active-call-page-remotevideo-container video.active-call-page-remotevideo-element-second-vis {
    border-radius: 4px;
    height: 50%;
    object-position: center;
    object-fit: contain;
    width: 100%; }
  .active-call-page-remotevideo-container video.active-call-page-localvideo-element-third-vis {
    max-width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain; }
  .active-call-page-remotevideo-container .wrapper-one-participant {
    display: grid;
    grid-template-areas: "operator expert" "participant participant";
    text-align: center;
    overflow-y: auto;
    height: 100%;
    width: 100%; }
    .active-call-page-remotevideo-container .wrapper-one-participant .item1 {
      grid-area: operator; }
    .active-call-page-remotevideo-container .wrapper-one-participant .item2 {
      grid-area: expert; }
    .active-call-page-remotevideo-container .wrapper-one-participant .item3 {
      grid-area: participant; }
  .active-call-page-remotevideo-container .wrapper-two-participants {
    display: grid;
    grid-template-areas: "operator expert" "participant participant2";
    text-align: center;
    overflow-y: auto;
    height: 100%;
    width: 100%; }
    .active-call-page-remotevideo-container .wrapper-two-participants .item1 {
      grid-area: operator; }
    .active-call-page-remotevideo-container .wrapper-two-participants .item2 {
      grid-area: expert; }
    .active-call-page-remotevideo-container .wrapper-two-participants .item3 {
      grid-area: participant; }
    .active-call-page-remotevideo-container .wrapper-two-participants .item4 {
      grid-area: participant2; }
  .active-call-page-remotevideo-container .wrapper-three-participants {
    display: grid;
    grid-template-areas: "operator operator expert expert participant participant" "B participant2 participant2 participant3 participant3 A";
    text-align: center;
    overflow-y: auto;
    height: 100%;
    width: 100%; }
    .active-call-page-remotevideo-container .wrapper-three-participants .item1 {
      grid-area: operator; }
    .active-call-page-remotevideo-container .wrapper-three-participants .item2 {
      grid-area: expert; }
    .active-call-page-remotevideo-container .wrapper-three-participants .item3 {
      grid-area: participant; }
    .active-call-page-remotevideo-container .wrapper-three-participants .item4 {
      grid-area: participant2; }
    .active-call-page-remotevideo-container .wrapper-three-participants .item5 {
      grid-area: participant3; }
  .active-call-page-remotevideo-container .wrapper-four-participants {
    display: grid;
    grid-template-areas: "operator expert participant" "participant2  participant3 participant4";
    text-align: center;
    overflow-y: auto;
    height: 100%;
    width: 100%; }
    .active-call-page-remotevideo-container .wrapper-four-participants .item1 {
      grid-area: operator; }
    .active-call-page-remotevideo-container .wrapper-four-participants .item2 {
      grid-area: expert; }
    .active-call-page-remotevideo-container .wrapper-four-participants .item3 {
      grid-area: participant; }
    .active-call-page-remotevideo-container .wrapper-four-participants .item4 {
      grid-area: participant2; }
    .active-call-page-remotevideo-container .wrapper-four-participants .item5 {
      grid-area: participant3; }
    .active-call-page-remotevideo-container .wrapper-four-participants .item6 {
      grid-area: participant4; }
  .active-call-page-remotevideo-container .active-call-page-localvideo-container {
    -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
            animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    background: transparent;
    max-height: 80%;
    position: absolute;
    top: 18px;
    right: 18px;
    width: 200px;
    left: calc(100% - 200px);
    overflow-y: auto; }
    @media screen and (max-width: 700px) {
      .active-call-page-remotevideo-container .active-call-page-localvideo-container {
        max-height: 80px; } }
    .active-call-page-remotevideo-container .active-call-page-localvideo-container.hidden {
      -webkit-animation: hidecam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
              animation: hidecam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
      opacity: 0; }
    .active-call-page-remotevideo-container .active-call-page-localvideo-container:hover button {
      opacity: 1; }
    .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-placeholder {
      -webkit-align-items: center;
              align-items: center;
      background: transparent;
      border-radius: 4px;
      -webkit-justify-content: center;
              justify-content: center;
      display: -webkit-flex;
      display: flex;
      position: absolute;
      font-size: 36px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1; }
    .active-call-page-remotevideo-container .active-call-page-localvideo-container button {
      -webkit-align-items: center;
              align-items: center;
      background: #fff;
      border: 2px solid #dedede;
      border-radius: 100%;
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      outline: none;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 0;
      opacity: 0;
      position: absolute;
      top: 8px;
      right: 8px;
      left: 165px;
      height: 26px;
      width: 26px;
      transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .active-call-page-remotevideo-container .active-call-page-localvideo-container button i.material-icons {
        color: #343a40;
        font-size: 18px;
        line-height: 1; }
    .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-active-pulse {
      -webkit-align-items: center;
              align-items: center;
      border-radius: 100%;
      display: -webkit-flex;
      display: flex;
      top: 8px;
      left: 8px;
      margin: 0;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 0;
      position: absolute;
      height: 26px;
      width: 26px; }
      @media screen and (max-width: 700px) {
        .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-active-pulse {
          height: 18px;
          width: 18px; } }
      .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-active-pulse .pulse__inner {
        -webkit-animation: pulsate 3s;
                animation: pulsate 3s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        background: #cc0000;
        border-radius: 100%;
        position: absolute;
        height: 80%;
        opacity: 1;
        width: 80%; }
        @media screen and (max-width: 1050px) {
          .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-active-pulse .pulse__inner {
            opacity: 0;
            -webkit-animation: none;
                    animation: none; } }
    .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-closebutton {
      -webkit-align-items: center;
              align-items: center;
      background: rgba(0, 0, 0, 0.2);
      display: -webkit-flex;
      display: flex;
      border-radius: 100%;
      position: absolute;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 0;
      margin: 0;
      top: 8px;
      right: 8px;
      height: 26px;
      width: 26px; }
      .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-closebutton:hover {
        background: rgba(0, 0, 0, 0.4); }
        .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-closebutton:hover i.material-icons {
          color: rgba(255, 255, 255, 0.75); }
      .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-closebutton i.material-icons {
        color: rgba(255, 255, 255, 0.56);
        font-size: 18px;
        line-height: 1; }
    .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-element {
      border-radius: 4px;
      max-height: 150px;
      object-fit: contain;
      object-position: right; }
      @media screen and (max-width: 700px) {
        .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-element {
          max-height: 80px; } }
      .active-call-page-remotevideo-container .active-call-page-localvideo-container .active-call-page-localvideo-element.hidden {
        display: none; }

.camera-handler-div {
  margin-top: 102px;
  margin-left: 23px;
  width: 50px;
  position: absolute;
  z-index: 9999; }
  .camera-handler-div button {
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    border: 4px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    margin: 0 7px;
    outline: none;
    opacity: 1;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 64px;
    width: 64px;
    margin-bottom: 5px; }
    .camera-handler-div button i.material-icons {
      color: #343a40;
      font-size: 28px;
      line-height: 1;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }

.sizePreview {
  -webkit-animation: showcam 500ms;
          animation: showcam 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  width: 32px;
  height: 32px;
  right: 350px;
  bottom: 10px;
  position: relative; }

.slider {
  -webkit-appearance: none;
  display: -webkit-flex;
  display: flex;
  width: 316px;
  height: 15px;
  border-radius: 5px;
  background: #ffffff;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s; }

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #6e96b8;
  cursor: pointer; }

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #6e96b8;
  cursor: pointer; }

.active-call-page-footer {
  height: 250px; }

@media screen and (max-width: 900px) {
  #toggle_fullscreen {
    display: none; } }

.active-call-page-right-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 18px;
  margin-right: 18px;
  width: 300px; }
  @media screen and (max-width: 900px) {
    .active-call-page-right-menu {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: 18px;
      margin-right: 18px;
      width: 300px;
      overflow-y: auto; }
      .active-call-page-right-menu.hidden {
        display: none; } }

/** 
*   Holo Maint - Theme Colors
**/
.product-file-list-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-y: auto;
  padding-bottom: 20px;
  margin-bottom: 15px;
  background: #dedede;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 300px; }
  @media screen and (max-width: 900px) {
    .product-file-list-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1 1;
              flex: 1 1;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow-y: visible;
      padding-bottom: 12px;
      background: #dedede; } }

.product-file-list-container-empty {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-y: auto;
  padding-bottom: 20px;
  margin-bottom: 15px;
  width: 300px; }

.product-file-list-item {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 10px;
  background: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 46px;
  margin-top: 8px;
  position: relative;
  width: 300px; }
  .product-file-list-item .product-file-icon-container {
    -webkit-align-items: center;
            align-items: center;
    border-right: 1px solid #dedede;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    height: 100%; }
  .product-file-list-item span {
    margin-left: 12px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 12px; }
  .product-file-list-item button {
    -webkit-align-items: center;
            align-items: center;
    background: #ffffff;
    border: 0;
    border-left: 1px solid #dedede;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: auto;
    outline: 0;
    padding: 0 12px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px; }
    .product-file-list-item button i.material-icons {
      color: #959595;
      font-size: 18px; }
    .product-file-list-item button:active {
      color: #343a40;
      outline: 0; }
    .product-file-list-item button:hover, .product-file-list-item button:active, .product-file-list-item button:focus {
      outline: 0; }
    .product-file-list-item button:hover i.material-icons {
      color: #605d5c; }

.product-info-container {
  background: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .product-info-container i {
    line-height: 1; }
  .product-info-container.fullscreen .product-info-header .product-info-header-title {
    margin-left: 0; }
  .product-info-container.fullscreen .product-info-header .close-click {
    cursor: pointer;
    margin-left: auto; }
  .product-info-container .product-info-header {
    -webkit-align-items: center;
            align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: -webkit-flex;
    display: flex;
    padding: 12px 18px; }
    .product-info-container .product-info-header .product-info-header-title {
      margin-left: auto; }
  .product-info-container .product-info-image-container {
    height: 150px;
    position: relative; }
    .product-info-container .product-info-image-container img {
      height: 100%;
      object-fit: contain;
      object-position: center;
      width: 100%; }
  .product-info-container .product-info-body {
    overflow-y: auto; }
    .product-info-container .product-info-body .product-info-body-header {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      margin: 18px;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .product-info-container .product-info-body .product-info-body-header .product-info-body-header-name {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .product-info-container .product-info-body .product-info-body-header .product-info-body-header-name p {
          font-size: 16px;
          margin: 0; }
        .product-info-container .product-info-body .product-info-body-header .product-info-body-header-name span {
          font-size: 12px; }
      .product-info-container .product-info-body .product-info-body-header .product-info-body-header-company {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        .product-info-container .product-info-body .product-info-body-header .product-info-body-header-company p {
          font-weight: bold;
          font-size: 12px;
          margin: 0; }
        .product-info-container .product-info-body .product-info-body-header .product-info-body-header-company span {
          font-size: 12px; }
    .product-info-container .product-info-body .product-info-body-content {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: 18px;
      margin-left: auto; }
      .product-info-container .product-info-body .product-info-body-content p {
        font-weight: bold;
        font-size: 12px;
        margin: 0 18px 6px; }
      .product-info-container .product-info-body .product-info-body-content span {
        font-size: 12px;
        padding: 0 18px 18px;
        max-height: 150px; }

.connecting-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100%; }
  .connecting-container .spinner {
    border-width: 0.4em;
    height: 3rem;
    margin-bottom: 24px;
    width: 3rem; }

/** 
*   Holo Maint - Theme Colors
**/
.custom-header {
  padding: 10px 0px;
  background-color: #7a7a76;
  color: black;
  margin-top: -100px;
  border-radius: 5px; }

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

.label-style {
  padding: 5px; }

.button {
  background-color: #959595;
  border: none;
  color: white;
  padding: 8px 10px;
  text-align: center;
  width: 170px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s; }

.custom-alert {
  left: 0px;
  padding: 20px;
  background: rgba(85, 85, 85, 0.5);
  color: black;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  height: 100%; }

.message-style {
  text-align: center;
  background-color: #ffffff;
  padding: 100px 0;
  width: 500px;
  margin-top: 15%;
  height: 0px;
  margin-left: 35%;
  border-radius: 5px;
  -webkit-animation-name: animatetop;
  animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s; }
  @media only screen and (max-device-width: 700px) and (orientation: portrait) {
    .message-style {
      text-align: center;
      background-color: #ffffff;
      padding: 100px 0;
      margin-top: 15%;
      margin-left: 0;
      height: 0px;
      width: auto;
      border-radius: 5px;
      -webkit-animation-name: animatetop;
              animation-name: animatetop;
      -webkit-animation-duration: 0.4s;
              animation-duration: 0.4s; } }

.xr-overlay {
  /* force a transparent background */
  background: rgba(0, 0, 0, 0) !important;
  /* act as containing block for descendants */
  contain: paint !important;
  /* the following styling is identical to :fullscreen */
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-transform: none !important;
          transform: none !important;
  /* intentionally not !important */
  object-fit: contain; }

.div-elements {
  position: absolute;
  margin: 16px;
  top: calc(100% - 115px);
  left: 50%; }

.next-button-ar {
  left: calc(100% - 124px);
  top: calc(100% - 100px);
  height: 48px;
  width: 48px; }

.next-button {
  left: 50px;
  height: 48px;
  width: 48px; }
  .next-button .i {
    font-size: 24px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

.vis-button-ar {
  left: calc(100% - 192px);
  top: calc(100% - 100px);
  height: 48px;
  width: 48px; }

.vis-button {
  left: -5px;
  height: 48px;
  width: 48px; }
  .vis-button .i {
    font-size: 24px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

.prev-button-ar {
  left: calc(100% - 260px);
  top: calc(100% - 100px);
  height: 48px;
  width: 48px; }

.prev-button {
  left: -60px;
  height: 48px;
  width: 48px; }
  .prev-button .i {
    font-size: 24px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

.play-pause-button-ar {
  left: calc(100% - 330px);
  top: calc(100% - 100px);
  height: 48px;
  width: 48px; }

.play-pause-button {
  left: -115px;
  height: 48px;
  width: 48px; }
  .play-pause-button .i {
    font-size: 24px; }

.toast-style {
  min-width: 250px;
  top: 25%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #00000088;
  color: #fff;
  text-align: center;
  border-radius: 22px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  font-size: 17px; }

.div-pdf {
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .div-pdf ::-webkit-scrollbar {
    width: 10px; }
  .div-pdf ::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .div-pdf ::-webkit-scrollbar-thumb {
    background: #888; }
  .div-pdf ::-webkit-scrollbar-thumb:hover {
    background: #555; }

.modal-window {
  background: #232323;
  margin: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .modal-window .div {
    width: 100%;
    height: 100%;
    margin-left: -64px;
    margin-top: -128px;
    position: absolute; }
    .modal-window .div .spinner {
      margin-left: -20%;
      margin-top: 35vh;
      margin-bottom: auto;
      height: 128px;
      width: 128px; }

.save-overlay-container {
  -webkit-align-items: center;
          align-items: center;
  background: rgba(255, 255, 255, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .save-overlay-container .save-overlay-text {
    font-size: 24px;
    margin-top: 24px; }
  .save-overlay-container .save-overlay-spinner {
    height: 48px;
    width: 48px; }

/** 
*   Holo Maint - Theme Colors
**/
.item-display-container {
  padding-top: 12px;
  padding-right: 10px;
  padding-left: 2px; }

.item-display-container-mobile {
  -webkit-flex-direction: column;
          flex-direction: column; }

.product-card-container {
  background: #ffffff;
  padding-bottom: 5px;
  position: relative;
  height: 100%;
  padding-left: 6px;
  border-radius: 10px; }
  .product-card-container .product-card-header {
    display: -webkit-flex;
    display: flex;
    padding-top: 12px; }
    .product-card-container .product-card-header .product-card-header-button-container {
      margin-left: auto;
      margin-right: 12px; }
      .product-card-container .product-card-header .product-card-header-button-container .btn-group button.btn {
        background: none;
        border: 0;
        box-shadow: none;
        outline: 0;
        padding: 0; }
        .product-card-container .product-card-header .product-card-header-button-container .btn-group button.btn i.material-icons {
          line-height: 1; }
    .product-card-container .product-card-header .product-card-header-text {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow: hidden; }
      .product-card-container .product-card-header .product-card-header-text p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .product-card-container .product-card-header .product-card-header-text span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .product-card-container .product-card-header .app_icon_button {
      margin-left: auto;
      margin-right: 6px; }
      .product-card-container .product-card-header .app_icon_button .material-icons.app_icon {
        line-height: 1; }
  .product-card-container .product-card-description {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.product-card-container-mobile {
  display: -webkit-flex;
  display: flex;
  background: #ffffff;
  margin-bottom: 10px;
  position: relative;
  height: 100%; }
  .product-card-container-mobile .product-card-header-mobile {
    display: -webkit-flex;
    display: flex;
    padding-left: 12px;
    padding-top: 50px; }
    .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow: hidden; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px; }
  .product-card-container-mobile .product-card-description-mobile {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.div-current-model {
  position: fixed;
  margin: 0;
  width: 100vw;
  height: 100%;
  z-index: 9999; }

.toast-message {
  min-width: 250px;
  top: 25%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #00000088;
  color: #fff;
  text-align: center;
  border-radius: 22px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  font-size: 17px; }

.div-spinner {
  width: 100%;
  height: 100%;
  margin-left: -64px;
  margin-top: -128px;
  position: absolute; }
  .div-spinner .spinner {
    margin-left: 50%;
    margin-top: 40vh;
    margin-bottom: auto;
    height: 128px;
    width: 128px; }

.buttons-container {
  position: absolute;
  margin: 16px;
  top: calc(100% - 115px);
  left: 50%; }
  .buttons-container.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -1; }
  .buttons-container .anim-visualizer-model {
    left: -5px;
    height: 48px;
    width: 48px; }
  .buttons-container .next-button-model {
    left: 50px;
    height: 48px;
    width: 48px; }
    .buttons-container .next-button-model i.material-icons {
      font-size: 24px; }
  .buttons-container .prev-button-model {
    left: -60px;
    height: 48px;
    width: 48px; }
    .buttons-container .prev-button-model i.material-icons {
      font-size: 24px; }
  .buttons-container .play-button-model {
    left: -115px;
    height: 48px;
    width: 48px; }
    .buttons-container .play-button-model i.material-icons {
      font-size: 24px; }

.voice-button-model {
  margin-left: auto;
  margin-right: 0px;
  margin-top: auto;
  bottom: 96px;
  height: 48px;
  width: 48px; }
  .voice-button-model i.material-icons {
    font-size: 24px; }

.ar-button-style {
  font-size: 15px;
  top: calc(100% - -21px);
  background-color: white;
  position: absolute;
  bottom: 20px;
  border: 1px solid white;
  border-radius: 43px;
  color: black;
  font-weight: bold;
  font: 13px sans-serif bold;
  text-align: center;
  outline: none;
  z-index: 999;
  cursor: auto;
  left: calc(100% - 169px);
  width: 45px;
  height: 45px; }

.active-call-current-model {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; }

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: white;
  outline: none;
  opacity: 1;
  transition: opacity .2s; }

.slider:hover {
  opacity: 1; }

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: darkgray;
  cursor: pointer; }

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: darkgray;
  cursor: pointer; }

.slider-scroll-container {
  top: calc(100% - 60px);
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: absolute;
  -webkit-justify-content: center;
          justify-content: center; }

.slider-scroll {
  width: 240px;
  height: 25px;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  cursor: pointer; }

.switch-page-button {
  width: 42px !important;
  height: 42px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  top: 50% !important; }
  .switch-page-button#next-btn {
    left: calc(100% - 42px - 1%) !important; }
  .switch-page-button#prev-btn {
    margin-left: 1%;
    left: 0 !important; }

.pageCounter {
  border-radius: 20px;
  background-color: white;
  padding: 0 0.5rem;
  color: black; }

#current-model #pdf-render-canvas {
  width: 100%;
  height: 100%;
  border-radius: 10px; }

@media screen and (orientation: landscape) {
  #current-model-2 .slider-scroll-container {
    left: calc(50% - 25vw);
    top: calc(100% - 60px);
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
  #current-model-2 .slider-scroll {
    width: 50vw; } }

@media screen and (orientation: portrait) {
  #current-model-2 .slider-scroll-container {
    left: calc(50% - 40vw);
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
  #current-model-2 .slider-scroll {
    width: 70vw; } }

#current-model-2 .switch-page-button {
  width: 42px !important;
  height: 42px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  top: 50% !important; }
  #current-model-2 .switch-page-button#next-btn {
    left: calc(100vw - 42px - 1%) !important; }
  #current-model-2 .switch-page-button#prev-btn {
    margin-left: 1%;
    left: 0 !important; }

@media screen and (orientation: landscape) {
  #current-model-2 .react-transform-component {
    width: unset; } }

@media screen and (orientation: portrait) {
  #current-model-2 .react-transform-component {
    width: unset; } }

@media screen and (orientation: landscape) {
  #current-model-2 .react-transform-element {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    -webkit-transform: translate(200px, 0px) scale(1);
            transform: translate(200px, 0px) scale(1); } }

@media screen and (orientation: landscape) {
  #current-model-2 #pdf-render-canvas {
    height: 100vh; } }

@media screen and (orientation: portrait) {
  #current-model-2 #pdf-render-canvas {
    width: 100%;
    height: 100%; } }

/** 
*   Holo Maint - Theme Colors
**/
.companies-list-container {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .companies-list-container {
      padding-top: 12px; }
      .companies-list-container .companies-list-actionbar button {
        width: 100%; } }
  .companies-list-container .companies-list-actionbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .companies-list-container .companies-list-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .companies-list-container .companies-list-items .item-display-container {
      height: 270px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .companies-list-container .companies-list-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .companies-list-container .companies-list-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .companies-list-container .companies-list-items .item-display-container {
          width: 80%; } }

.company-detail-container .company-unselected {
  margin-top: 10px;
  height: 600px;
  color: #eee;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 24px solid #dedede;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .company-detail-container .company-unselected i.material-icons.app_icon.company-unselected-icon {
    font-size: 60px; }

.app-detail-container .app-unselected {
  margin-top: 10px;
  height: 300px;
  color: #eee;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 24px solid #dedede;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .app-detail-container .app-unselected i.material-icons.app_icon.app-unselected-icon {
    font-size: 60px; }

.licenses-list {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: -15px;
  margin-top: 10px;
  min-width: 250px; }

.card-height {
  height: 298px; }

.scrollable-content {
  padding-right: 3px;
  margin-bottom: 20px;
  height: 298px;
  max-height: 298px;
  overflow-y: auto; }

.custom-scrollbar::-webkit-scrollbar {
  border-style: visible;
  width: 10px; }

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  padding-right: 5px;
  border-radius: 10px;
  margin-bottom: 8px; }

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4); }

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #cccccc; }

#newLicense {
  margin-top: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-animation: fadeIn .6s;
          animation: fadeIn .6s; }

.license-card {
  border-color: #d1d1d1;
  border-style: solid;
  border-width: 1px;
  border-radius: 2%;
  background-color: #f6f6f6; }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: translate(-1vw, 0);
            transform: translate(-1vw, 0);
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    -webkit-transform: translate(-1vw, 0);
            transform: translate(-1vw, 0);
    opacity: 0; }
  100% {
    opacity: 1; } }

.animated {
  transition: height 2s;
  height: auto;
  width: 100%;
  max-width: 100%; }

.company-detail-container {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .company-detail-container {
      padding-top: 12px; }
      .company-detail-container .company-list-actionbar button {
        width: 100%; } }
  .company-detail-container .company-detail-actionbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .company-detail-container .company-detail-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .company-detail-container .company-detail-items .item-display-container {
      height: 270px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .company-detail-container .company-detail-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .company-detail-container .company-detail-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .company-detail-container .company-detail-items .item-display-container {
          width: 80%; } }

.company-details {
  margin-top: 10px; }
  .company-details .company-details-adress {
    margin-top: 35px; }
  .company-details .company-details-permits {
    margin-top: 35px; }
  .company-details .company-details-user {
    margin-top: 35px; }

.sub-companies-list {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.scrollable-content-list-companies {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-right: 3px;
  max-height: 105px;
  margin-bottom: 7px;
  margin-top: 7px;
  overflow-y: auto; }

.list-group-item-size {
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: 20px; }

.custom-scrollbar::-webkit-scrollbar {
  border-style: visible;
  width: 10px; }

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  margin-bottom: -3px; }

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4); }

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #cccccc; }

.companies-list {
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 75vh; }

/** 
*   Holo Maint - Theme Colors
**/
.company-item-row {
  background: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex; }
  .company-item-row:active, .company-item-row:hover {
    background: #dedede; }
    .company-item-row:active .material-icons, .company-item-row:hover .material-icons {
      border-right: 1px solid #efefef; }
  .company-item-row .material-icons {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px; }
  .company-item-row span.company-item-row-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .company-item-row span.company-item-row-name {
        font-size: 13px; } }
  .company-item-row span.company-item-row-name-inactive {
    color: #aaaaaa;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .company-item-row span.company-item-row-name-inactive {
        font-size: 13px; } }
  .company-item-row span.file-item-row-date_created {
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    margin-left: auto;
    margin-right: 12px; }

/** 
*   Holo Maint - Theme Colors
**/
.companies-list {
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 75vh; }

.company-item-row {
  background: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex; }
  .company-item-row:active, .company-item-row:hover {
    background: #dedede; }
    .company-item-row:active .material-icons, .company-item-row:hover .material-icons {
      border-right: 1px solid #efefef; }
  .company-item-row .material-icons {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px; }
  .company-item-row span.company-item-row-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .company-item-row span.company-item-row-name {
        font-size: 13px; } }
  .company-item-row span.company-item-row-subname {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .company-item-row span.company-item-row-subname {
        font-size: 11px; } }
  .company-item-row span.file-item-row-date_created {
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    margin-left: auto;
    margin-right: 12px; }

i.modal-icon {
  padding: 10px;
  font-size: 35px; }

.modal-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 auto; }
  .modal-center span {
    padding-top: 25px; }
  .modal-center .name {
    margin-left: 7px;
    text-transform: capitalize; }

.modal-full {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 auto;
  width: 100%; }
  .modal-full span {
    padding-top: 25px; }
  .modal-full .name {
    margin-left: 7px;
    text-transform: capitalize; }

.modal-block {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .modal-block span {
    padding-top: 25px; }
  .modal-block .name {
    margin-left: 7px;
    text-transform: capitalize; }

/** 
*   Holo Maint - Theme Colors
**/
.users-list-container {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .users-list-container {
      padding-top: 12px; }
      .users-list-container .users-list-actionbar button {
        width: 100%; } }
  .users-list-container .doc-tool-sort-button {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center; }
  .users-list-container .doc-tool-sort-button-flip {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
    padding-left: 8px;
    padding-right: 8px;
    text-align: center; }
  .users-list-container .users-list-actionbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .users-list-container .users-list-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .users-list-container .users-list-items .item-display-container {
      height: 270px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .users-list-container .users-list-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .users-list-container .users-list-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .users-list-container .users-list-items .item-display-container {
          width: 80%; } }

.users-list {
  padding-top: 10px; }

/** 
*   Holo Maint - Theme Colors
**/
.users-item-row {
  background: #ffffff;
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .users-item-row .material-icons {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px; }
  .users-item-row .material-icons-closed {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px;
    border-bottom: 1px solid #dedede; }
  .users-item-row div.users-item-row-info {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 12px 12px; }
    @media screen and (max-width: 500px) {
      .users-item-row div.users-item-row-info {
        font-size: 13px; } }
  .users-item-row div.blocked-users-item-row-info {
    color: #aaaaaa;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 12px 12px; }
    @media screen and (max-width: 500px) {
      .users-item-row div.blocked-users-item-row-info {
        font-size: 13px; } }
  .users-item-row p.users-item-row-name {
    margin: 0; }
  .users-item-row span.users-item-row-team {
    margin: 0;
    font-style: italic;
    font-size: 14px; }
  .users-item-row div.users-item-row-role {
    font-size: 11px; }
  .users-item-row .users-item-row-action {
    margin-left: auto;
    padding-top: 8px;
    padding-right: 24px; }
    .users-item-row .users-item-row-action .btn-group button.btn {
      background: none;
      border: 0;
      box-shadow: none;
      outline: 0;
      padding: 0; }
      .users-item-row .users-item-row-action .btn-group button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-item-row-action .btn-group button.btn i.material-icons {
        line-height: 1;
        border-right: none;
        width: 38px;
        min-width: 38px; }
  .users-item-row .users-edit-row-action {
    margin-left: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-self: stretch;
            align-self: stretch;
    -webkit-flex: 1 1;
            flex: 1 1; }
    .users-item-row .users-edit-row-action .btn-group {
      justify-self: auto; }
      .users-item-row .users-edit-row-action .btn-group button.btn {
        background: #ffffff;
        margin-left: auto;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-align-self: stretch;
                align-self: stretch; }
        .users-item-row .users-edit-row-action .btn-group button.btn:focus {
          box-shadow: none; }
        .users-item-row .users-edit-row-action .btn-group button.btn i.material-icons {
          line-height: 1;
          border-right: none;
          width: 38px;
          min-width: 38px; }
  .users-item-row .users-tool-button {
    margin-left: auto;
    vertical-align: middle;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    cursor: pointer; }
  .users-item-row .users-tool-bot-button {
    margin-left: auto;
    vertical-align: bottom;
    text-align: bottom;
    display: -webkit-flex;
    display: flex;
    cursor: pointer; }
  .users-item-row .users-tool-save-button {
    padding-top: 16px;
    padding-bottom: 16px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: 12px;
    padding-right: 32px;
    -webkit-align-self: stretch;
            align-self: stretch; }
    .users-item-row .users-tool-save-button button.btn {
      width: 100%;
      padding: 18px;
      padding-left: 24px;
      padding-right: 24px;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .users-item-row .users-tool-save-button button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-tool-save-button button.btn i.material-icons {
        border-right: none;
        text-align: center;
        font-size: 24px; }
  .users-item-row .users-tool-reset-button {
    padding-bottom: 16px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-self: stretch;
            align-self: stretch;
    box-shadow: none;
    font-size: 16px; }
    .users-item-row .users-tool-reset-button button.btn {
      padding: 0px;
      min-width: auto;
      padding-left: 4px;
      padding-right: 4px; }
      .users-item-row .users-tool-reset-button button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-tool-reset-button button.btn i.material-icons {
        line-height: 1;
        border-right: none; }
  .users-item-row .users-tool-reset-button-toggled {
    padding-bottom: 16px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-self: stretch;
            align-self: stretch;
    box-shadow: none;
    font-size: 16px; }
    .users-item-row .users-tool-reset-button-toggled button.btn {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      background: #ffffff;
      padding: 0px;
      min-width: auto;
      padding-left: 4px;
      padding-right: 4px; }
      .users-item-row .users-tool-reset-button-toggled button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-tool-reset-button-toggled button.btn i.material-icons {
        line-height: 1;
        border-right: none; }
  .users-item-row span.file-item-row-date_created {
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    margin-left: auto;
    margin-right: 12px; }

.modal .modal-dialog.products-link-document-modal {
  max-width: 60%; }
  @media screen and (max-width: 500px) {
    .modal .modal-dialog.products-link-document-modal {
      max-width: 100%; }
      .modal .modal-dialog.products-link-document-modal .table {
        font-size: 12px; } }

.modal .modal-dialog .modal-content .modal-body.products-link-document-modal-body {
  padding: 0; }

/** 
*   Holo Maint - Theme Colors
**/
.user-create-container {
  width: 100%;
  -webkit-flex: auto;
          flex: auto;
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .user-create-container {
      padding-top: 12px; }
      .user-create-container .user-create-actionbar button {
        width: 100%; } }
  .user-create-container .user-create-actionbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .user-create-container .usre-create-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .user-create-container .usre-create-items .item-display-container {
      height: 270px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .user-create-container .usre-create-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .user-create-container .usre-create-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .user-create-container .usre-create-items .item-display-container {
          width: 80%; } }
  .user-create-container .user-create-form-container {
    margin-top: 10px; }

.product-tab-qrcode-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 12px;
  padding-top: 35px;
  position: relative;
  margin-left: 20%;
  margin-right: 20%; }

.product-tab-qrcode-outer {
  display: block;
  background: #fff; }
  .product-tab-qrcode-outer .product-tab-qrcode-name {
    display: none; }
  .product-tab-qrcode-outer .product-tab-qrcode-description {
    text-align: center;
    padding: 20px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    margin-top: 12px;
    font-size: 100%; }
  .product-tab-qrcode-outer.overlay {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999; }
    .product-tab-qrcode-outer.overlay .product-tab-qrcode-name {
      display: block;
      margin-bottom: 12px;
      font-size: 300%; }
    .product-tab-qrcode-outer.overlay .product-tab-qrcode-description {
      display: none; }
    .product-tab-qrcode-outer.overlay .product-tab-qrcode-container {
      height: 70% !important;
      width: 70%; }
    .product-tab-qrcode-outer.overlay .app_icon_button.no-print {
      display: none; }
    .product-tab-qrcode-outer.overlay .app_icon_button.done-print {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px; }
    @media print {
      .product-tab-qrcode-outer.overlay .app_icon_button.done-print {
        display: none; } }
  .product-tab-qrcode-outer .app_icon_button {
    display: block;
    height: auto;
    width: auto;
    padding: 12px 0;
    margin: 0 auto; }
    .product-tab-qrcode-outer .app_icon_button.done-print {
      display: none; }
    .product-tab-qrcode-outer .app_icon_button i.material-icons {
      font-size: 48px;
      line-height: 48px; }

.product-tab-qrcode-canvas {
  height: 100%;
  width: 100%; }

.product-tab-qrcode-border {
  padding: 10%;
  border-right: 6px solid #28a745;
  border-top: 6px solid #28a745;
  border-left: 6px solid #28a745;
  border-bottom: 6px solid #28a745; }

/** 
*   Holo Maint - Theme Colors
**/
.dashboard-queue-placeholder {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }
  .dashboard-queue-placeholder i.app_icon {
    font-size: 48px; }

.queue-card {
  margin-top: 15px; }
  @media screen and (max-width: 500px) {
    .queue-card {
      margin-top: 12px; } }

.camera-warning-card {
  display: -webkit-flex;
  display: flex;
  padding: 24px;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  background-color: #cc0000 !important;
  color: #ffffff; }

.temporary-div {
  margin-left: calc(100% - 155px); }

.drop-camera {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.dropdown-content div:hover {
  background-color: #ddd; }

.drop-camera:hover .dropdown-content {
  display: block; }

.drop-camera:hover .dropbtn {
  background-color: #3e8e41; }

.dashboard-queue-status-indicator {
  font-size: 64px;
  font-weight: bold; }
  .dashboard-queue-status-indicator.red {
    color: #cc0000; }
  .dashboard-queue-status-indicator.yellow {
    color: #ffc107; }
  .dashboard-queue-status-indicator.green {
    color: #b9c900; }

.dashboard-peer {
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #959595;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  margin: 12px;
  height: 46px;
  position: relative; }
  .dashboard-peer:hover {
    background: #dedede; }
  .dashboard-peer .dashboard-peer-icon-container {
    -webkit-align-items: center;
            align-items: center;
    border-right: 1px solid #959595;
    display: -webkit-flex;
    display: flex;
    padding: 0 12px;
    height: 100%; }
    .dashboard-peer .dashboard-peer-icon-container .app_icon {
      line-height: 1; }
  .dashboard-peer span {
    margin-left: 12px; }

/** 
*   Holo Maint - Theme Colors
**/
.incoming-call-container {
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #959595;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 64px;
  position: relative;
  margin-bottom: 12px; }
  .incoming-call-container .incoming-call-user-icon {
    border-right: 1px solid #959595;
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  .incoming-call-container .incoming-call-phone-icon {
    border-left: 1px solid #959595;
    color: #959595;
    font-size: 24px;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  @media screen and (max-width: 500px) {
    .incoming-call-container {
      height: 46px; }
      .incoming-call-container .incoming-call-phone-icon {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; }
      .incoming-call-container .incoming-call-user-icon {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; } }
  .incoming-call-container:hover {
    background: rgba(0, 0, 0, 0.05); }
    .incoming-call-container:hover .incoming-call-phone-icon {
      background: #b9c900;
      color: #ffffff; }
  .incoming-call-container .incoming-call-text-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 12px;
    width: calc(100% - 74px); }
    .incoming-call-container .incoming-call-text-container p {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      margin: 0; }
    .incoming-call-container .incoming-call-text-container span {
      font-size: 10px;
      text-transform: uppercase; }

/** 
*   Holo Maint - Theme Colors
**/
.ongoing-call-container {
  -webkit-align-items: center;
  border: 1px solid #959595;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  /* position: relative; */
  margin-bottom: 12px;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .ongoing-call-container .ongoing-call-user-icon {
    border-right: 1px solid #959595;
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  .ongoing-call-container .ongoing-call-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .ongoing-call-container .ongoing-call-phone-icon-accept {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid #959595;
    background: #b9c900;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color: black;
    border-radius: 0; }
  .ongoing-call-container .ongoing-call-phone-icon-decline {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid #959595;
    background: #cc0000;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color: black;
    border-radius: 0; }
  @media screen and (max-width: 500px) {
    .ongoing-call-container {
      height: unset; }
      .ongoing-call-container .ongoing-call-phone-icon-accept {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; }
      .ongoing-call-container .ongoing-call-phone-icon-decline {
        border: 1px solid #959595;
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; }
      .ongoing-call-container .ongoing-call-user-icon {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; } }
  .ongoing-call-container:hover {
    background: rgba(0, 0, 0, 0.05); }
  .ongoing-call-container .ongoing-call-phone-icon-accept:hover {
    background: #b9c900 !important;
    color: white; }
  .ongoing-call-container .ongoing-call-phone-icon-decline:hover {
    background: #cc0000 !important;
    color: white; }
  .ongoing-call-container .ongoing-call-text-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 1.25rem;
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    .ongoing-call-container .ongoing-call-text-container .ongoing-call-info-container {
      display: grid;
      grid-template-columns: 1fr;
      -webkit-flex-grow: 1;
              flex-grow: 1; }
    .ongoing-call-container .ongoing-call-text-container p {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      margin: 0; }
    .ongoing-call-container .ongoing-call-text-container span {
      font-size: 10px;
      text-transform: uppercase;
      text-align: end; }

/** 
*   Holo Maint - Theme Colors
**/
.app-page-header-container {
  background: #dedede;
  padding: 12px;
  margin-bottom: 12px; }
  @media screen and (max-width: 500px) {
    .app-page-header-container {
      display: none; } }

/** 
*   Holo Maint - Theme Colors
**/
.app-stretched-image {
  display: block;
  position: relative;
  min-width: 80px;
  min-height: 80px; }
  .app-stretched-image img {
    height: 100%;
    object-fit: contain;
    object-position: left;
    width: 100%; }

.div-documentation-detail {
  position: fixed;
  height: 80%;
  overflow-y: scroll; }

.card-header {
  height: 60px; }

.selected-files {
  padding-bottom: 20px;
  font-size: 130%; }

.item-display-container-mobile {
  -webkit-flex-direction: column;
          flex-direction: column; }

.product-card-container-mobile {
  display: -webkit-flex;
  display: flex;
  background: #ffffff;
  margin-bottom: 10px;
  margin-left: 24px;
  margin-right: 24px;
  position: relative;
  height: 100%; }
  .product-card-container-mobile .product-card-header-mobile {
    display: -webkit-flex;
    display: flex;
    padding-left: 12px;
    padding-top: 20px; }
    .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px; }
  .product-card-container-mobile .product-card-description-mobile {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

/** 
*   Holo Maint - Theme Colors
**/
.documentation-menu .doc-tool-sort-button {
  padding-left: 8px;
  padding-right: 8px;
  text-align: center; }

.documentation-menu .doc-tool-sort-button-flip {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
  padding-left: 8px;
  padding-right: 8px;
  text-align: center; }

.documentation-menu .doc-tool-sort-column {
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px; }

.documentation-menu .doc-tool-sort-text:disabled {
  background: white;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  border: 0;
  box-shadow: none;
  outline: 0;
  padding: 16px, 16px;
  padding-right: 24px;
  height: 50px;
  background-color: transparent; }
  .documentation-menu .doc-tool-sort-text:disabled:hover {
    background: white;
    background-color: transparent; }
  .documentation-menu .doc-tool-sort-text:disabled:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent; }

.documentation-menu .doc-tool-sort-text {
  padding: 8px;
  padding-right: 16px; }

.documentation-menu .doc-tool-delete-text {
  float: right;
  padding: 8px;
  padding-right: 16px; }

/** 
*   Holo Maint - Theme Colors
**/
.btn-model > * {
  pointer-events: none; }

.modal-doc-container {
  -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background: #232323;
  width: 100%;
  height: calc(100% - 120px);
  position: fixed;
  top: 60px;
  bottom: 60px;
  right: 0;
  z-index: 999; }
  .modal-doc-container.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -1; }
  .modal-doc-container :focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0); }
  .modal-doc-container button {
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    border: 2px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    outline: none;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
    opacity: 1;
    z-index: 99999;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 26px;
    width: 26px;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .modal-doc-container button i.material-icons {
      color: #343a40;
      font-size: 18px;
      line-height: 1;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }

/** 
*   Holo Maint - Theme Colors
**/
.browser-warning-container {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.browser-warning-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px; }

.browser-warning-icon i.app_icon {
  color: #959595;
  font-size: 48px; }

.browser-warning-body {
  margin-bottom: 12px; }

.browser-warning-compatible-browsers {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 12px 0; }

.browser-warning-continue-button-container {
  margin-top: 18px; }

.browser-warning-browser-link {
  -webkit-align-items: center;
          align-items: center;
  background: #dedede;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  padding: 8px 12px;
  margin-bottom: 8px; }
  .browser-warning-browser-link img {
    height: 34px;
    margin-right: 12px;
    width: 34px; }

.browser-warning-language-toggler-container {
  position: absolute;
  top: 12px;
  right: 12px; }

/** 
*   Holo Maint - Theme Colors
**/
.body-content {
  margin-left: 60px;
  height: 100%;
  transition: margin 500ms cubic-bezier(0.19, 1, 0.22, 1); }
  .body-content.nav-open {
    margin-left: 220px; }
  .body-content.mobile {
    margin-left: 0; }

/** 
*   Holo Maint - Theme Colors
**/
.mobile-navlink {
  -webkit-align-items: center;
          align-items: center;
  color: #dedede;
  display: -webkit-flex;
  display: flex;
  padding: 12px 18px;
  text-decoration: none; }
  .mobile-navlink:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .mobile-navlink.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .mobile-navlink.active .material-icons.app_icon {
      color: #343a40; }
  .mobile-navlink span.mobile-navlink-text {
    white-space: nowrap; }
  .mobile-navlink span.mobile-navlink-landscape-text {
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap; }
  .mobile-navlink .material-icons.app_icon {
    margin-right: 18px; }

.mobile-operator-navlink {
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #dedede;
  display: -webkit-flex;
  display: flex;
  padding: 12px 9px;
  text-decoration: none;
  font-size: 8px;
  background-color: transparent; }
  .mobile-operator-navlink:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .mobile-operator-navlink.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .mobile-operator-navlink.active .material-icons.app_icon {
      color: #343a40; }
  .mobile-operator-navlink span.mobile-navlink-text {
    white-space: nowrap; }
  .mobile-operator-navlink span.mobile-navlink-landscape-text {
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap; }
  .mobile-operator-navlink .material-icons.app_icon {
    margin-right: 18px; }

.mobile-navlink-landscape {
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: #dedede;
  display: -webkit-flex;
  display: flex;
  padding: 12px 9px;
  text-decoration: none;
  font-size: 12px;
  background-color: transparent; }
  .mobile-navlink-landscape:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .mobile-navlink-landscape.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .mobile-navlink-landscape.active .material-icons.app_icon {
      color: #343a40; }

/** 
*   Holo Maint - Theme Colors
**/
@-webkit-keyframes underlayFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes underlayFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes slideInFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInFromLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.mobile-nav-menu-outer {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999; }
  .mobile-nav-menu-outer .mobile-nav-menu-underlay {
    -webkit-animation-name: underlayFadeIn;
            animation-name: underlayFadeIn;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
            animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .mobile-nav-menu-outer .mobile-nav-menu-side {
    -webkit-animation-name: slideInFromLeft;
            animation-name: slideInFromLeft;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
            animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    position: absolute;
    background: #343a40;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: 300px;
    width: 90%; }
    .mobile-nav-menu-outer .mobile-nav-menu-side .mobile-nav-title {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      height: 75px;
      -webkit-justify-content: center;
              justify-content: center; }
      .mobile-nav-menu-outer .mobile-nav-menu-side .mobile-nav-title strong {
        color: #cc0000;
        font-size: 18px;
        line-height: 15px;
        font-weight: bold;
        text-transform: uppercase; }
      .mobile-nav-menu-outer .mobile-nav-menu-side .mobile-nav-title span {
        color: #ffffff;
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase; }

/** 
*   Holo Maint - Theme Colors
**/
.layout-navbar-navlink {
  -webkit-align-items: center;
          align-items: center;
  color: lightgray;
  display: -webkit-flex;
  display: flex;
  padding: 12px 18px;
  text-decoration: none; }
  .layout-navbar-navlink:hover {
    background: #959595;
    color: #343a40;
    text-decoration: none; }
  .layout-navbar-navlink.active {
    background: #efefef;
    color: #343a40;
    text-decoration: none; }
    .layout-navbar-navlink.active .material-icons.app_icon {
      color: #343a40; }
  .layout-navbar-navlink span.layout-navbar-navlink-text {
    white-space: nowrap; }
  .layout-navbar-navlink .material-icons.app_icon {
    margin-right: 18px; }

.edit-company-logo {
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 25px;
  top: 138px; }
  .edit-company-logo i.material-icons.app_icon {
    padding: 0;
    width: 25px;
    height: 25px;
    background: dimgray;
    border-radius: 50%;
    font-size: 17px; }

/** 
*   Holo Maint - Theme Colors
**/
.layout-navmenu {
  background: #343a40;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  width: 60px; }
  .layout-navmenu.is-open {
    width: 220px;
    overflow: auto; }
  .layout-navmenu .layout-navmenu-small-title {
    color: #cc0000;
    font-size: 24px;
    font-weight: bold;
    height: 75px;
    line-height: 75px;
    text-transform: uppercase;
    text-align: center; }
  .layout-navmenu .layout-navmenu-title {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 75px;
    -webkit-justify-content: center;
            justify-content: center; }
    .layout-navmenu .layout-navmenu-title strong {
      color: #cc0000;
      font-size: 18px;
      line-height: 15px;
      font-weight: bold;
      text-transform: uppercase; }
    .layout-navmenu .layout-navmenu-title span {
      color: #ffffff;
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase; }

i.material-icons.app_icon.user-icon {
  padding: 0px;
  width: 70px;
  height: 70px;
  background: white;
  border-radius: 50%;
  font-size: 45px;
  margin-bottom: 10px; }

.user-name {
  color: white;
  margin-bottom: 30px; }

.team-name {
  font-size: 12px; }

.modal .modal-dialog.products-upload-document-modal {
  max-width: 60%; }
  @media screen and (max-width: 500px) {
    .modal .modal-dialog.products-upload-document-modal {
      max-width: 100%; } }

/** 
*   Holo Maint - Theme Colors
**/
.layout-navbar {
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  background: #605d5c;
  display: -webkit-flex;
  display: flex;
  height: 60px;
  padding: 0 1rem; }
  .layout-navbar .layout-navbar-mobile-title {
    font-size: 18px;
    margin-left: 6px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #dedede; }
  .layout-navbar .right-container {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    margin-left: auto; }

.layout-navbar-expert {
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  background: #ffffff;
  display: -webkit-flex;
  display: flex;
  height: 60px;
  padding: 0 1rem; }
  .layout-navbar-expert .layout-navbar-mobile-title {
    font-size: 18px;
    margin-left: 6px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #dedede; }
  .layout-navbar-expert .right-container {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    margin-left: auto; }

.btn.select-role {
  background: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  color: #ffffff; }
  .btn.select-role:active, .btn.select-role:focus, .btn.select-role:hover {
    background: none;
    box-shadow: none;
    border: none; }

/** 
*   Holo Maint - Theme Colors
**/
.mobile-bottom-menu-bar {
  position: fixed;
  bottom: 0;
  height: 60px;
  left: 0;
  right: 0;
  z-index: 1028;
  touch-action: manipulation; }

.layout-navbar {
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  background: #605d5c;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 60px;
  padding: 0 1rem;
  align-items: center; }

.layout-navbar-files {
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  background: #605d5c;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 60px;
  padding: 0 1rem;
  align-items: center; }

.bottom-item {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

/** 
*   Holo Maint - Theme Colors
**/
.nav-home {
  color: #ffffff;
  background: transparent;
  padding: 12px 0; }

.nav-bottom {
  color: #ffffff;
  background: transparent;
  padding: 0 0; }

.nav-bottom-landscape {
  padding: "18px 0px";
  margin-right: 6px;
  color: #ffffff;
  background: transparent;
  padding: 0 0; }

.nav-bottom-mobile {
  background-color: transparent; }

/** 
*   Holo Maint - Theme Colors
**/
.product-detail-display-container {
  padding: 0 12px;
  position: relative;
  width: 70%; }
  .product-detail-display-container .product-details-image-mobile {
    display: block;
    max-height: 150px;
    min-height: 150px;
    object-fit: contain;
    position: relative;
    width: 100%; }
    .product-detail-display-container .product-details-image-mobile:before {
      height: 100%;
      width: 100%;
      content: " ";
      display: block;
      position: absolute;
      background-image: url("/images/product-placeholder.gif");
      background-size: contain;
      background-position: center;
      background-color: #fff;
      background-repeat: no-repeat; }
  .product-detail-display-container .item-inputfield-text {
    text-overflow: ellipsis; }
  @media screen and (max-width: 920px) {
    .product-detail-display-container {
      width: 100%; } }

.item_detail_tab_display_container {
  margin-top: 18px; }

.nav-tabs.custom-nav {
  background: #fff;
  border: none; }
  @media screen and (max-width: 500px) {
    .nav-tabs.custom-nav .nav-item {
      text-align: center;
      width: 100%; } }

.nav-tabs .nav-link.custom-nav-link.custom-nav-link {
  background: none;
  border: none;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.56);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 24px; }
  .nav-tabs .nav-link.custom-nav-link.custom-nav-link:hover {
    border: none;
    background: #f3f3f3; }
  .nav-tabs .nav-link.custom-nav-link.custom-nav-link.active.active.active {
    border: none;
    background: #dedede;
    color: rgba(0, 0, 0, 0.87); }
  @media screen and (max-width: 500px) {
    .nav-tabs .nav-link.custom-nav-link.custom-nav-link {
      font-size: 11px;
      text-transform: uppercase;
      padding: 12px; } }

.modal .modal-dialog.products-link-document-modal {
  max-width: 60%; }
  @media screen and (max-width: 500px) {
    .modal .modal-dialog.products-link-document-modal {
      max-width: 100%; }
      .modal .modal-dialog.products-link-document-modal .table {
        font-size: 12px; } }

.modal .modal-dialog .modal-content .modal-body.products-link-document-modal-body {
  padding: 0; }

.documentation-tab-button {
  margin-right: 12px; }
  @media screen and (max-width: 500px) {
    .documentation-tab-button {
      margin-right: 0;
      margin-bottom: 12px;
      width: 100%; } }

.modal .modal-dialog.products-link-document-modal {
  max-width: 60%; }
  @media screen and (max-width: 500px) {
    .modal .modal-dialog.products-link-document-modal {
      max-width: 100%; }
      .modal .modal-dialog.products-link-document-modal .table {
        font-size: 12px; } }

.modal .modal-dialog .modal-content .modal-body.products-link-document-modal-body {
  padding: 0; }

.worklog-list-container {
  margin-top: 12px; }

.worklog-row {
  cursor: pointer; }

.page-number {
  margin-left: calc(100% - 140px); }

.div-buttons {
  margin-left: calc(50% - 65px);
  margin-top: -70px; }

.date-filter-class {
  margin-left: calc(100% - 460px); }

.button {
  background-color: #bfbfbf;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; }

.item-image-display-container {
  padding: 0 12px 0 0;
  width: 100%; }
  .item-image-display-container .item-image-display-container-edit-icon {
    padding-right: 0px;
    -webkit-align-self: stretch;
            align-self: stretch;
    margin-right: 0px;
    margin-left: auto;
    cursor: pointer; }

.product-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 12px 0; }

.qr-modal-overlay {
  background-color: transparent; }

.qr-video {
  margin: auto;
  width: 100%; }

/** 
*   Holo Maint - Theme Colors
**/
.products-list-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .products-list-container {
      padding-top: 12px; }
      .products-list-container .products-list-actionbar button {
        width: 100%; } }
  .products-list-container .products-list-actionbar {
    padding: 0 12px; }
  .products-list-container .products-list-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .products-list-container .products-list-items .item-display-container {
      height: 280px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .products-list-container .products-list-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .products-list-container .products-list-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .products-list-container .products-list-items .item-display-container {
          width: 100%; } }

.products-list-container-mobile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.products-list-container-mobile-active-call {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 12px;
  border-radius: 10px; }

.product_card_header {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  min-height: 62px; }
  .product_card_header .button-container {
    margin-left: auto; }

.search-bar {
  padding: 10px;
  text-align: right;
  margin-right: 15px; }
  @media only screen and (max-device-width: 700px) and (orientation: portrait) {
    .search-bar {
      text-align: center;
      padding: 10px;
      margin-right: 0px; }
      .search-bar .input {
        width: calc(100% - 95px); } }

.qr-code-button {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%; }
  .qr-code-button :focus {
    outline: none;
    -webkit-tap-highlight-color: transparent; }
  .qr-code-button button {
    -webkit-align-items: center;
            align-items: center;
    right: 12px;
    bottom: 24px;
    background: #fff;
    border: 4px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    margin: 0 12px;
    outline: none;
    opacity: 1;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 64px;
    width: 64px; }
    .qr-code-button button.icon-primary i.material-icons {
      color: #343a40; }
    .qr-code-button button.icon-primary-2 {
      left: calc(100% - 170px); }
    .qr-code-button button i.material-icons {
      color: #343a40;
      font-size: 28px;
      line-height: 1;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }

/** 
*   Holo Maint - Theme Colors
**/
.timeline {
  margin-top: 50px; }

.timeline-block {
  position: relative;
  margin: 45px 0; }
  .timeline-block:first-child {
    margin-top: 0; }
  .timeline-block:last-child:before {
    height: 0; }
  .timeline-block:before {
    content: "";
    position: absolute;
    left: 24px;
    height: calc(100% + 45px);
    width: 4px;
    background: #dedede;
    box-sizing: border-box; }
  .timeline-block:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 60px;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff; }

.timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #dedede;
  text-align: center;
  background-color: #ffffff !important;
  color: #343a40; }
  .timeline-icon .material-icons {
    line-height: 1.9 !important; }

.timeline-content {
  position: relative;
  margin-left: 70px;
  background: #ffffff;
  border-radius: 0.25em;
  padding: 1em; }
  .timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-right: 7px soli #ffffff; }

.app-stretched-image-mobile {
  display: block;
  position: relative;
  top: 50%;
  height: 80px;
  width: 80px;
  min-width: 80px;
  min-height: 80px; }
  .app-stretched-image-mobile .icon {
    height: 100%;
    object-fit: contain;
    object-position: left;
    width: 100%; }

.item-display-container-mobile {
  -webkit-flex-direction: column;
          flex-direction: column; }

.form-control-help {
  box-shadow: none;
  background: #fdfdfd;
  transition: background-color 0.2s ease, padding-left 0.2s linear;
  padding-left: 0.75rem;
  width: 75%;
  overflow-y: scroll;
  min-height: 50vh; }

.form-group label {
  font-weight: 600;
  font-size: 0.9rem; }

.input-group > .form-control {
  height: 50px; }

textarea {
  resize: vertical;
  overflow: auto;
  min-height: 150px; }

label {
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0; }

.form-control-help:disabled {
  background: none;
  border: none;
  padding-left: 0;
  transition: background-color 0.2s ease, padding-left 0.2s linear; }

.product-card-container-mobile {
  display: -webkit-flex;
  display: flex;
  background: #ffffff;
  margin-bottom: 10px;
  position: relative;
  height: 100%; }
  .product-card-container-mobile .product-card-header-mobile {
    display: -webkit-flex;
    display: flex;
    padding-left: 12px;
    padding-top: 20px;
    overflow: hidden; }
    .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow: hidden;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
  .product-card-container-mobile .product-card-header-product-mobile {
    display: -webkit-flex;
    display: flex;
    padding-left: 12px;
    padding-top: 50px;
    overflow: hidden; }
    .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow: hidden;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
      .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
  .product-card-container-mobile .product-card-description-mobile {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

/** 
*   Holo Maint - Theme Colors
**/
/*.products-list-button{
	width: 100%;
	flex: auto;
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	// @media screen and (max-width: $break-mobile) {
	// 	padding-top: 12px;

	// 	.user-create-actionbar {
	// 		button {
	// 			width: 100%;
	// 		}
	// 	}
	// }
}*/
.teams-list-container {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .teams-list-container {
      padding-top: 12px; }
      .teams-list-container .teams-list-actionbar button {
        width: 100%; } }
  .teams-list-container .teams-list-actionbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .teams-list-container .teams-list-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .teams-list-container .teams-list-items .item-display-container {
      height: 270px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .teams-list-container .teams-list-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .teams-list-container .teams-list-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .teams-list-container .teams-list-items .item-display-container {
          width: 80%; } }

.team-detail-container .team-unselected {
  margin-top: 10px;
  height: 600px;
  color: #eee;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 24px solid #dedede;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .team-detail-container .team-unselected i.material-icons.app_icon.team-unselected-icon {
    font-size: 60px; }

.app-detail-container .app-unselected {
  margin-top: 10px;
  height: 300px;
  color: #eee;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 24px solid #dedede;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .app-detail-container .app-unselected i.material-icons.app_icon.app-unselected-icon {
    font-size: 60px; }

.teams-list {
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 75vh; }

/** 
*   Holo Maint - Theme Colors
**/
.team-item-row {
  background: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex; }
  .team-item-row:active, .team-item-row:hover {
    background: #dedede; }
    .team-item-row:active .material-icons, .team-item-row:hover .material-icons {
      border-right: 1px solid #efefef; }
  .team-item-row .material-icons {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px; }
  .team-item-row span.team-item-row-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .team-item-row span.team-item-row-name {
        font-size: 13px; } }
  .team-item-row span.team-item-row-name-inactive {
    color: #aaaaaa;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 2.3; }
    @media screen and (max-width: 500px) {
      .team-item-row span.team-item-row-name-inactive {
        font-size: 13px; } }
  .team-item-row span.file-item-row-date_created {
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    margin-left: auto;
    margin-right: 12px; }

.animated {
  transition: height 2s;
  height: auto;
  width: 100%;
  max-width: 100%; }

.team-detail-container {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 500px) {
    .team-detail-container {
      padding-top: 12px; }
      .team-detail-container .team-list-actionbar button {
        width: 100%; } }
  .team-detail-container .team-detail-actionbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .team-detail-container .team-detail-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .team-detail-container .team-detail-items .item-display-container {
      height: 290px;
      width: 25%; }
      @media screen and (max-width: 1440px) {
        .team-detail-container .team-detail-items .item-display-container {
          width: 33.3%; } }
      @media screen and (max-width: 920px) {
        .team-detail-container .team-detail-items .item-display-container {
          width: 50%; } }
      @media screen and (max-width: 380px) {
        .team-detail-container .team-detail-items .item-display-container {
          width: 80%; } }

.team-details {
  margin-top: 10px; }
  .team-details .team-details-adress {
    margin-top: 35px; }
  .team-details .team-details-user {
    margin-top: 35px; }

modal .modal-dialog.team-link-products-modal {
  max-width: 60%; }
  @media screen and (max-width: 500px) {
    modal .modal-dialog.team-link-products-modal {
      max-width: 100%; }
      modal .modal-dialog.team-link-products-modal .table {
        font-size: 12px; } }

.modal .modal-dialog .modal-content .modal-body.team-link-products-modal-body {
  padding: 0; }
  .modal .modal-dialog .modal-content .modal-body.team-link-products-modal-body th {
    vertical-align: middle; }
  .modal .modal-dialog .modal-content .modal-body.team-link-products-modal-body .no-border-table td {
    vertical-align: middle;
    border-top: none; }
  .modal .modal-dialog .modal-content .modal-body.team-link-products-modal-body .small-thumbnail {
    min-width: 70px;
    min-height: 40px;
    height: 6vh;
    width: 6vw; }
  .modal .modal-dialog .modal-content .modal-body.team-link-products-modal-body .small-product-code {
    font-size: 12px; }

/** 
*   Holo Maint - Theme Colors
**/
.settings-container {
  padding: 12px; }

.setting-display-container {
  padding: 12px; }

.settings-card-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #ffffff;
  margin-bottom: 24px;
  padding-bottom: 12px;
  position: relative;
  height: 100%; }
  .settings-card-container .settings-card-header {
    padding-top: 12px; }
    .settings-card-container .settings-card-header .settings-card-header-text p {
      margin: 12px 0;
      padding: 0;
      font-size: 15px;
      font-weight: bold;
      margin-left: 12px; }
    .settings-card-container .settings-card-header .settings-card-header-text span {
      color: #cc0000;
      font-size: 13px;
      margin-left: 12px; }
  .settings-card-container .settings-card-description {
    font-size: 12px;
    margin: 12px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .settings-card-container .settings-card-content {
    display: -webkit-flex;
    display: flex; }

.card-header .ticket-menu-button {
  margin-left: auto; }

.card-body .generate-link-col-button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding-top: 8px; }

/** 
*   Holo Maint - Theme Colors
**/
.timeline {
  margin-top: 50px; }

.timeline-block {
  position: relative;
  margin: 45px 0; }
  .timeline-block:first-child {
    margin-top: 0; }
  .timeline-block:last-child:before {
    height: 0; }
  .timeline-block:before {
    content: "";
    position: absolute;
    left: 24px;
    height: calc(100% + 45px);
    width: 4px;
    background: #dedede;
    box-sizing: border-box; }
  .timeline-block:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 60px;
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff; }

.timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #dedede;
  text-align: center;
  background-color: #ffffff !important;
  color: #343a40; }
  .timeline-icon .material-icons {
    line-height: 1.9 !important; }

.timeline-content {
  position: relative;
  margin-left: 70px;
  background: #ffffff;
  border-radius: 0.25em;
  padding: 1em; }
  .timeline-content:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-right: 7px soli #ffffff; }

.app-stretched-image-mobile {
  display: block;
  position: relative;
  top: 50%;
  height: 80px;
  width: 80px;
  min-width: 80px;
  min-height: 80px; }
  .app-stretched-image-mobile .icon {
    height: 100%;
    object-fit: contain;
    object-position: left;
    width: 100%; }

.item-display-container-mobile {
  -webkit-flex-direction: column;
          flex-direction: column; }

.product-card-container-mobile {
  display: -webkit-flex;
  display: flex;
  background: #ffffff;
  margin-bottom: 10px;
  position: relative;
  height: 100%; }
  .product-card-container-mobile .product-card-header-mobile {
    display: -webkit-flex;
    display: flex;
    padding-left: 12px;
    padding-top: 20px;
    overflow: hidden; }
    .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow: hidden;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
  .product-card-container-mobile .product-card-header-product-mobile {
    display: -webkit-flex;
    display: flex;
    padding-left: 12px;
    padding-top: 50px;
    overflow: hidden; }
    .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      overflow: hidden;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
      .product-card-container-mobile .product-card-header-product-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        margin-right: 28px;
        overflow: hidden; }
  .product-card-container-mobile .product-card-description-mobile {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

/** 
*   Holo Maint - Theme Colors
**/
.modal-ticket-container {
  -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  background: #232323;
  width: 100%;
  height: calc(100vh - 120px);
  position: fixed;
  top: 60px;
  bottom: 60px;
  right: 0;
  z-index: 999; }
  .modal-ticket-container.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -1; }
  .modal-ticket-container button {
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    border: 2px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    outline: none;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
    opacity: 1;
    z-index: 99999;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 26px;
    width: 26px;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .modal-ticket-container button i.material-icons {
      color: #343a40;
      font-size: 18px;
      line-height: 1; }

.troubleshooting-actions-container {
  display: -webkit-flex;
  display: flex; }
  @media screen and (max-width: 500px) {
    .troubleshooting-actions-container {
      display: none; } }

.troubleshooting-actions-container-buttons {
  -webkit-align-items: center;
          align-items: center;
  background: transparent;
  display: -webkit-flex;
  display: flex;
  margin-left: auto; }
  .troubleshooting-actions-container-buttons .disabled {
    cursor: not-allowed; }

.languages-dropdown-container {
  padding: 12px; }

.troubleshooting-node {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 64px;
  margin-top: 12px;
  margin-left: 0px;
  position: relative; }
  .troubleshooting-node .node-icon {
    font-size: 36px;
    margin-left: 18px;
    margin-right: 18px;
    -webkit-flex: 0 1;
            flex: 0 1; }
  @media screen and (max-width: 500px) {
    .troubleshooting-node {
      height: 48px; }
      .troubleshooting-node .node-icon {
        font-size: 24px;
        margin-left: 10px;
        margin-right: 10px;
        -webkit-flex: 0 1;
                flex: 0 1; } }
  .troubleshooting-node.active {
    background: #eaeae9;
    box-shadow: 0 0 2px 0 inset black; }
  .troubleshooting-node .node-text {
    font-size: 13px;
    width: 70%;
    overflow: hidden;
    margin-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-flex: 1 1;
            flex: 1 1; }
  .troubleshooting-node .troubleshooting-node-actions {
    margin-left: 0px;
    position: relative;
    height: 100%; }
    @media screen and (max-width: 500px) {
      .troubleshooting-node .troubleshooting-node-actions {
        display: none; } }
    .troubleshooting-node .troubleshooting-node-actions .troubleshooting-node-actions-button {
      background: transparent;
      border-left: 1px solid #e0e0e0;
      height: 100%;
      width: 48px; }

/** 
*   Holo Maint - Theme Colors
**/
.troubleshooting-node-mobile {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  padding: 0 12px;
  margin-top: 12px;
  min-height: 54px;
  word-break: break-word; }
  .troubleshooting-node-mobile .troubleshooting-node-text-mobile {
    font-size: 12px;
    display: block;
    word-wrap: break-word;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-left: 12px; }

.dot-filled {
  margin-right: 10px;
  margin-left: 5px;
  padding-top: 15px;
  height: 16px;
  width: 16px;
  background-color: #605d5c;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 0 0 2pt #b0aeae;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.dot {
  margin-right: 10px;
  margin-left: 5px;
  padding-top: 15px;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 0 0 2pt #b0aeae;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.troubleshooting-node {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 64px;
  margin-top: 12px;
  margin-left: 0px;
  position: relative; }
  .troubleshooting-node .node-icon {
    font-size: 36px;
    margin-left: 18px;
    margin-right: 18px; }
  @media screen and (max-width: 500px) {
    .troubleshooting-node {
      height: 48px; }
      .troubleshooting-node .node-icon {
        font-size: 24px;
        margin-left: 10px;
        margin-right: 10px; } }
  .troubleshooting-node.active {
    background: #eaeae9;
    box-shadow: 0 0 2px 0 inset black; }
  .troubleshooting-node .node-text {
    font-size: 13px;
    overflow: hidden;
    margin-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .troubleshooting-node .troubleshooting-node-actions {
    margin-left: auto;
    position: relative;
    height: 100%; }
    @media screen and (max-width: 500px) {
      .troubleshooting-node .troubleshooting-node-actions {
        display: none; } }
    .troubleshooting-node .troubleshooting-node-actions .troubleshooting-node-actions-button {
      background: transparent;
      border-left: 1px solid #e0e0e0;
      height: 100%;
      width: 48px; }

.troubleshooting-nav-button {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }
  .troubleshooting-nav-button button {
    background: #00000000;
    border: 4px solid #00000000; }
    .troubleshooting-nav-button button.icon-primary i.material-icons {
      color: #343a40; }
    .troubleshooting-nav-button button i.material-icons {
      color: #343a40;
      font-size: 28px; }

.align-top-element {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.troubleshooting-question-editor-container {
  background: #fff; }
  @media screen and (max-width: 500px) {
    .troubleshooting-question-editor-container {
      display: none; } }
  .troubleshooting-question-editor-container .troubleshooting-question-editor-header {
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #e0e0e0;
    display: -webkit-flex;
    display: flex;
    padding: 8px 24px; }
    .troubleshooting-question-editor-container .troubleshooting-question-editor-header .troubleshooting-question-editor-buttons {
      margin-left: auto; }
  .troubleshooting-question-editor-container .troubleshooting-question-editor-body {
    padding: 8px 24px; }
    .troubleshooting-question-editor-container .troubleshooting-question-editor-body .troubleshooting-question-editor-body-placeholder {
      padding: 24px 0;
      text-align: center; }
    .troubleshooting-question-editor-container .troubleshooting-question-editor-body .troubleshooting-form-group-container :not():last-child div {
      margin-bottom: 1rem; }
  .troubleshooting-question-editor-container .troubleshooting-question-editor-textarea {
    font-size: 12px; }
  .troubleshooting-question-editor-container .troubleshooting-question-editor-actions {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    padding: 0 24px 24px; }
    .troubleshooting-question-editor-container .troubleshooting-question-editor-actions .troubleshooting-question-editor-savebutton {
      margin-left: auto; }

.troubleshooting-answer-edit-actions {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex; }

.bold-troubleshooting-completed {
  padding-left: 5px;
  font-weight: 700; }

.troubleshooting-answer-edit-node-container {
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .troubleshooting-answer-edit-node-container .troubleshooting-answer-edit-node-header {
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #e0e0e0;
    display: -webkit-flex;
    display: flex;
    padding: 8px 24px; }
    .troubleshooting-answer-edit-node-container .troubleshooting-answer-edit-node-header .troubleshooting-answer-edit-node-header-text {
      font-size: 16px; }
    .troubleshooting-answer-edit-node-container .troubleshooting-answer-edit-node-header .troubleshooting-answer-edit-node-header-buttons {
      margin-left: auto; }
  .troubleshooting-answer-edit-node-container .troubleshooting-answer-edit-node-body {
    padding: 8px 24px; }
  .troubleshooting-answer-edit-node-container .troubleshooting-answer-editor-textarea {
    font-size: 12px; }

.troubleshooting-answer-node-mobile {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  padding: 0 24px;
  margin-top: 12px;
  min-height: 54px; }
  .troubleshooting-answer-node-mobile .troubleshooting-answer-node-text-mobile {
    font-size: 12px;
    display: block;
    word-wrap: break-word;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 4px;
    margin-bottom: 4px;
    word-break: break-word; }

.troubleshooting-answer-node {
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  padding: 0 24px;
  margin-top: 12px;
  height: 54px; }
  .troubleshooting-answer-node:hover {
    background: #fafafa; }
  .troubleshooting-answer-node.active {
    background: #eaeae9; }
  .troubleshooting-answer-node .troubleshooting-answer-node-key {
    font-size: 48px;
    margin-right: 24px; }
  .troubleshooting-answer-node .troubleshooting-answer-node-text {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .troubleshooting-answer-node .troubleshooting-answer-node-actions {
    margin-left: auto; }
    .troubleshooting-answer-node .troubleshooting-answer-node-actions .app_icon_button {
      background: transparent;
      height: 100%;
      width: auto; }
      .troubleshooting-answer-node .troubleshooting-answer-node-actions .app_icon_button i {
        font-size: 36px; }

/** 
*   Holo Maint - Theme Colors
**/
@-webkit-keyframes whiteFlash {
  0% {
    display: block;
    opacity: 1; }
  95% {
    opacity: 0; }
  100% {
    display: none; } }
@keyframes whiteFlash {
  0% {
    display: block;
    opacity: 1; }
  95% {
    opacity: 0; }
  100% {
    display: none; } }

@-webkit-keyframes pulsate {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes pulsate {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.1; }
  100% {
    opacity: 1; } }

@keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes showcam {
  from {
    opacity: 0;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes showcam {
  from {
    opacity: 0;
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes hidecam {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); } }

@keyframes hidecam {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px); } }

.camera-selected-indicator {
  right: 16px;
  top: 22px;
  height: 16px;
  width: 16px;
  background-color: #b9c900;
  border-radius: 50%;
  margin: 0 12px;
  display: inline-block;
  position: absolute;
  font-size: 70%;
  font-weight: 700;
  text-align: center;
  z-index: 999; }

.video_view {
  position: relative;
  width: 100%;
  height: 0; }
  .video_view #swap-camera-button {
    -webkit-align-items: center;
            align-items: center;
    right: 18px;
    top: 24px;
    background: #fff;
    border: 4px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    margin: 0 12px;
    outline: none;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 998;
    height: 46px;
    width: 46px; }
    .video_view #swap-camera-button i.material-icons {
      color: #343a40;
      font-size: 24px;
      line-height: 1;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
  .video_view #change-resolution-button {
    -webkit-align-items: center;
            align-items: center;
    right: 18px;
    top: 80px;
    background: #fff;
    border: 4px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    margin: 0 12px;
    outline: none;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 998;
    height: 46px;
    width: 46px; }
    .video_view #change-resolution-button i.material-icons {
      color: #343a40;
      font-size: 24px;
      line-height: 1;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
  .video_view .remote_view_view {
    position: absolute;
    border: 2px solid #dedede;
    width: 40%;
    top: 10px;
    left: 10px;
    z-index: 999; }
    @media (orientation: landscape) {
      .video_view .remote_view_view {
        width: 15%; } }
  .video_view .remote_view_hidden {
    visibility: hidden; }
  .video_view .spinner {
    color: #fff;
    position: absolute;
    top: 30vh;
    left: calc(50vw - 45px);
    height: 90px;
    width: 90px; }

#current-model-2 .react-transform-component {
  overflow: visible; }

.dropdown-resolution {
  position: inherit;
  display: inline-block; }

.dropdown-resolution-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  right: 0px;
  top: calc(100% + 123px);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999; }

.dropdown-resolution a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.dropdown-resolution-content a:hover {
  background-color: #ccc; }

.dropdown-resolution:hover .dropdown-resolution-content {
  display: block; }

.active-call-page-localvideo-container-expanded {
  background: #343434;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0;
  top: 0;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  z-index: 1031; }
  .active-call-page-localvideo-container-expanded .mobile-caller-localvideo-container {
    background: transparent; }
  .active-call-page-localvideo-container-expanded .mobile-caller-localvideo-element {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media (orientation: landscape) {
      .active-call-page-localvideo-container-expanded .mobile-caller-localvideo-element {
        width: 100%;
        height: auto; } }
  .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    right: 0;
    padding: 22px; }
    .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      width: 100%; }
      .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
        -webkit-align-items: center;
                align-items: center;
        background: #fff;
        border: 4px solid #dedede;
        border-radius: 100%;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        margin: 0 12px;
        outline: none;
        opacity: 1;
        -webkit-justify-content: center;
                justify-content: center;
        position: relative;
        transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
        height: 64px;
        width: 64px; }
        @media screen and (max-width: 700px) {
          .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
            height: 46px;
            width: 46px; } }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
          height: 86px;
          width: 86px; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
              height: 64px;
              width: 64px; } }
          .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
            font-size: 36px; }
            @media screen and (max-width: 700px) {
              .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
                font-size: 36px; } }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-red i.material-icons {
          color: #cc0000;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-green i.material-icons {
          color: #b9c900;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
          color: #343a40;
          font-size: 28px;
          line-height: 1;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
              font-size: 20px; } }
  .active-call-page-localvideo-container-expanded video.active-call-page-localvideo-element {
    border-radius: 4px;
    height: 100%;
    object-position: center;
    object-fit: contain;
    width: 100%; }
  .active-call-page-localvideo-container-expanded .active-call-page-modal-container {
    -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
            animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    background: #232323;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999; }
    .active-call-page-localvideo-container-expanded .active-call-page-modal-container.hidden {
      visibility: hidden;
      opacity: 0;
      z-index: -1; }
    .active-call-page-localvideo-container-expanded .active-call-page-modal-container button {
      -webkit-align-items: center;
              align-items: center;
      background: #fff;
      border: 2px solid #dedede;
      border-radius: 100%;
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      outline: none;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 0;
      opacity: 1;
      z-index: 99999;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 26px;
      width: 26px;
      transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .active-call-page-localvideo-container-expanded .active-call-page-modal-container button i.material-icons {
        color: #343a40;
        font-size: 18px;
        line-height: 1;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }

.active-call-page-localvideo-container {
  background: #343434;
  position: fixed;
  width: 100%;
  height: calc(100vh - 120px);
  bottom: 60px;
  left: 0;
  top: 60px;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0; }
  .active-call-page-localvideo-container .mobile-caller-localvideo-container {
    background: transparent; }
  .active-call-page-localvideo-container .mobile-caller-localvideo-element {
    width: auto;
    height: 100%; }
  .active-call-page-localvideo-container .active-call-page-localvideo-menu {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 60px;
    left: 0;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    right: 0;
    padding: 22px; }
    .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      width: 100%; }
      .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
        -webkit-align-items: center;
                align-items: center;
        background: #fff;
        border: 4px solid #dedede;
        border-radius: 100%;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        margin: 0 12px;
        outline: none;
        opacity: 1;
        -webkit-justify-content: center;
                justify-content: center;
        position: relative;
        transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
        height: 64px;
        width: 64px; }
        @media screen and (max-width: 700px) {
          .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
            height: 46px;
            width: 46px; } }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
          height: 86px;
          width: 86px; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
              height: 64px;
              width: 64px; } }
          .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
            font-size: 36px; }
            @media screen and (max-width: 700px) {
              .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
                font-size: 36px; } }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-red i.material-icons {
          color: #cc0000;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-green i.material-icons {
          color: #b9c900;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
          color: #343a40;
          font-size: 28px;
          line-height: 1;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
              font-size: 20px; } }
  .active-call-page-localvideo-container video.active-call-page-localvideo-element {
    border-radius: 4px;
    height: 100%;
    object-position: center;
    object-fit: contain;
    width: 100%; }
  .active-call-page-localvideo-container .active-call-page-modal-container {
    -webkit-animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
            animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    background: #232323;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 999; }
    .active-call-page-localvideo-container .active-call-page-modal-container.hidden {
      visibility: hidden;
      opacity: 0;
      z-index: -1; }
    .active-call-page-localvideo-container .active-call-page-modal-container button {
      -webkit-align-items: center;
              align-items: center;
      background: #fff;
      border: 2px solid #dedede;
      border-radius: 100%;
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      outline: none;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 0;
      opacity: 1;
      z-index: 99999;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 26px;
      width: 26px;
      transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .active-call-page-localvideo-container .active-call-page-modal-container button i.material-icons {
        color: #343a40;
        font-size: 18px;
        line-height: 1;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none; }

.container-parent {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-align-content: stretch;
            align-content: stretch;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
}

.banner-div {
    background-image: url(/images/OLO2DT0.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    width: 45vmin;
    padding: 5%;
}

.landing-page-card-parent {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.landing-page-card-header {
    padding: 22px;
    background: #606060;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 20%);
}

.landing-page-card-body {
    padding: 3%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.parent-button {
    padding: 3%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.submit-button-active{
    border: none;
    background: #C21717;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 20%);
    color: white;
    padding: 0.5vw 2vh;
    font-size: 2rem;
}

.submit-button-disable {
    border: none;
    background: #A8A8A8;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 20%);
    color: white;
    padding: 0.5vw 2vh;
    font-size: 2rem;
}

.input-modified {
    width: 40vmin;
    margin-right: 2rem;
}

.div-form {
    margin-bottom: 2rem;
}
.text-props {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-style: double;
  padding: 5%;
  font-size: 150%; }

.pointer:hover {
  cursor: pointer; }

#text-title-props {
  color: #772626; }

.user-qrcode-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: calc(100% - 120px);
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .user-qrcode-container .user-qrcode-svg {
    padding: 25px;
    width: 100%;
    height: 100%; }
  .user-qrcode-container .user-qrcode-color-picker {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: space-between;
            align-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

/** 
*   Holo Maint - Theme Colors
**/
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #343a40;
  --secondary: #dedede;
  --success: #b9c900;
  --info: #009ee0;
  --warning: #ffc107;
  --danger: #cc0000;
  --light: #dedede;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #605d5c;
  text-align: left;
  background-color: #efefef; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #343a40;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #121416;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #efefef;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-order: -1;
          order: -1; }

.order-last {
  -webkit-order: 13;
          order: 13; }

.order-0 {
  -webkit-order: 0;
          order: 0; }

.order-1 {
  -webkit-order: 1;
          order: 1; }

.order-2 {
  -webkit-order: 2;
          order: 2; }

.order-3 {
  -webkit-order: 3;
          order: 3; }

.order-4 {
  -webkit-order: 4;
          order: 4; }

.order-5 {
  -webkit-order: 5;
          order: 5; }

.order-6 {
  -webkit-order: 6;
          order: 6; }

.order-7 {
  -webkit-order: 7;
          order: 7; }

.order-8 {
  -webkit-order: 8;
          order: 8; }

.order-9 {
  -webkit-order: 9;
          order: 9; }

.order-10 {
  -webkit-order: 10;
          order: 10; }

.order-11 {
  -webkit-order: 11;
          order: 11; }

.order-12 {
  -webkit-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #605d5c; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #605d5c;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6c8ca; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95999c; }

.table-hover .table-primary:hover {
  background-color: #b9bbbe; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b9bbbe; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f6f6f6; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #eeeeee; }

.table-hover .table-secondary:hover {
  background-color: #e9e9e9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e9e9e9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #ebf0b8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #dbe37a; }

.table-hover .table-success:hover {
  background-color: #e5eca3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #e5eca3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e4f6; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7acdef; }

.table-hover .table-info:hover {
  background-color: #a1dbf3; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a1dbf3; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1b8b8; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e47a7a; }

.table-hover .table-danger:hover {
  background-color: #eda3a3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eda3a3; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f6f6f6; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #eeeeee; }

.table-hover .table-light:hover {
  background-color: #e9e9e9; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e9e9e9; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #ffffff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #6d7a86;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #605d5c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #b9c900; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(185, 201, 0, 0.9);
  border-radius: 3px; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #b9c900;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23b9c900' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #b9c900;
    box-shadow: 0 0 0 0.2rem rgba(185, 201, 0, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #b9c900;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23b9c900' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #b9c900;
    box-shadow: 0 0 0 0.2rem rgba(185, 201, 0, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #b9c900; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #b9c900; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #b9c900; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #e8fc00;
  background: #e8fc00 linear-gradient(180deg, #e9fa24, #e8fc00) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(185, 201, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #b9c900; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #b9c900; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #b9c900;
  box-shadow: 0 0 0 0.2rem rgba(185, 201, 0, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #cc0000; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(204, 0, 0, 0.9);
  border-radius: 3px; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #cc0000;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cc0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc0000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #cc0000;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #cc0000;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cc0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc0000' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #cc0000;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #cc0000; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #cc0000; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #cc0000; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: red;
  background: red linear-gradient(180deg, #fd2424, red) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #cc0000; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #cc0000; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #cc0000;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #605d5c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #605d5c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0.1rem 0.1rem #dedede; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0.1rem 0.1rem #dedede, inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #ffffff;
    background: #23272b linear-gradient(180deg, #414548, #23272b) repeat-x;
    border-color: #1d2124; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background: #23272b linear-gradient(180deg, #414548, #23272b) repeat-x;
    border-color: #1d2124;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40;
    background-image: none; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-secondary {
  color: #212529;
  background: #dedede linear-gradient(180deg, #e1e1e1, #dedede) repeat-x;
  border-color: #dedede;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #212529;
    background: #cbcbcb linear-gradient(180deg, #d0d0d0, #cbcbcb) repeat-x;
    border-color: #c5c5c5; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background: #cbcbcb linear-gradient(180deg, #d0d0d0, #cbcbcb) repeat-x;
    border-color: #c5c5c5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(194, 194, 195, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #dedede;
    border-color: #dedede;
    background-image: none; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #c5c5c5;
    background-image: none;
    border-color: #bebebe; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(194, 194, 195, 0.5); }

.btn-success {
  color: #212529;
  background: #b9c900 linear-gradient(180deg, #c1cf24, #b9c900) repeat-x;
  border-color: #b9c900;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #ffffff;
    background: #96a300 linear-gradient(180deg, #a3ae24, #96a300) repeat-x;
    border-color: #8a9600; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background: #96a300 linear-gradient(180deg, #a3ae24, #96a300) repeat-x;
    border-color: #8a9600;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(162, 176, 6, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #b9c900;
    border-color: #b9c900;
    background-image: none; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #8a9600;
    background-image: none;
    border-color: #7e8900; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(162, 176, 6, 0.5); }

.btn-info {
  color: #ffffff;
  background: #009ee0 linear-gradient(180deg, #24aae2, #009ee0) repeat-x;
  border-color: #009ee0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #ffffff;
    background: #0083ba linear-gradient(180deg, #2493c2, #0083ba) repeat-x;
    border-color: #007aad; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background: #0083ba linear-gradient(180deg, #2493c2, #0083ba) repeat-x;
    border-color: #007aad;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 173, 229, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #009ee0;
    border-color: #009ee0;
    background-image: none; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #007aad;
    background-image: none;
    border-color: #0071a0; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 173, 229, 0.5); }

.btn-warning {
  color: #212529;
  background: #ffc107 linear-gradient(180deg, #fdc82a, #ffc107) repeat-x;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #212529;
    background: #e0a800 linear-gradient(180deg, #e2b224, #e0a800) repeat-x;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background: #e0a800 linear-gradient(180deg, #e2b224, #e0a800) repeat-x;
    border-color: #d39e00;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    background-image: none; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    background-image: none;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #ffffff;
  background: #cc0000 linear-gradient(180deg, #d12424, #cc0000) repeat-x;
  border-color: #cc0000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #ffffff;
    background: #a60000 linear-gradient(180deg, #b12424, #a60000) repeat-x;
    border-color: #990000; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background: #a60000 linear-gradient(180deg, #b12424, #a60000) repeat-x;
    border-color: #990000;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(212, 38, 38, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #cc0000;
    border-color: #cc0000;
    background-image: none; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #990000;
    background-image: none;
    border-color: #8c0000; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 38, 38, 0.5); }

.btn-light {
  color: #212529;
  background: #dedede linear-gradient(180deg, #e1e1e1, #dedede) repeat-x;
  border-color: #dedede;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background: #cbcbcb linear-gradient(180deg, #d0d0d0, #cbcbcb) repeat-x;
    border-color: #c5c5c5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background: #cbcbcb linear-gradient(180deg, #d0d0d0, #cbcbcb) repeat-x;
    border-color: #c5c5c5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(194, 194, 195, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #dedede;
    border-color: #dedede;
    background-image: none; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c5c5c5;
    background-image: none;
    border-color: #bebebe; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(194, 194, 195, 0.5); }

.btn-dark {
  color: #ffffff;
  background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #ffffff;
    background: #23272b linear-gradient(180deg, #414548, #23272b) repeat-x;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background: #23272b linear-gradient(180deg, #414548, #23272b) repeat-x;
    border-color: #1d2124;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40;
    background-image: none; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    background-image: none;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-secondary {
  color: #dedede;
  border-color: #dedede; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #dedede;
    border-color: #dedede; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #dedede;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #dedede;
    border-color: #dedede; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }

.btn-outline-success {
  color: #b9c900;
  border-color: #b9c900; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #b9c900;
    border-color: #b9c900; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(185, 201, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #b9c900;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #b9c900;
    border-color: #b9c900; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(185, 201, 0, 0.5); }

.btn-outline-info {
  color: #009ee0;
  border-color: #009ee0; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #009ee0;
    border-color: #009ee0; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 158, 224, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #009ee0;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #009ee0;
    border-color: #009ee0; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 158, 224, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #cc0000;
  border-color: #cc0000; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #cc0000;
    border-color: #cc0000; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #cc0000;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #cc0000;
    border-color: #cc0000; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }

.btn-outline-light {
  color: #dedede;
  border-color: #dedede; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #dedede;
    border-color: #dedede; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #dedede;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #dedede;
    border-color: #dedede; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #343a40;
  text-decoration: none; }
  .btn-link:hover {
    color: #121416;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #605d5c;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background: #e9ecef linear-gradient(180deg, #eaecef, #e9ecef) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 3px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #343a40;
    background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x;
    box-shadow:; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #6d7a86; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #88939e;
    border-color: #88939e;
    box-shadow:; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 3px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #343a40;
  background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x;
  box-shadow:; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: rgba(52, 58, 64, 0.5) linear-gradient(180deg, rgba(117, 121, 125, 0.575), rgba(52, 58, 64, 0.5)) repeat-x; }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background: rgba(52, 58, 64, 0.5) linear-gradient(180deg, rgba(117, 121, 125, 0.575), rgba(52, 58, 64, 0.5)) repeat-x; }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: rgba(52, 58, 64, 0.5) linear-gradient(180deg, rgba(117, 121, 125, 0.575), rgba(52, 58, 64, 0.5)) repeat-x; }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background: rgba(52, 58, 64, 0.5) linear-gradient(180deg, rgba(117, 121, 125, 0.575), rgba(52, 58, 64, 0.5)) repeat-x; }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 3px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #6d7a86;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #6d7a86;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background: #e9ecef linear-gradient(180deg, #eaecef, #e9ecef) repeat-x;
    border-left: inherit;
    border-radius: 0 3px 3px 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #efefef, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #efefef, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #efefef, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background: #88939e linear-gradient(180deg, #98a1aa, #88939e) repeat-x; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background: #88939e linear-gradient(180deg, #98a1aa, #88939e) repeat-x; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background: #88939e linear-gradient(180deg, #98a1aa, #88939e) repeat-x; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #efefef;
    border-color: #dee2e6 #dee2e6 #efefef; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 3px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #343a40; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 2px 2px 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 2px 2px; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 2px; }

.card-img,
.card-img-top,
.card-img-bottom {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      -webkit-flex: 1 0;
              flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap; }
    .card-group > .card {
      -webkit-flex: 1 0;
              flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 3px; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #343a40;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #121416;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #343a40; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #1d2124; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #dedede; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #c5c5c5; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }

.badge-success {
  color: #212529;
  background-color: #b9c900; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #8a9600; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(185, 201, 0, 0.5); }

.badge-info {
  color: #ffffff;
  background-color: #009ee0; }
  a.badge-info:hover, a.badge-info:focus {
    color: #ffffff;
    background-color: #007aad; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 158, 224, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #cc0000; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #990000; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }

.badge-light {
  color: #212529;
  background-color: #dedede; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #c5c5c5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #1b1e21;
  background: #d6d8d9 linear-gradient(180deg, #dadbdc, #d6d8d9) repeat-x;
  border-color: #c6c8ca; }
  .alert-primary hr {
    border-top-color: #b9bbbe; }
  .alert-primary .alert-link {
    color: #040505; }

.alert-secondary {
  color: #737373;
  background: #f8f8f8 linear-gradient(180deg, #f7f7f7, #f8f8f8) repeat-x;
  border-color: #f6f6f6; }
  .alert-secondary hr {
    border-top-color: #e9e9e9; }
  .alert-secondary .alert-link {
    color: #5a5a5a; }

.alert-success {
  color: #606900;
  background: #f1f4cc linear-gradient(180deg, #f1f3d1, #f1f4cc) repeat-x;
  border-color: #ebf0b8; }
  .alert-success hr {
    border-top-color: #e5eca3; }
  .alert-success .alert-link {
    color: #313600; }

.alert-info {
  color: #005274;
  background: #ccecf9 linear-gradient(180deg, #d1ecf8, #ccecf9) repeat-x;
  border-color: #b8e4f6; }
  .alert-info hr {
    border-top-color: #a1dbf3; }
  .alert-info .alert-link {
    color: #002e41; }

.alert-warning {
  color: #856404;
  background: #fff3cd linear-gradient(180deg, #fdf2d2, #fff3cd) repeat-x;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #6a0000;
  background: #f5cccc linear-gradient(180deg, #f4d1d1, #f5cccc) repeat-x;
  border-color: #f1b8b8; }
  .alert-danger hr {
    border-top-color: #eda3a3; }
  .alert-danger .alert-link {
    color: #370000; }

.alert-light {
  color: #737373;
  background: #f8f8f8 linear-gradient(180deg, #f7f7f7, #f8f8f8) repeat-x;
  border-color: #f6f6f6; }
  .alert-light hr {
    border-top-color: #e9e9e9; }
  .alert-light .alert-link {
    color: #5a5a5a; }

.alert-dark {
  color: #1b1e21;
  background: #d6d8d9 linear-gradient(180deg, #dadbdc, #d6d8d9) repeat-x;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 3px;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #343a40;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1; }

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 3px; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #605d5c;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -webkit-flex-direction: row;
          flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 3px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 3px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 3px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 3px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-secondary {
  color: #737373;
  background-color: #f6f6f6; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #737373;
    background-color: #e9e9e9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #737373;
    border-color: #737373; }

.list-group-item-success {
  color: #606900;
  background-color: #ebf0b8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #606900;
    background-color: #e5eca3; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #606900;
    border-color: #606900; }

.list-group-item-info {
  color: #005274;
  background-color: #b8e4f6; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #005274;
    background-color: #a1dbf3; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #005274;
    border-color: #005274; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #6a0000;
  background-color: #f1b8b8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6a0000;
    background-color: #eda3a3; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #6a0000;
    border-color: #6a0000; }

.list-group-item-light {
  color: #737373;
  background-color: #f6f6f6; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #737373;
    background-color: #e9e9e9; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #737373;
    border-color: #737373; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #000000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  -webkit-flex-basis: 350px;
          flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-flex;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 3px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #605d5c; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #343a40 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1d2124 !important; }

.bg-secondary {
  background-color: #dedede !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #c5c5c5 !important; }

.bg-success {
  background-color: #b9c900 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #8a9600 !important; }

.bg-info {
  background-color: #009ee0 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #007aad !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #cc0000 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #990000 !important; }

.bg-light {
  background-color: #dedede !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c5c5c5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-gradient-primary {
  background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x !important; }

.bg-gradient-secondary {
  background: #dedede linear-gradient(180deg, #e1e1e1, #dedede) repeat-x !important; }

.bg-gradient-success {
  background: #b9c900 linear-gradient(180deg, #c1cf24, #b9c900) repeat-x !important; }

.bg-gradient-info {
  background: #009ee0 linear-gradient(180deg, #24aae2, #009ee0) repeat-x !important; }

.bg-gradient-warning {
  background: #ffc107 linear-gradient(180deg, #fdc82a, #ffc107) repeat-x !important; }

.bg-gradient-danger {
  background: #cc0000 linear-gradient(180deg, #d12424, #cc0000) repeat-x !important; }

.bg-gradient-light {
  background: #dedede linear-gradient(180deg, #e1e1e1, #dedede) repeat-x !important; }

.bg-gradient-dark {
  background: #343a40 linear-gradient(180deg, #50555a, #343a40) repeat-x !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #343a40 !important; }

.border-secondary {
  border-color: #dedede !important; }

.border-success {
  border-color: #b9c900 !important; }

.border-info {
  border-color: #009ee0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #cc0000 !important; }

.border-light {
  border-color: #dedede !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 3px !important; }

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important; }

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important; }

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-flex !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #343a40 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #121416 !important; }

.text-secondary {
  color: #dedede !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #b8b8b8 !important; }

.text-success {
  color: #b9c900 !important; }

a.text-success:hover, a.text-success:focus {
  color: #737d00 !important; }

.text-info {
  color: #009ee0 !important; }

a.text-info:hover, a.text-info:focus {
  color: #006894 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #cc0000 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: maroon !important; }

.text-light {
  color: #dedede !important; }

a.text-light:hover, a.text-light:focus {
  color: #b8b8b8 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #605d5c !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.breadcrumb {
  background-color: #dedede;
  padding: 0;
  margin-bottom: 0;
  color: #ffffff; }

body {
  font-family: "Open Sans", sans-serif !important; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif !important;
  text-transform: capitalize; }

h2 {
  font-weight: 200; }

span, p, div {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.material-icons {
  line-height: 1.5; }

.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

.material-icons.md-72 {
  font-size: 72px; }

/*
* Custom Dropzone Style 
*/
.dropzone {
  min-height: 140px;
  background: #ffffff;
  padding: 20px 20px; }
  .dropzone.active {
    border-color: #b9c900;
    color: #b9c900; }
  .dropzone .material-icons {
    font-size: 8rem;
    padding-top: 5rem;
    line-height: 0; }
  .dropzone .dropzone-image-container {
    width: 100px;
    height: 100px;
    margin: 15px;
    float: left;
    position: relative; }
  .dropzone .dropzone-thmbnail-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .dropzone .dropzone-thmbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .dropzone .spinner-center {
    position: absolute;
    left: 45px;
    top: 45px;
    color: #fff; }

.dropzone.dz-clickable {
  cursor: pointer; }

.dropzone.dz-drag-hover {
  border: solid;
  color: -10; }

.dropzone .dz-message {
  font-size: 16px;
  text-align: center;
  margin: 2em 0; }

.dropzone .dz-fallback {
  display: none; }

.dropzone.dz-clickable * {
  cursor: default; }

.form-group label {
  font-weight: 600;
  font-size: 0.9rem; }

.form-control {
  box-shadow: none;
  background: #fdfdfd;
  transition: background-color 0.2s ease, padding-left 0.2s linear;
  padding-left: 0.75rem; }

.input-group > .form-control {
  height: 50px; }

textarea {
  resize: vertical;
  overflow: auto;
  min-height: 150px; }

label {
  font-weight: 600;
  font-size: 0.9rem;
  margin: 0; }

.form-control:disabled {
  background: none;
  border: none;
  padding-left: 0;
  transition: background-color 0.2s ease, padding-left 0.2s linear; }

.card {
  border: rgba(0, 0, 0, 0);
  background-color: #ffffff; }
  .card .card-header {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .card .card-header h1,
    .card .card-header h2,
    .card .card-header h3,
    .card .card-header h4,
    .card .card-header h5,
    .card .card-header h6 {
      margin-top: 5px;
      margin-bottom: 5px; }
  .card .card-footer {
    background-color: #dedede;
    border-top: none;
    min-height: 20px; }

.card-header {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  min-height: 62px; }
  .card-header .button-container {
    margin-left: auto; }

/**
*
**/
.btn-outline-secondary:hover {
  color: #ffffff; }

.btn-item, .btn-input {
  background: #ffffff;
  min-height: 46px;
  min-width: 46px; }
  .btn-item:hover, .btn-input:hover {
    color: #ffc107; }

.btn-input {
  border: 1px solid #ced4da; }

.navbar-light {
  background: #ffffff;
  color: #dedede; }

.icon {
  background-color: #dedede;
  height: 200px;
  padding: 0;
  box-shadow: none;
  margin: 0;
  border: none;
  text-align: center; }

.file .icon,
.file .image {
  height: 160px;
  overflow: hidden; }

.image {
  width: 100%;
  object-fit: cover; }

.file .icon {
  padding: 15px 10px;
  text-align: center; }

.file .icon i {
  font-size: 60px;
  color: #343a40; }

.file {
  border: 1px solid #e7eaec;
  padding: 0;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
  overflow: hidden;
  height: 280px; }
  @media screen and (max-width: 500px) {
    .file {
      margin-bottom: 12px;
      height: 200px; } }
  .file.active {
    border: 1px solid #b9c900; }
  .file .file-name {
    padding: 16px;
    overflow-wrap: break-word;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .file .file-name small {
      position: absolute;
      bottom: 10px; }
  .file .thumbnail-image {
    border-bottom: 2px solid #efefef; }

.file-box {
  float: left;
  margin-right: 12px;
  width: 215.3px; }
  @media screen and (max-width: 500px) {
    .file-box {
      width: 100%; } }

.file-options {
  display: none; }

.file-thumbnail:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 255, 255, 0.5);
  transition: background 0.3s linear; }

.thumbnail-image {
  border-bottom: 2px solid #efefef; }

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overscroll-behavior-y: contain; }

.material-icons {
  line-height: 1.5 !important; }

#root {
  height: 100%; }

.flex-row {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex; }

[class^="css-"] {
  z-index: 99999 !important; }

.gutter-right.gutter-right.gutter-right {
  padding-right: 12px; }
  @media screen and (max-width: 500px) {
    .gutter-right.gutter-right.gutter-right {
      padding-right: 0; } }

.col-no-gutters.col-no-gutters.col-no-gutters {
  padding-right: 0px;
  padding-left: 0px; }

@media screen and (max-width: 500px) {
  .hide-mobile {
    display: none; } }

.hide-desktop {
  display: none; }
  @media screen and (max-width: 500px) {
    .hide-desktop {
      display: block; } }

.button-enabled {
  -webkit-align-items: center;
          align-items: center;
  right: 12px;
  bottom: 24px;
  background: #fff;
  border: 4px solid #dedede;
  border-radius: 100%;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  margin: 0 12px;
  outline: none;
  opacity: 1;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  height: 64px;
  width: 64px; }
  .button-enabled.icon-primary i.material-icons {
    color: #343a40; }
  .button-enabled i.material-icons {
    color: #343a40;
    font-size: 28px;
    line-height: 1;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.button-disabled {
  -webkit-align-items: center;
          align-items: center;
  right: 12px;
  bottom: 24px;
  background: #fff;
  border: 4px solid #dedede;
  border-radius: 100%;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  margin: 0 12px;
  outline: none;
  opacity: 1;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  height: 64px;
  width: 64px; }
  .button-disabled.icon-primary i.material-icons {
    color: #343a40; }
  .button-disabled i.material-icons {
    color: #343a40;
    font-size: 28px;
    line-height: 1;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.padding-top {
  padding-top: 12px; }

@media screen and (max-width: 500px) {
  .padding-top-mobile {
    padding-top: 12px; } }

.padding-top-desktop {
  padding-top: 12px; }
  @media screen and (max-width: 500px) {
    .padding-top-desktop {
      padding-top: 0; } }

