@import "../../scss/custom-variables";

.timeline {
	margin-top: 50px;
}

.timeline-block {
	position: relative;
	margin: 45px 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		&:before {
			height: 0;
		}
	}

	&:before {
		content: "";
		position: absolute;
		left: 24px;
		height: calc(100% + 45px);
		width: 4px;
		background: $light;
		box-sizing: border-box;
	}

	&:after {
		content: "";
		position: absolute;
		top: 12px;
		left: 60px;
		z-index: 1;
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 10px solid $white;
	}
}

.timeline-icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	font-size: 16px;

	border: 3px solid $light; // TODO Change this
	text-align: center;
	background-color: $white !important;
	color: $primary;
	.material-icons {
		line-height: 1.9 !important;
	}
}

.timeline-content {
	position: relative;
	margin-left: 70px;
	background: $white;
	border-radius: 0.25em;
	padding: 1em;

	&:before {
		content: "";
		position: absolute;
		top: 16px;
		right: 100%;
		height: 0;
		width: 0;
		border: 10px solid transparent;
		border-right: 7px soli $white;
	}
}

.app-stretched-image-mobile {
	display: block;
	position: relative;
	top: 50%;
	height: 80px;
	width: 80px;
	min-width: 80px;
	min-height: 80px;
	.icon {
		height: 100%;
		object-fit: contain;
		object-position: left;
		width: 100%;
	}
}

.item-display-container-mobile {
	flex-direction: column;
}

.product-card-container-mobile {
	display: flex;
	background: $white;
	margin-bottom: 10px;
	position: relative;
	height: 100%;

	.product-card-header-mobile {
		display: flex;
		padding-left: 12px;
		padding-top: 20px;
		overflow: hidden;

		.product-card-header-text-mobile {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			user-select: none;

			p {
				margin: 0;
				padding: 0;
				font-size: 15px;
				font-weight: bold;
				margin-left: 12px;
				margin-right: 28px;
				overflow: hidden;
			}

			span {
				color: $danger;
				font-size: 13px;
				margin-left: 12px;
				margin-right: 28px;
				overflow: hidden;
			}
		}
	}

	.product-card-header-product-mobile {
		display: flex;
		padding-left: 12px;
		padding-top: 50px;
		overflow: hidden;

		.product-card-header-text-mobile {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			user-select: none;

			p {
				margin: 0;
				padding: 0;
				font-size: 16px;
				font-weight: bold;
				margin-left: 12px;
				margin-right: 28px;
				overflow: hidden;
			}

			span {
				color: $danger;
				font-size: 13px;
				margin-left: 12px;
				margin-right: 28px;
				overflow: hidden;
			}
		}
	}

	.product-card-description-mobile {
		font-size: 12px;
		margin: 6px 12px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
