// Font Styles

@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:500,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,300i,400,400i,600,600i,700,700i,800,800i");

body {
	font-family: $body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Open Sans", sans-serif !important;
	text-transform: capitalize;
}

h2 {
	font-weight: 200;
}
span, p, div {
	user-select: none;
}

.material-icons {
	line-height: 1.5;
}

.material-icons.md-18 {
	font-size: 18px;
}
.material-icons.md-24 {
	font-size: 24px;
}
.material-icons.md-36 {
	font-size: 36px;
}
.material-icons.md-48 {
	font-size: 48px;
}

.material-icons.md-72 {
	font-size: 72px;
}
