@import "../../scss/custom-variables";
@import "../../scss/animation";

.layout-navmenu {
	background: $primary;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;

	transition: width 500ms $animation-timing;
	overflow: hidden;

	width: $nav-collapsed-width;

	&.is-open {
		width: $nav-open-width;
		overflow: auto;
	}

	.layout-navmenu-small-title {
		color: $danger;
		font-size: 24px;
		font-weight: bold;
		height: 75px;
		line-height: 75px;
		text-transform: uppercase;
		text-align: center;
	}

	.layout-navmenu-title {
		align-items: center;
		display: flex;
		height: 75px;
		justify-content: center;

		strong {
			color: $danger;
			font-size: 18px;
			line-height: 15px;
			font-weight: bold;
			text-transform: uppercase;
		}

		span {
			color: $white;
			font-size: 13px;
			line-height: 13px;
			text-transform: uppercase;
		}
	}
}

i.material-icons.app_icon.user-icon {
	padding: 0px;
	width: 70px;
	height: 70px;
	background: white;
	border-radius: 50%;
	font-size: 45px;
	margin-bottom: 10px;
}

.user-name {
	color: white;
	margin-bottom: 30px;
}

.team-name {
	font-size: 12px;
}
