/**
*
**/

.btn-outline-secondary {
	&:hover {
		color: $white;
	}
}

.btn-item {
	background: $white;
	min-height: 46px;
	min-width: 46px;

	&:hover {
		color: $warning;
	}
}

.btn-input {
	@extend .btn-item;
	border: 1px solid #ced4da;
}
