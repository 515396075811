.user-qrcode-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 120px);
    justify-content: space-between;

    .user-qrcode-svg{
        padding: 25px;
        width: 100%;
        height: 100%;
    }

    .user-qrcode-color-picker{
        display: flex;
        flex-direction: column;
        align-content: space-between;
        align-items: center;
        justify-content: space-between;
    }
}

