@import "../../scss/custom-variables";
@import "../../scss/breakpoints";

.incoming-call-container {
	align-items: center;
	border: 1px solid $gray;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	height: 64px;
	position: relative;
	margin-bottom: 12px;

	.incoming-call-user-icon {
		border-right: 1px solid $gray;
		font-size: 24px;
		padding-left: 5px;
		padding-right: 5px;
		height: 100%;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
	}

	.incoming-call-phone-icon {
		border-left: 1px solid $gray;
		color: $gray;
		font-size: 24px;
		margin-left: auto;
		padding-left: 5px;
		padding-right: 5px;
		height: 100%;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
	}

	@media screen and (max-width: $break-mobile) {
		height: 46px;

		.incoming-call-phone-icon {
			line-height: 46px;
			padding-left: 6px;
			padding-right: 6px;
		}

		.incoming-call-user-icon {
			line-height: 46px;
			padding-left: 6px;
			padding-right: 6px;
		}
	}

	&:hover {
		background: rgba(0, 0, 0, 0.05);

		.incoming-call-phone-icon {
			background: $success;
			color: $white;
		}
	}

	.incoming-call-text-container {
		display: flex;
		flex-direction: column;
		padding: 0 12px;
		width: calc(100% - 74px);

		p {
			width: auto;
    		white-space: nowrap;
    		overflow: hidden;
    		text-overflow: ellipsis;
			font-size: 14px;
			margin: 0;
		}

		span {
			font-size: 10px;
			text-transform: uppercase;
		}
	}
}
