@import "../../scss/custom-variables";
@import "../../scss/breakpoints";

.product-file-list-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-y: auto;
	padding-bottom: 20px;
	margin-bottom: 15px;
	background: $light;
	max-width:fit-content;
	width:300px;
	// resize:horizontal;
	// min-width: 300px;
	// align-items:flex-start;

	@media screen and (max-width: $break-mobile-landscape) {
		display: flex;
		flex: 1;
		flex-direction: column;
		overflow-y: visible;
		padding-bottom: 12px;
		background: $light;
	}
}

.product-file-list-container-empty{
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-y: auto;
	padding-bottom: 20px;
	margin-bottom: 15px;
	width:300px;
}

.product-file-list-item {
	align-items: center;
	border-radius: 10px;
	background: $white;
	display: flex;
	flex-shrink: 0;
	height: 46px;
	margin-top: 8px;
	position: relative;
	width:300px;

	.product-file-icon-container {
		align-items: center;
		border-right: 1px solid $light;
		display: flex;
		justify-content: center;
		padding-left: 12px;
		padding-right: 12px;
		height: 100%;
	}

	span {
		margin-left: 12px;
		font-size: 14px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-right: 12px;
	}

	button {
		align-items: center;
		background: $white;
		border: 0;
		border-left: 1px solid $light;
		cursor: pointer;
		display: flex;
		height: 100%;
		justify-content: center;
		margin-left: auto;
		outline: 0;
		padding: 0 12px;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;

		i.material-icons {
			color: $gray;
			font-size: 18px;
		}

		&:active {
			color: $primary;
			outline: 0;
		}

		&:hover,
		&:active,
		&:focus {
			outline: 0;
		}

		&:hover {
			i.material-icons {
				color: $gray-dark;
			}
		}
	}
}
