// body background and text
$body-bg: #efefef;
$body-color: #605d5c;
$body-font: "Open Sans", sans-serif !important;

/** 
*   Holo Maint - Theme Colors
**/

// Primary Bootstrap Colors
$primary: #343a40;
$secondary: #dedede; //#6c757d;
$success: #b9c900;
$info: #009ee0;
$warning: #ffc107;
$danger: #cc0000;

// General Bootstrap Colors
$white: #ffffff;
$black: #000000;
$gray: #959595;
$gray-dark: #605d5c;
$light: #dedede;

// Color None
$Color-none: rgba(0, 0, 0, 0);

// Define common padding and border radius sizes and more.
$border-radius: 3px;

// Enabling shadows and gradients
$shadow-1dp: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
$enable-shadows: true;
$enable-gradients: true;

// Form Styles
$label-font-weight: 600 !default;

$input-boder-size: 5rem;

//$input-focus-border-color: $light !default;
//$input-focus-color: $light !default;
$input-focus-box-shadow: none;

// Button Styles

$btn-focus-box-shadow: 0 0 0.1rem 0.1rem $light;

$nav-open-width: 220px;
$nav-collapsed-width: 60px;
