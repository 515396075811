@import "../../scss/animation";
@import "../../scss/custom-variables";

@keyframes underlayFadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes slideInFromLeft {
	from {
		transform: translate3d(-100%, 0, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.mobile-nav-menu-outer {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;

	.mobile-nav-menu-underlay {
		animation-name: underlayFadeIn;
		animation-duration: 500ms;
		animation-timing-function: $animation-timing;
		background: rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.mobile-nav-menu-side {
		animation-name: slideInFromLeft;
		animation-duration: 500ms;
		animation-timing-function: $animation-timing;
		position: absolute;
		background: $primary;
		left: 0;
		top: 0;
		bottom: 0;
		max-width: 300px;
		width: 90%;

		.mobile-nav-title {
			align-items: center;
			display: flex;
			height: 75px;
			justify-content: center;

			strong {
				color: $danger;
				font-size: 18px;
				line-height: 15px;
				font-weight: bold;
				text-transform: uppercase;
			}

			span {
				color: $white;
				font-size: 13px;
				line-height: 13px;
				text-transform: uppercase;
			}
		}
	}
}
