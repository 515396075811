/** 
*   Holo Maint - Theme Colors
**/
.custom-header {
  padding: 10px 0px;
  background-color: #7a7a76;
  color: black;
  margin-top: -100px;
  border-radius: 5px; }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

.label-style {
  padding: 5px; }

.button {
  background-color: #959595;
  border: none;
  color: white;
  padding: 8px 10px;
  text-align: center;
  width: 170px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s; }

.custom-alert {
  left: 0px;
  padding: 20px;
  background: rgba(85, 85, 85, 0.5);
  color: black;
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  height: 100%; }

.message-style {
  text-align: center;
  background-color: #ffffff;
  padding: 100px 0;
  width: 500px;
  margin-top: 15%;
  height: 0px;
  margin-left: 35%;
  border-radius: 5px;
  -webkit-animation-name: animatetop;
  animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s; }
  @media only screen and (max-device-width: 700px) and (orientation: portrait) {
    .message-style {
      text-align: center;
      background-color: #ffffff;
      padding: 100px 0;
      margin-top: 15%;
      margin-left: 0;
      height: 0px;
      width: auto;
      border-radius: 5px;
      animation-name: animatetop;
      animation-duration: 0.4s; } }
