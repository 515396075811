@import "../../scss/custom-variables";

.app_icon_button {
	background: $white;
	border: none;
	cursor: pointer;
	height: 34px;
	outline: none;
	width: 34px;

	&:hover {
		background: $light;
	}

	.material-icons {
		line-height: 1.4;
	}

	.app_icon {
		color: rgba(0, 0, 0, 0.56);
	}

	&:hover {
		> .app_icon {
			color: rgba(0, 0, 0, 0.87);
		}
	}

	&:active {
		outline: none;
	}

	&:focus {
		outline: none;
	}
}
