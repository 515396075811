@import "./breakpoints";

.icon {
	background-color: #dedede;
	height: 200px;
	padding: 0;
	box-shadow: none;
	margin: 0;
	border: none;
	text-align: center;
}

.file .icon,
.file .image {
	height: 160px;
	overflow: hidden;
}

.image {
	width: 100%;
	object-fit: cover;
}

.file .icon {
	padding: 15px 10px;
	text-align: center;
}

.file .icon i {
	font-size: 60px;
	color: $primary;
}

.file {
	border: 1px solid #e7eaec;
	padding: 0;
	background-color: $white;
	position: relative;
	margin-bottom: 20px;
	box-sizing: border-box;
	overflow: hidden;
	height: 280px;

	@media screen and (max-width: $break-mobile) {
		margin-bottom: 12px;
		height: 200px;
	}

	&.active {
		border: 1px solid $success;
	}
	.file-name {
		padding: 16px;
		overflow-wrap: break-word;
		height: 80px;
		overflow: hidden;
		text-overflow: ellipsis;

		small {
			position: absolute;
			bottom: 10px;
		}
	}
	.thumbnail-image {
		border-bottom: 2px solid #efefef;
	}
}
.file-box {
	float: left;
	margin-right: 12px;
	width: 215.3px;

	@media screen and (max-width: $break-mobile) {
		width: 100%;
	}
}

.file-options {
	display: none;
}

.file-thumbnail:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 255, 255, 0.5);
	-moz-transition: background 0.3s linear;
	-webkit-transition: background 0.3s linear;
	-o-transition: background 0.3s linear;
	transition: background 0.3s linear;
}

.thumbnail-image {
	border-bottom: 2px solid #efefef;
}
