/** 
*   Holo Maint - Theme Colors
**/
.app-stretched-image {
  display: block;
  position: relative;
  min-width: 80px;
  min-height: 80px; }
  .app-stretched-image img {
    height: 100%;
    object-fit: contain;
    object-position: left;
    width: 100%; }

.div-documentation-detail {
  position: fixed;
  height: 80%;
  overflow-y: scroll; }

.card-header {
  height: 60px; }

.selected-files {
  padding-bottom: 20px;
  font-size: 130%; }

.item-display-container-mobile {
  flex-direction: column; }

.product-card-container-mobile {
  display: flex;
  background: #ffffff;
  margin-bottom: 10px;
  margin-left: 24px;
  margin-right: 24px;
  position: relative;
  height: 100%; }
  .product-card-container-mobile .product-card-header-mobile {
    display: flex;
    padding-left: 12px;
    padding-top: 20px; }
    .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile {
      display: flex;
      flex-direction: column;
      user-select: none; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px; }
  .product-card-container-mobile .product-card-description-mobile {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
