@import "../../scss/custom-variables";
@import "../../scss/breakpoints";

.troubleshooting-node-mobile {
	align-items: center;
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	padding: 0 12px;
	margin-top: 12px;
	min-height: 54px;
	word-break: break-word;

	.troubleshooting-node-text-mobile {
		font-size: 12px;
		display: block;
		word-wrap: break-word;
		padding-top: 12px;
		padding-bottom: 12px;
		margin-top: 4px;
		margin-bottom: 4px;
		padding-left: 12px;
	}
}


.dot-filled {
	margin-right: 10px;
	margin-left: 5px;
	padding-top: 15px;
	height: 16px;
	width: 16px;
	background-color: #605d5c;
	border-radius: 50%;
	display: inline-block;
	box-shadow: 0 0 0 2pt #b0aeae;
	align-items: flex-end;
  }
.dot {
	margin-right: 10px;
	margin-left: 5px;
	padding-top: 15px;
	height: 16px;
	width: 16px;
	background-color: #ffffff;
	border-radius: 50%;
	display: inline-block;
	box-shadow: 0 0 0 2pt #b0aeae;
	align-items: flex-end;
  }

.troubleshooting-node {
	align-items: center;
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	cursor: pointer;
	display: flex;

	height: 64px;

	margin-top: 12px;
	margin-left: 0px;
	position: relative;

	.node-icon {
		font-size: 36px;
		margin-left: 18px;
		margin-right: 18px;
	}

	@media screen and (max-width: $break-mobile) {
		height: 48px;

		.node-icon {
			font-size: 24px;
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	&.active {
		background: #eaeae9;
		box-shadow: 0 0 2px 0 inset black;
	}

	.node-text {
		font-size: 13px;
		overflow: hidden;
		margin-right: 12px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.troubleshooting-node-actions {
		margin-left: auto;
		position: relative;
		height: 100%;

		@media screen and (max-width: $break-mobile) {
			display: none;
		}

		.troubleshooting-node-actions-button {
			background: transparent;
			border-left: 1px solid #e0e0e0;
			height: 100%;
			width: 48px;
		}
	}
}

.troubleshooting-nav-button {
	align-items: center;
	justify-content: space-evenly;

	button {
		background: #00000000;
		border: 4px solid #00000000;


		&.icon-primary {
			i.material-icons {
				color: $primary;
			}
		}

		i.material-icons {
			color: $primary;
			font-size: 28px;
		}
	}
}

.align-top-element {
	display: flex;
	align-items: center;
}