@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

/*.products-list-button{
	width: 100%;
	flex: auto;
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	// @media screen and (max-width: $break-mobile) {
	// 	padding-top: 12px;

	// 	.user-create-actionbar {
	// 		button {
	// 			width: 100%;
	// 		}
	// 	}
	// }
}*/

.teams-list-container {
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: $break-mobile) {
		padding-top: 12px;

		.teams-list-actionbar {
			button {
				width: 100%;
			}
		}
	}

	.teams-list-actionbar {
		display: flex;
		justify-content: space-between;
	}

	.teams-list-items {
		display: flex;
		flex-wrap: wrap;

		.item-display-container {
			height: 270px;
			width: 25%;

			@media screen and (max-width: $break-large) {
				width: 33.3%;
			}

			@media screen and (max-width: $break-medium) {
				width: 50%;
			}

			@media screen and (max-width: $break-small) {
				width: 80%;
			}
		}
	}
}

.team-detail-container {
	.team-unselected {
		margin-top: 10px;
		height: 600px;
		color: #eee;
		text-align: center;
		justify-content: center;
		border-bottom: 24px solid $light;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		i.material-icons.app_icon.team-unselected-icon {
			font-size: 60px;
		}
	}
}

.app-detail-container {
	.app-unselected {
		margin-top: 10px;
		height: 300px;
		color: #eee;
		text-align: center;
		justify-content: center;
		border-bottom: 24px solid $light;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		i.material-icons.app_icon.app-unselected-icon {
			font-size: 60px;
		}
	}
}
