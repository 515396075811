@keyframes spin-rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.spinner {
	color: #6c757d;
	width: 1rem;
	height: 1rem;
	display: inline-block;
	vertical-align: text-bottom;
	border: 0.2em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spin-rotate 0.75s linear infinite;
}
