/** 
*   Holo Maint - Theme Colors
**/
.incoming-call-container {
  align-items: center;
  border: 1px solid #959595;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 64px;
  position: relative;
  margin-bottom: 12px; }
  .incoming-call-container .incoming-call-user-icon {
    border-right: 1px solid #959595;
    font-size: 24px;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .incoming-call-container .incoming-call-phone-icon {
    border-left: 1px solid #959595;
    color: #959595;
    font-size: 24px;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  @media screen and (max-width: 500px) {
    .incoming-call-container {
      height: 46px; }
      .incoming-call-container .incoming-call-phone-icon {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; }
      .incoming-call-container .incoming-call-user-icon {
        line-height: 46px;
        padding-left: 6px;
        padding-right: 6px; } }
  .incoming-call-container:hover {
    background: rgba(0, 0, 0, 0.05); }
    .incoming-call-container:hover .incoming-call-phone-icon {
      background: #b9c900;
      color: #ffffff; }
  .incoming-call-container .incoming-call-text-container {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    width: calc(100% - 74px); }
    .incoming-call-container .incoming-call-text-container p {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      margin: 0; }
    .incoming-call-container .incoming-call-text-container span {
      font-size: 10px;
      text-transform: uppercase; }
