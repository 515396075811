@import "../../scss/custom-variables";
@import "../../scss/animation";

@keyframes enterAnimation {
	from {
		opacity: 0;
		transform: translateY(-20px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.app-toast-container {
	position: fixed;
	top: 0;
	right: 0;
	padding: 18px;
	z-index: 2391938;
}

.app-toast-box {
	animation-name: enterAnimation;
	animation-duration: 500ms;
	animation-timing-function: $animation-timing;
	animation-fill-mode: forwards;
	background: $primary;
	// box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.2), 0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12);
	margin-bottom: 18px;
	min-width: 230px;
	max-width: 230px;
	width: 230px;

	&.info {
		border-left: 4px solid $info;
	}

	&.success {
		border-left: 4px solid $success;
	}

	&.warning {
		border-left: 4px solid $warning;
	}

	&.error {
		border-left: 4px solid $danger;
	}

	.app-toast-top-layer {
		align-items: center;
		background: rgba(255, 255, 255, 0.05);
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		color: rgba(255, 255, 255, 0.5);
		display: flex;
		padding: 4px 8px;
		font-size: 11px;
		font-weight: bold;
		text-transform: uppercase;

		.app-toast-top-layer-dismiss {
			background: none;
			border: none;
			cursor: pointer;
			outline: none;
			margin-left: auto;
			padding: 0;

			&:hover {
				i.app_icon {
					color: rgba(255, 255, 255, 0.56);
				}
			}

			i.app_icon {
				color: rgba(255, 255, 255, 0.3);
				line-height: 1.5;
				font-size: 18px;
			}
		}
	}

	.app-toast-body {
		color: #fff;
		font-size: 13px;
		padding: 8px 8px;
	}
}
