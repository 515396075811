@import "../../scss/custom-variables";
@import "../../scss/animation";

.body-content {
	margin-left: $nav-collapsed-width;
	height: 100%;
	transition: margin 500ms $animation-timing;

	&.nav-open {
		margin-left: $nav-open-width;
	}

	&.mobile {
		margin-left: 0;
	}
}
