/** 
*   Holo Maint - Theme Colors
**/
.layout-navmenu {
  background: #343a40;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  width: 60px; }
  .layout-navmenu.is-open {
    width: 220px;
    overflow: auto; }
  .layout-navmenu .layout-navmenu-small-title {
    color: #cc0000;
    font-size: 24px;
    font-weight: bold;
    height: 75px;
    line-height: 75px;
    text-transform: uppercase;
    text-align: center; }
  .layout-navmenu .layout-navmenu-title {
    align-items: center;
    display: flex;
    height: 75px;
    justify-content: center; }
    .layout-navmenu .layout-navmenu-title strong {
      color: #cc0000;
      font-size: 18px;
      line-height: 15px;
      font-weight: bold;
      text-transform: uppercase; }
    .layout-navmenu .layout-navmenu-title span {
      color: #ffffff;
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase; }

i.material-icons.app_icon.user-icon {
  padding: 0px;
  width: 70px;
  height: 70px;
  background: white;
  border-radius: 50%;
  font-size: 45px;
  margin-bottom: 10px; }

.user-name {
  color: white;
  margin-bottom: 30px; }

.team-name {
  font-size: 12px; }
