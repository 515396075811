/** 
*   Holo Maint - Theme Colors
**/
.product-file-list-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 20px;
  margin-bottom: 15px;
  background: #dedede;
  max-width: fit-content;
  width: 300px; }
  @media screen and (max-width: 900px) {
    .product-file-list-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: visible;
      padding-bottom: 12px;
      background: #dedede; } }

.product-file-list-container-empty {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 20px;
  margin-bottom: 15px;
  width: 300px; }

.product-file-list-item {
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-shrink: 0;
  height: 46px;
  margin-top: 8px;
  position: relative;
  width: 300px; }
  .product-file-list-item .product-file-icon-container {
    align-items: center;
    border-right: 1px solid #dedede;
    display: flex;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    height: 100%; }
  .product-file-list-item span {
    margin-left: 12px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 12px; }
  .product-file-list-item button {
    align-items: center;
    background: #ffffff;
    border: 0;
    border-left: 1px solid #dedede;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    outline: 0;
    padding: 0 12px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px; }
    .product-file-list-item button i.material-icons {
      color: #959595;
      font-size: 18px; }
    .product-file-list-item button:active {
      color: #343a40;
      outline: 0; }
    .product-file-list-item button:hover, .product-file-list-item button:active, .product-file-list-item button:focus {
      outline: 0; }
    .product-file-list-item button:hover i.material-icons {
      color: #605d5c; }
