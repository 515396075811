/** 
*   Holo Maint - Theme Colors
**/
.layout-navbar {
  align-items: center;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  background: #605d5c;
  display: flex;
  height: 60px;
  padding: 0 1rem; }
  .layout-navbar .layout-navbar-mobile-title {
    font-size: 18px;
    margin-left: 6px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #dedede; }
  .layout-navbar .right-container {
    align-items: center;
    display: flex;
    margin-left: auto; }

.layout-navbar-expert {
  align-items: center;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  background: #ffffff;
  display: flex;
  height: 60px;
  padding: 0 1rem; }
  .layout-navbar-expert .layout-navbar-mobile-title {
    font-size: 18px;
    margin-left: 6px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #dedede; }
  .layout-navbar-expert .right-container {
    align-items: center;
    display: flex;
    margin-left: auto; }

.btn.select-role {
  background: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  color: #ffffff; }
  .btn.select-role:active, .btn.select-role:focus, .btn.select-role:hover {
    background: none;
    box-shadow: none;
    border: none; }
