@import "../../scss/breakpoints";

.modal .modal-dialog.products-link-document-modal {
	max-width: 60%;

	@media screen and (max-width: $break-mobile) {
		max-width: 100%;

		.table {
			font-size: 12px;
		}
	}
}

.modal .modal-dialog .modal-content .modal-body.products-link-document-modal-body {
	padding: 0;
}
