/** 
*   Holo Maint - Theme Colors
**/
.nav-home {
  color: #ffffff;
  background: transparent;
  padding: 12px 0; }

.nav-bottom {
  color: #ffffff;
  background: transparent;
  padding: 0 0; }

.nav-bottom-landscape {
  padding: "18px 0px";
  margin-right: 6px;
  color: #ffffff;
  background: transparent;
  padding: 0 0; }

.nav-bottom-mobile {
  background-color: transparent; }
