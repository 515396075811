.product-info-container {
	background: #fff;
	// border-radius: 4px;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	display: flex;
	flex-direction: column;

	i {
		line-height: 1;
	}

	&.fullscreen {
		.product-info-header {
			.product-info-header-title {
				margin-left: 0;
			}

			.close-click {
				cursor: pointer;
				margin-left: auto;
			}
		}
	}

	.product-info-header {
		align-items: center;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		display: flex;
		padding: 12px 18px;

		.product-info-header-title {
			margin-left: auto;
		}
	}

	.product-info-image-container {
		height: 150px;
		position: relative;

		img {
			height: 100%;
			object-fit: contain;
			object-position: center;
			width: 100%;
		}
	}

	.product-info-body {
		overflow-y: auto;
		.product-info-body-header {
			align-items: center;
			display: flex;
			margin: 18px;
			flex-wrap: wrap;
			justify-content: space-between;

			.product-info-body-header-name {
				display: flex;
				flex-direction: column;

				p {
					font-size: 16px;
					margin: 0;
				}

				span {
					font-size: 12px;
				}
			}

			.product-info-body-header-company {
				display: flex;
				flex-direction: column;
				// margin-left: auto;

				p {
					font-weight: bold;
					font-size: 12px;
					margin: 0;
				}

				span {
					font-size: 12px;
				}
			}
		}

		.product-info-body-content {
			display: flex;
			flex-direction: column;
			margin-top: 18px;
			margin-left: auto;

			p {
				font-weight: bold;
				font-size: 12px;
				margin: 0 18px 6px;
			}

			span {
				font-size: 12px;
				padding: 0 18px 18px;
				max-height: 150px;
			}
		}
	}
}
