@import "../../scss/breakpoints";

.documentation-tab-button {
	margin-right: 12px;

	@media screen and (max-width: $break-mobile) {
		margin-right: 0;
		margin-bottom: 12px;
		width: 100%;
	}
}
