/** 
*   Holo Maint - Theme Colors
**/
.item-display-container {
  padding-top: 12px;
  padding-right: 10px;
  padding-left: 2px; }

.item-display-container-mobile {
  flex-direction: column; }

.product-card-container {
  background: #ffffff;
  padding-bottom: 5px;
  position: relative;
  height: 100%;
  padding-left: 6px;
  border-radius: 10px; }
  .product-card-container .product-card-header {
    display: flex;
    padding-top: 12px; }
    .product-card-container .product-card-header .product-card-header-button-container {
      margin-left: auto;
      margin-right: 12px; }
      .product-card-container .product-card-header .product-card-header-button-container .btn-group button.btn {
        background: none;
        border: 0;
        box-shadow: none;
        outline: 0;
        padding: 0; }
        .product-card-container .product-card-header .product-card-header-button-container .btn-group button.btn i.material-icons {
          line-height: 1; }
    .product-card-container .product-card-header .product-card-header-text {
      display: flex;
      flex-direction: column;
      overflow: hidden; }
      .product-card-container .product-card-header .product-card-header-text p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .product-card-container .product-card-header .product-card-header-text span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .product-card-container .product-card-header .app_icon_button {
      margin-left: auto;
      margin-right: 6px; }
      .product-card-container .product-card-header .app_icon_button .material-icons.app_icon {
        line-height: 1; }
  .product-card-container .product-card-description {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.product-card-container-mobile {
  display: flex;
  background: #ffffff;
  margin-bottom: 10px;
  position: relative;
  height: 100%; }
  .product-card-container-mobile .product-card-header-mobile {
    display: flex;
    padding-left: 12px;
    padding-top: 50px; }
    .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile {
      display: flex;
      flex-direction: column;
      overflow: hidden; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: bold;
        margin-left: 12px; }
      .product-card-container-mobile .product-card-header-mobile .product-card-header-text-mobile span {
        color: #cc0000;
        font-size: 13px;
        margin-left: 12px; }
  .product-card-container-mobile .product-card-description-mobile {
    font-size: 12px;
    margin: 6px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
