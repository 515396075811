/** 
*   Holo Maint - Theme Colors
**/
.users-item-row {
  background: #ffffff;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .users-item-row .material-icons {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px; }
  .users-item-row .material-icons-closed {
    text-align: center;
    font-size: 24px;
    padding: 15px 15px;
    height: 100%;
    border-right: 1px solid #dedede;
    min-width: 66px;
    border-bottom: 1px solid #dedede; }
  .users-item-row div.users-item-row-info {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    margin-left: 12px;
    padding: 12px 12px; }
    @media screen and (max-width: 500px) {
      .users-item-row div.users-item-row-info {
        font-size: 13px; } }
  .users-item-row div.blocked-users-item-row-info {
    color: #aaaaaa;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    margin-left: 12px;
    padding: 12px 12px; }
    @media screen and (max-width: 500px) {
      .users-item-row div.blocked-users-item-row-info {
        font-size: 13px; } }
  .users-item-row p.users-item-row-name {
    margin: 0; }
  .users-item-row span.users-item-row-team {
    margin: 0;
    font-style: italic;
    font-size: 14px; }
  .users-item-row div.users-item-row-role {
    font-size: 11px; }
  .users-item-row .users-item-row-action {
    margin-left: auto;
    padding-top: 8px;
    padding-right: 24px; }
    .users-item-row .users-item-row-action .btn-group button.btn {
      background: none;
      border: 0;
      box-shadow: none;
      outline: 0;
      padding: 0; }
      .users-item-row .users-item-row-action .btn-group button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-item-row-action .btn-group button.btn i.material-icons {
        line-height: 1;
        border-right: none;
        width: 38px;
        min-width: 38px; }
  .users-item-row .users-edit-row-action {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    flex: 1; }
    .users-item-row .users-edit-row-action .btn-group {
      justify-self: auto; }
      .users-item-row .users-edit-row-action .btn-group button.btn {
        background: #ffffff;
        margin-left: auto;
        flex-wrap: wrap;
        align-self: stretch; }
        .users-item-row .users-edit-row-action .btn-group button.btn:focus {
          box-shadow: none; }
        .users-item-row .users-edit-row-action .btn-group button.btn i.material-icons {
          line-height: 1;
          border-right: none;
          width: 38px;
          min-width: 38px; }
  .users-item-row .users-tool-button {
    margin-left: auto;
    vertical-align: middle;
    text-align: center;
    display: flex;
    cursor: pointer; }
  .users-item-row .users-tool-bot-button {
    margin-left: auto;
    vertical-align: bottom;
    text-align: bottom;
    display: flex;
    cursor: pointer; }
  .users-item-row .users-tool-save-button {
    padding-top: 16px;
    padding-bottom: 16px;
    flex-wrap: wrap;
    margin-left: 12px;
    padding-right: 32px;
    align-self: stretch; }
    .users-item-row .users-tool-save-button button.btn {
      width: 100%;
      padding: 18px;
      padding-left: 24px;
      padding-right: 24px;
      flex-wrap: wrap; }
      .users-item-row .users-tool-save-button button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-tool-save-button button.btn i.material-icons {
        border-right: none;
        text-align: center;
        font-size: 24px; }
  .users-item-row .users-tool-reset-button {
    padding-bottom: 16px;
    flex-wrap: wrap;
    align-self: stretch;
    box-shadow: none;
    font-size: 16px; }
    .users-item-row .users-tool-reset-button button.btn {
      padding: 0px;
      min-width: auto;
      padding-left: 4px;
      padding-right: 4px; }
      .users-item-row .users-tool-reset-button button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-tool-reset-button button.btn i.material-icons {
        line-height: 1;
        border-right: none; }
  .users-item-row .users-tool-reset-button-toggled {
    padding-bottom: 16px;
    flex-wrap: wrap;
    align-self: stretch;
    box-shadow: none;
    font-size: 16px; }
    .users-item-row .users-tool-reset-button-toggled button.btn {
      flex-wrap: wrap;
      background: #ffffff;
      padding: 0px;
      min-width: auto;
      padding-left: 4px;
      padding-right: 4px; }
      .users-item-row .users-tool-reset-button-toggled button.btn:focus {
        box-shadow: none; }
      .users-item-row .users-tool-reset-button-toggled button.btn i.material-icons {
        line-height: 1;
        border-right: none; }
  .users-item-row span.file-item-row-date_created {
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    margin-left: auto;
    margin-right: 12px; }
