@import "../../scss/custom-variables";
@import "../../scss/breakpoints";

.app-page-header-container {
	background: $secondary;
	padding: 12px;
	margin-bottom: 12px;

	@media screen and (max-width: $break-mobile) {
		display: none;
	}
}
