@import "../../scss/breakpoints";

.troubleshooting-node {
	align-items: center;
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	cursor: pointer;
	display: flex;

	height: 64px;

	margin-top: 12px;
	margin-left: 0px;
	position: relative;

	.node-icon {
		font-size: 36px;
		margin-left: 18px;
		margin-right: 18px;
		flex: 0;
	}

	@media screen and (max-width: $break-mobile) {
		height: 48px;

		.node-icon {
			font-size: 24px;
			margin-left: 10px;
			margin-right: 10px;
			flex: 0;
		}
	}

	&.active {
		background: #eaeae9;
		box-shadow: 0 0 2px 0 inset black;
	}

	.node-text {
		font-size: 13px;
		width: 70%;
		overflow: hidden;
		margin-right: 12px;
		text-overflow: ellipsis;
		white-space: nowrap;
		flex: 1;
	}

	.troubleshooting-node-actions {
		margin-left: 0px;
		position: relative;
		height: 100%;

		@media screen and (max-width: $break-mobile) {
			display: none;
		}

		.troubleshooting-node-actions-button {
			background: transparent;
			border-left: 1px solid #e0e0e0;
			height: 100%;
			width: 48px;
		}
	}
}
