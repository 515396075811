.troubleshooting-answer-node-mobile {
	align-items: center;
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	padding: 0 24px;
	margin-top: 12px;
	min-height: 54px;

	.troubleshooting-answer-node-text-mobile {
		font-size: 12px;
		display: block;
		word-wrap: break-word;
		padding-top: 12px;
		padding-bottom: 12px;
		margin-top: 4px;
		margin-bottom: 4px;
		word-break: break-word;
	}
}

.troubleshooting-answer-node {
	align-items: center;
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	padding: 0 24px;
	margin-top: 12px;
	height: 54px;

	&:hover {
		background: #fafafa;
	}

	&.active {
		background: #eaeae9;
	}

	.troubleshooting-answer-node-key {
		font-size: 48px;
		margin-right: 24px;
	}

	.troubleshooting-answer-node-text {
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.troubleshooting-answer-node-actions {
		margin-left: auto;

		.app_icon_button {
			background: transparent;
			height: 100%;
			width: auto;

			i {
				font-size: 36px;
			}
		}
	}
}
