@import "../../scss/animation";
@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

.app-dropzone-rejected-files {
	margin-top: 12px;
}

.app-dropzone-outer {
	background: $white;
	border-radius: 4px;
	padding: 18px;

	.app-dropzone-container {
		border: 4px dashed transparent;
		border-radius: 4px;
		height: 64px;

		@media screen and (max-width: $break-mobile) {
			min-height: 100px;
		}

		&.is-dropping {
			border: 4px dashed $primary;
		}

		.app-dropzone-file-preview {
			border: 1px solid $light;
			position: relative;
			margin-bottom: 12px;

			.app-dropzone-file-preview-thumbnail {
				border-bottom: 1px solid $light;
				height: 65px;
				position: relative;
				width: 100%;

				@media screen and (max-width: $break-mobile) {
					height: 35px;
				}

				.app-dropzone-file-preview-progress {
					background: $danger;
					height: 12px;
					position: absolute;
					bottom: 0;
					left: 0;
					transition: width 500ms $animation-timing;
					width: 0px;
				}

				.app-dropzone-file-preview-thumbnail-img {
					object-fit: cover;
					object-position: center;
					height: 100%;
					width: 100%;
				}

				.app-dropzone-file-preview-thumbnail-icon {
					align-items: center;
					display: flex;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					justify-content: center;

					i.app_icon {
						font-size: 36px;
					}
				}
			}

			.app-dropzone-file-preview-body {
				padding: 12px 12px 12px 12px;
				position: relative;

				.app-dropzone-file-preview-filename {
					font-size: 13px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.app-dropzone-file-preview-filesize {
					font-size: 13px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.app-dropzone-info {
			align-items: center;
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: center;
			height: 46px;
			font-size: 12px;

			i.material-icons {
				font-size: 30px;

				@media screen and (max-width: $break-mobile) {
					font-size: 32px;
				}
			}

			.span {
				font-size: 12px;
			}
		}
	}
}
