@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

.product-detail-display-container {
	padding: 0 12px;
	position: relative;
	width: 70%;

	.product-details-image-mobile {
		display: block;
		max-height: 150px;
		min-height: 150px;
		object-fit: contain;
		position: relative;
		width: 100%;

		&:before {
			height: 100%;
			width: 100%;
			content: " ";
			display: block;
			position: absolute;
			background-image: url("/images/product-placeholder.gif");
			background-size: contain;
			background-position: center;
			background-color: #fff;
			background-repeat: no-repeat;
		}
	}

	.item-inputfield-text {
		text-overflow: ellipsis;
	}

	@media screen and (max-width: $break-medium) {
		width: 100%;
	}
}
