.card-header {
	.ticket-menu-button {
		margin-left: auto;
	}
}
.card-body {
	.generate-link-col-button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: flex-start;
		align-items: flex-start;
		padding-top: 8px;
	}
}
