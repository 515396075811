
@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

.companies-list {
	margin-top: 10px;
	overflow-y: scroll;
    max-height: 75vh;
}

.company-item-row {
	background: $white;
	// border: 1px solid #e0e0e0;
	margin-bottom: 10px;
	cursor: pointer;
	display: flex;

	&:active,
	&:hover {
		background: $light;
		.material-icons {
			border-right: 1px solid $body-bg;
		}
	}

	.material-icons {
		text-align: center;
		font-size: 24px;
		padding: 15px 15px;
		height: 100%;
		border-right: 1px solid $light;
		min-width: 66px;
	}

	span.company-item-row-name {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
		margin-left: 12px;
		padding: 15px 15px;
		line-height: 2.3;

		@media screen and (max-width: $break-mobile) {
			font-size: 13px;
		}
	}

	span.company-item-row-subname {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
		margin-left: 12px;
		padding: 15px 15px;
		line-height: 2.3;

		@media screen and (max-width: $break-mobile) {
			font-size: 11px;
		}
	}

	span.file-item-row-date_created {
		color: rgba(0, 0, 0, 0.56);
		font-size: 12px;
		margin-left: auto;
		margin-right: 12px;
	}
}
