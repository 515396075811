@import "../../scss/custom-variables";

.mobile-bottom-menu-bar {
	position: fixed;
	bottom: 0;
	height: 60px;
	left: 0;
	right: 0;
	z-index: 1028;
	touch-action: manipulation;

}

.layout-navbar {
	align-items: center;
	box-shadow: $shadow-1dp;
	background: $gray-dark;
	display: flex;
	justify-content: space-between;
	height: 60px;
	padding: 0 1rem;
	align-items: center;
}
.layout-navbar-files {
	align-items: center;
	box-shadow: $shadow-1dp;
	background: $gray-dark;
	display: flex;
	justify-content: space-around;
	height: 60px;
	padding: 0 1rem;
	align-items: center;
}
.bottom-item {
	flex-grow: 1;
}