@import "../../scss/custom-variables";
@import "../../scss/breakpoints";

.ongoing-call-container {
	-webkit-align-items: center;
    border: 1px solid #959595;
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    /* position: relative; */
    margin-bottom: 12px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: stretch;

	.ongoing-call-user-icon {
		border-right: 1px solid $gray;
		font-size: 24px;
		padding-left: 5px;
		padding-right: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.ongoing-call-button-container{
		display: flex;
		flex-direction: column;
	}

	.ongoing-call-phone-icon-accept {
		padding-left: 6px;
		padding-right: 6px;
		border: 1px solid $gray;
		
		background: $success;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		color: black;
		border-radius: 0;
	}

	.ongoing-call-phone-icon-decline {
		padding-left: 6px;
		padding-right: 6px;
		border: 1px solid $gray;
		
		background: $danger;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		color: black;
		border-radius: 0;
	}

	@media screen and (max-width: $break-mobile) {
		height: unset;

		.ongoing-call-phone-icon-accept {
			line-height: 46px;
			padding-left: 6px;
			padding-right: 6px;
		}

		.ongoing-call-phone-icon-decline {
		 	border: 1px solid $gray;
			line-height: 46px;
			padding-left: 6px;
			padding-right: 6px;
		}
		.ongoing-call-user-icon {
			line-height: 46px;
			padding-left: 6px;
			padding-right: 6px;
		}
	}

	&:hover {
		background: rgba(0, 0, 0, 0.05);
	}

	.ongoing-call-phone-icon-accept:hover {
		background: $success !important;
		
		color: white;	
	}

	.ongoing-call-phone-icon-decline:hover {
		background: $danger !important;
		
		color: white;	
	}


	.ongoing-call-text-container {
		display: flex;
		flex-direction: column;
		padding: 1.25rem;
		flex-grow: 1;

		.ongoing-call-info-container{
			display: grid;
			grid-template-columns: 1fr;
			flex-grow: 1;

		}
		p {
			width: auto;
    		white-space: nowrap;
    		overflow: hidden;
    		text-overflow: ellipsis;
			font-size: 14px;
			margin: 0;
		}

		span {
			font-size: 10px;
			text-transform: uppercase;
			text-align: end;
		}
	}
}
