/** 
*   Holo Maint - Theme Colors
**/
.browser-warning-container {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.browser-warning-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px; }

.browser-warning-icon i.app_icon {
  color: #959595;
  font-size: 48px; }

.browser-warning-body {
  margin-bottom: 12px; }

.browser-warning-compatible-browsers {
  display: flex;
  flex-direction: column;
  margin: 12px 0; }

.browser-warning-continue-button-container {
  margin-top: 18px; }

.browser-warning-browser-link {
  align-items: center;
  background: #dedede;
  border-radius: 4px;
  display: flex;
  padding: 8px 12px;
  margin-bottom: 8px; }
  .browser-warning-browser-link img {
    height: 34px;
    margin-right: 12px;
    width: 34px; }

.browser-warning-language-toggler-container {
  position: absolute;
  top: 12px;
  right: 12px; }
