.xr-overlay {
    /* force a transparent background */
    background: rgba(0,0,0,0) !important;

    /* act as containing block for descendants */
    contain: paint !important;

    /* the following styling is identical to :fullscreen */
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    min-width: 0 !important;
    max-width: none !important;
    min-height: 0 !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;

    /* intentionally not !important */
    object-fit: contain;
}

.div-elements {
    position: absolute; 
    margin: 16px; 
    top: calc(100% - 115px); 
    left: 50%;
}

.next-button-ar {
    left: calc(100% - 124px);
    top: calc(100% - 100px);
    height: 48px;
    width: 48px;
}

.next-button {
    left: 50px; 
    height: 48px; 
    width: 48px;

    .i {
        font-size: 24px; 
        transform: scaleX(-1);
    }
}

.vis-button-ar {
    left: calc(100% - 192px);
    top: calc(100% - 100px);
    height: 48px;
    width: 48px;
}

.vis-button {
    left: -5px; 
    height: 48px; 
    width: 48px;
    
    .i {
        font-size: 24px; 
        transform: scaleX(-1);
    }
}

.prev-button-ar {
    left: calc(100% - 260px);
    top: calc(100% - 100px);
    height: 48px;
    width: 48px;
}

.prev-button {
    left: -60px; 
    height: 48px; 
    width: 48px;
    
    .i {
        font-size: 24px; 
        transform: scaleX(-1);
    }
}

.play-pause-button-ar {
    left: calc(100% - 330px);
    top: calc(100% - 100px);
    height: 48px;
    width: 48px;
}

.play-pause-button {
    left: -115px; 
    height: 48px; 
    width: 48px;

    .i {
        font-size: 24px;
    }
}

.toast-style {
    min-width: 250px; 
    top: 25%; 
    left: 50%; 
    margin-right: -50%; 
    transform: translate(-50%, -50%); 
    background-color: #00000088; 
    color: #fff; 
    text-align: center; 
    border-radius: 22px; 
    padding: 16px; 
    position: absolute; 
    z-index: 1; 
    font-size: 17px;
}

.div-pdf {
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
     
    ::-webkit-scrollbar-thumb {
      background: #888; 
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
    width: 100%;
	flex-direction: column;
}