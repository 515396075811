@import "../../scss/breakpoints";

.item-image-display-container {
	padding: 0 12px 0 0;
	width: 100%;

	.item-image-display-container-edit-icon {
		padding-right: 0px;
		align-self: stretch;
		margin-right: 0px;
		margin-left: auto;
		cursor: pointer;
	}
}
