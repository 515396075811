@import "../../scss/custom-variables";

.layout-navbar {
	align-items: center;
	box-shadow: $shadow-1dp;
	background: $gray-dark;
	display: flex;
	height: 60px;
	padding: 0 1rem;

	.layout-navbar-mobile-title {
		font-size: 18px;
		margin-left: 6px;
		text-transform: capitalize;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: $secondary;
	}

	.right-container {
		align-items: center;
		display: flex;
		margin-left: auto;
	}
}

.layout-navbar-expert {
	align-items: center;
	box-shadow: $shadow-1dp;
	background: $white;
	display: flex;
	height: 60px;
	padding: 0 1rem;

	.layout-navbar-mobile-title {
		font-size: 18px;
		margin-left: 6px;
		text-transform: capitalize;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: $secondary;
	}

	.right-container {
		align-items: center;
		display: flex;
		margin-left: auto;
	}
}

.btn.select-role {
	background: none;
	box-shadow: none;
	border: none;
	border-radius: 0;
	color: $white;

	&:active,
	&:focus,
	&:hover {
		background: none;
		box-shadow: none;
		border: none;
	}
}
