.sub-companies-list {
    height: fit-content;
}

.scrollable-content-list-companies {
    height: fit-content;
    padding-right: 3px;
   
    max-height: 105px;
    margin-bottom:7px;
    margin-top: 7px;
    overflow-y: auto;
}

.list-group-item-size{
    overflow:hidden;  
    height:fit-content;
    margin-right:20px;
}

.custom-scrollbar::-webkit-scrollbar {
    border-style: visible;
    width: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: -3px;
    
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgb(204, 204, 204);
}
