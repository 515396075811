@import "../../scss/custom-variables";

.item-display-container {
	// width:300px;
	padding-top: 12px;
	padding-right: 10px;
	padding-left: 2px;
}
.item-display-container-mobile {
	flex-direction: column;
}

.product-card-container {
	background: $white;
	padding-bottom: 5px;
	position: relative;
	height: 100%;
	padding-left: 6px;
	border-radius: 10px;

	.product-card-header {
		display: flex;
		padding-top: 12px;

		.product-card-header-button-container {
			margin-left: auto;
			margin-right: 12px;

			.btn-group {
				button.btn {
					background: none;
					border: 0;
					box-shadow: none;
					outline: 0;
					padding: 0;

					i.material-icons {
						line-height: 1;
					}
				}
			}
		}

		.product-card-header-text {
			display: flex;
			flex-direction: column;
			overflow: hidden;

			p {
				margin: 0;
				padding: 0;
				font-size: 15px;
				font-weight: bold;
				margin-left: 12px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			span {
				color: $danger;
				font-size: 13px;
				margin-left: 12px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.app_icon_button {
			margin-left: auto;
			margin-right: 6px;

			.material-icons.app_icon {
				line-height: 1;
			}
		}
	}

	.product-card-description {
		font-size: 12px;
		margin: 6px 12px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

.product-card-container-mobile {
	display: flex;
	background: $white;
	margin-bottom: 10px;
	position: relative;
	height: 100%;

	.product-card-header-mobile {
		display: flex;
		padding-left: 12px;
		padding-top: 50px;

		.product-card-header-text-mobile {
			display: flex;
			flex-direction: column;
			overflow: hidden;

			p {
				margin: 0;
				padding: 0;
				font-size: 15px;
				font-weight: bold;
				margin-left: 12px;
			}

			span {
				color: $danger;
				font-size: 13px;
				margin-left: 12px;
			}
		}
	}

	.product-card-description-mobile {
		font-size: 12px;
		margin: 6px 12px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
