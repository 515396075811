.slider-scroll-container {
	top: calc(100% - 60px);
	align-items: center;
	display: flex;
	flex-direction: row;
	position: absolute;
	justify-content: center;
	
}

.slider-scroll {
	width: 240px;
	height: 25px;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
	cursor:pointer;
}

.switch-page-button {
	width: 42px !important;
	height: 42px !important;
	align-items: center !important;
	top: 50% !important;

	&#next-btn {
		left: calc(100% - 42px - 1%) !important;
	}

	&#prev-btn {
		margin-left:1%;
		left: 0 !important;
	}
}

.pageCounter{
	border-radius: 20px;
	background-color: white;
    padding: 0 0.5rem;
	color: black;
	// text-shadow: 0px 0px 6px black;
}

#current-model{
	#pdf-render-canvas {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}
}

#current-model-2 {
	@media screen and (orientation: landscape){
		.slider-scroll-container {
			left: calc(50% - 25vw);
			top: calc(100% - 60px);
			justify-content: space-evenly;
		}

		.slider-scroll {
			width: 50vw;
		}
	}

	@media screen and (orientation: portrait){
		.slider-scroll-container {
			left: calc(50% - 40vw);
			justify-content: space-evenly;
		}

		.slider-scroll {
			width: 70vw;
		}
	}

	.switch-page-button {
		width: 42px !important;
		height: 42px !important;
		align-items: center !important;
		top: 50% !important;
	
		&#next-btn {
			left: calc(100vw - 42px - 1%) !important;
		}
	
		&#prev-btn {
			margin-left:1%;
			left: 0 !important;
		}
	}

	.react-transform-component {
		@media screen and (orientation: landscape) {
			width: unset;
		}

		@media screen and (orientation: portrait) {
			width: unset;
		}
	}

	.react-transform-element {
		@media screen and (orientation: landscape) {
			width: min-content;
			transform: translate(200px, 0px) scale(1);
		}

		// @media screen and (orientation: portrait) {
		// 	transform: unset !important;
		// }
	}

	#pdf-render-canvas {
		@media screen and (orientation: landscape) {
			// position: relative;
			height: 100vh;
		}

		@media screen and (orientation: portrait) {
			width: 100%;
			height: 100%;
		}
	}
}