.save-overlay-container {
	align-items: center;
	background: rgba(255, 255, 255, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 99;

	.save-overlay-text {
		font-size: 24px;
		margin-top: 24px;
	}

	.save-overlay-spinner {
		height: 48px;
		width: 48px;
	}
}
