@import "../../scss/breakpoints";

.troubleshooting-actions-container {
	display: flex;

	@media screen and (max-width: $break-mobile) {
		display: none;
	}
}

.troubleshooting-actions-container-buttons {
	align-items: center;
	background: transparent;
	display: flex;
	margin-left: auto;

	.disabled {
		cursor: not-allowed;
	}
}
