.card {
	border: $Color-none;
	background-color: $white;

	.card-header {
		background-color: rgba(0, 0, 0, 0);
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}

	.card-footer {
		background-color: $light;
		border-top: none;
		min-height: 20px;
	}
}

.card-header {
	align-items: center;
	display: flex;
	min-height: 62px;

	.button-container {
		margin-left: auto;
	}
}
