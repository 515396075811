@import "../../scss/custom-variables";

.nav-home {
	color: $white;
	background: transparent;
	padding: 12px 0;
}

.nav-bottom {
	color: $white;
	background: transparent;
	padding: 0 0;
}

.nav-bottom-landscape {
	padding: "18px 0px";
	margin-right: 6px;
	color: $white;
	background: transparent;
	padding: 0 0;
}

.nav-bottom-mobile {
	background-color: transparent;
}
