/** 
*   Holo Maint - Theme Colors
**/
@keyframes whiteFlash {
  0% {
    display: block;
    opacity: 1; }
  95% {
    opacity: 0; }
  100% {
    display: none; } }

@keyframes pulsate {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.1; }
  100% {
    opacity: 1; } }

@keyframes showcam {
  from {
    opacity: 0;
    transform: translateY(-15px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes hidecam {
  from {
    opacity: 1;
    transform: translateY(0); }
  to {
    opacity: 0;
    transform: translateY(15px); } }

.camera-selected-indicator {
  right: 16px;
  top: 22px;
  height: 16px;
  width: 16px;
  background-color: #b9c900;
  border-radius: 50%;
  margin: 0 12px;
  display: inline-block;
  position: absolute;
  font-size: 70%;
  font-weight: 700;
  text-align: center;
  z-index: 999; }

.video_view {
  position: relative;
  width: 100%;
  height: 0; }
  .video_view #swap-camera-button {
    align-items: center;
    right: 18px;
    top: 24px;
    background: #fff;
    border: 4px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    margin: 0 12px;
    outline: none;
    justify-content: center;
    position: absolute;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 998;
    height: 46px;
    width: 46px; }
    .video_view #swap-camera-button i.material-icons {
      color: #343a40;
      font-size: 24px;
      line-height: 1;
      user-select: none; }
  .video_view #change-resolution-button {
    align-items: center;
    right: 18px;
    top: 80px;
    background: #fff;
    border: 4px solid #dedede;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    margin: 0 12px;
    outline: none;
    justify-content: center;
    position: absolute;
    transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 998;
    height: 46px;
    width: 46px; }
    .video_view #change-resolution-button i.material-icons {
      color: #343a40;
      font-size: 24px;
      line-height: 1;
      user-select: none; }
  .video_view .remote_view_view {
    position: absolute;
    border: 2px solid #dedede;
    width: 40%;
    top: 10px;
    left: 10px;
    z-index: 999; }
    @media (orientation: landscape) {
      .video_view .remote_view_view {
        width: 15%; } }
  .video_view .remote_view_hidden {
    visibility: hidden; }
  .video_view .spinner {
    color: #fff;
    position: absolute;
    top: 30vh;
    left: calc(50vw - 45px);
    height: 90px;
    width: 90px; }

#current-model-2 .react-transform-component {
  overflow: visible; }

.dropdown-resolution {
  position: inherit;
  display: inline-block; }

.dropdown-resolution-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  right: 0px;
  top: calc(100% + 123px);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999; }

.dropdown-resolution a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

.dropdown-resolution-content a:hover {
  background-color: #ccc; }

.dropdown-resolution:hover .dropdown-resolution-content {
  display: block; }

.active-call-page-localvideo-container-expanded {
  background: #343434;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0;
  top: 0;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  z-index: 1031; }
  .active-call-page-localvideo-container-expanded .mobile-caller-localvideo-container {
    background: transparent; }
  .active-call-page-localvideo-container-expanded .mobile-caller-localvideo-element {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media (orientation: landscape) {
      .active-call-page-localvideo-container-expanded .mobile-caller-localvideo-element {
        width: 100%;
        height: auto; } }
  .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu {
    align-items: center;
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0;
    justify-content: space-between;
    right: 0;
    padding: 22px; }
    .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section {
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      width: 100%; }
      .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
        align-items: center;
        background: #fff;
        border: 4px solid #dedede;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        margin: 0 12px;
        outline: none;
        opacity: 1;
        justify-content: center;
        position: relative;
        transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
        height: 64px;
        width: 64px; }
        @media screen and (max-width: 700px) {
          .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
            height: 46px;
            width: 46px; } }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
          height: 86px;
          width: 86px; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
              height: 64px;
              width: 64px; } }
          .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
            font-size: 36px; }
            @media screen and (max-width: 700px) {
              .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
                font-size: 36px; } }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-red i.material-icons {
          color: #cc0000;
          user-select: none; }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-green i.material-icons {
          color: #b9c900;
          user-select: none; }
        .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
          color: #343a40;
          font-size: 28px;
          line-height: 1;
          user-select: none; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container-expanded .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
              font-size: 20px; } }
  .active-call-page-localvideo-container-expanded video.active-call-page-localvideo-element {
    border-radius: 4px;
    height: 100%;
    object-position: center;
    object-fit: contain;
    width: 100%; }
  .active-call-page-localvideo-container-expanded .active-call-page-modal-container {
    animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: forwards;
    background: #232323;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999; }
    .active-call-page-localvideo-container-expanded .active-call-page-modal-container.hidden {
      visibility: hidden;
      opacity: 0;
      z-index: -1; }
    .active-call-page-localvideo-container-expanded .active-call-page-modal-container button {
      align-items: center;
      background: #fff;
      border: 2px solid #dedede;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      outline: none;
      justify-content: center;
      padding: 0;
      opacity: 1;
      z-index: 99999;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 26px;
      width: 26px;
      transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .active-call-page-localvideo-container-expanded .active-call-page-modal-container button i.material-icons {
        color: #343a40;
        font-size: 18px;
        line-height: 1;
        user-select: none; }

.active-call-page-localvideo-container {
  background: #343434;
  position: fixed;
  width: 100%;
  height: calc(100vh - 120px);
  bottom: 60px;
  left: 0;
  top: 60px;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0; }
  .active-call-page-localvideo-container .mobile-caller-localvideo-container {
    background: transparent; }
  .active-call-page-localvideo-container .mobile-caller-localvideo-element {
    width: auto;
    height: 100%; }
  .active-call-page-localvideo-container .active-call-page-localvideo-menu {
    align-items: center;
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 60px;
    left: 0;
    justify-content: space-between;
    right: 0;
    padding: 22px; }
    .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section {
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      width: 100%; }
      .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
        align-items: center;
        background: #fff;
        border: 4px solid #dedede;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        margin: 0 12px;
        outline: none;
        opacity: 1;
        justify-content: center;
        position: relative;
        transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
        height: 64px;
        width: 64px; }
        @media screen and (max-width: 700px) {
          .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button {
            height: 46px;
            width: 46px; } }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
          height: 86px;
          width: 86px; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg {
              height: 64px;
              width: 64px; } }
          .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
            font-size: 36px; }
            @media screen and (max-width: 700px) {
              .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.lg i.material-icons {
                font-size: 36px; } }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-red i.material-icons {
          color: #cc0000;
          user-select: none; }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button.icon-green i.material-icons {
          color: #b9c900;
          user-select: none; }
        .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
          color: #343a40;
          font-size: 28px;
          line-height: 1;
          user-select: none; }
          @media screen and (max-width: 700px) {
            .active-call-page-localvideo-container .active-call-page-localvideo-menu .active-call-page-localvideo-menu-section button i.material-icons {
              font-size: 20px; } }
  .active-call-page-localvideo-container video.active-call-page-localvideo-element {
    border-radius: 4px;
    height: 100%;
    object-position: center;
    object-fit: contain;
    width: 100%; }
  .active-call-page-localvideo-container .active-call-page-modal-container {
    animation: showcam 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: forwards;
    background: #232323;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 999; }
    .active-call-page-localvideo-container .active-call-page-modal-container.hidden {
      visibility: hidden;
      opacity: 0;
      z-index: -1; }
    .active-call-page-localvideo-container .active-call-page-modal-container button {
      align-items: center;
      background: #fff;
      border: 2px solid #dedede;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      outline: none;
      justify-content: center;
      padding: 0;
      opacity: 1;
      z-index: 99999;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 26px;
      width: 26px;
      transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
      .active-call-page-localvideo-container .active-call-page-modal-container button i.material-icons {
        color: #343a40;
        font-size: 18px;
        line-height: 1;
        user-select: none; }
