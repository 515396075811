@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

.documentation-menu {
	.doc-tool-sort-button {
		padding-left: 8px;
		padding-right: 8px;
		text-align: center;
	}

	.doc-tool-sort-button-flip {
		transform: scaleY(-1);
		padding-left: 8px;
		padding-right: 8px;
		text-align: center;
	}

	.doc-tool-sort-column {
        column-gap: 10px;
	}
	.doc-tool-sort-text:disabled {
		background: white;
		text-decoration-color: black;
		border: 0;
		box-shadow: none;
		outline: 0;
		padding: 16px, 16px;
		padding-right: 24px;
		height: 50px;
		background-color: transparent;

		&:hover {
			background: white;
			background-color: transparent;
		}

		&:focus {
			outline: none;
			box-shadow: none;
			background-color: transparent;
		}
	}

	.doc-tool-sort-text {
		padding: 8px;
		padding-right: 16px;
	}

	.doc-tool-delete-text {
		float: right;
		padding: 8px;
		padding-right: 16px;
	}
}