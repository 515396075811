@import "../../scss/breakpoints";
@import "../../scss/custom-variables";

.users-list-container {
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: $break-mobile) {
		padding-top: 12px;

		.users-list-actionbar {
			button {
				width: 100%;
			}
		}
	}
	
	.doc-tool-sort-button {
		padding-left: 8px;
		padding-right: 8px;
		text-align: center;
	}

	.doc-tool-sort-button-flip {
		transform: scaleY(-1);
		padding-left: 8px;
		padding-right: 8px;
		text-align: center;
	}

	.users-list-actionbar {
		display: flex;
		justify-content: space-between;
	}

	.users-list-items {
		display: flex;
		flex-wrap: wrap;

		.item-display-container {
			height: 270px;
			width: 25%;

			@media screen and (max-width: $break-large) {
				width: 33.3%;
			}

			@media screen and (max-width: $break-medium) {
				width: 50%;
			}

			@media screen and (max-width: $break-small) {
				width: 80%;
			}
		}
	}
}
.users-list {
	padding-top: 10px;
}
