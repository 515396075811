/** 
*   Holo Maint - Theme Colors
**/
.settings-container {
  padding: 12px; }

.setting-display-container {
  padding: 12px; }

.settings-card-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-bottom: 24px;
  padding-bottom: 12px;
  position: relative;
  height: 100%; }
  .settings-card-container .settings-card-header {
    padding-top: 12px; }
    .settings-card-container .settings-card-header .settings-card-header-text p {
      margin: 12px 0;
      padding: 0;
      font-size: 15px;
      font-weight: bold;
      margin-left: 12px; }
    .settings-card-container .settings-card-header .settings-card-header-text span {
      color: #cc0000;
      font-size: 13px;
      margin-left: 12px; }
  .settings-card-container .settings-card-description {
    font-size: 12px;
    margin: 12px 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .settings-card-container .settings-card-content {
    display: flex; }
