@import "../../scss/custom-variables";

.mobile-navlink {
	align-items: center;
	color: $light;
	display: flex;
	padding: 12px 18px;
	text-decoration: none;

	&:hover {
		background: $gray;
		color: $primary;
		text-decoration: none;
	}

	&.active {
		background: $body-bg;
		color: $primary;
		text-decoration: none;

		.material-icons.app_icon {
			color: $primary;
		}
	}

	span.mobile-navlink-text {
		white-space: nowrap;
	}
	span.mobile-navlink-landscape-text {
		flex-direction: row;
		white-space: nowrap;
	}
	.material-icons.app_icon {
		margin-right: 18px;
	}
}

.mobile-operator-navlink {
	align-items: center;
	flex-direction: column;
	color: $light;
	display: flex;
	padding: 12px 9px;
	text-decoration: none;
	font-size: 8px;
	background-color: transparent;

	&:hover {
		background: $gray;
		color: $primary;
		text-decoration: none;
	}

	&.active {
		background: $body-bg;
		color: $primary;
		text-decoration: none;

		.material-icons.app_icon {
			color: $primary;
		}
	}

	span.mobile-navlink-text {
		white-space: nowrap;
	}
	span.mobile-navlink-landscape-text {
		flex-direction: row;
		white-space: nowrap;
	}
	.material-icons.app_icon {
		margin-right: 18px;
	}
}

.mobile-navlink-landscape {
	align-items: center;
	flex-direction: row;
	color: $light;
	display: flex;
	padding: 12px 9px;
	text-decoration: none;
	font-size: 12px;
	background-color: transparent;
	
	&:hover {
		background: $gray;
		color: $primary;
		text-decoration: none;
	}

	&.active {
		background: $body-bg;
		color: $primary;
		text-decoration: none;

		.material-icons.app_icon {
			color: $primary;
		}
	}	
}
