@import "../../scss/breakpoints";
modal .modal-dialog.team-link-products-modal {
	max-width: 60%;

	@media screen and (max-width: $break-mobile) {
		max-width: 100%;

		.table {
			font-size: 12px;
		}
	
	}
}

.modal .modal-dialog .modal-content .modal-body.team-link-products-modal-body {
	padding: 0;    
	th{
		vertical-align: middle;
	}

	.no-border-table td{
		vertical-align: middle;
		border-top: none;
	}
	.small-thumbnail{
		min-width: 70px;
		min-height: 40px;
		height: 6vh;
		width: 6vw;
	}
	.small-product-code{
		font-size: 12px;
	}
}

