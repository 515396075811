@import "../../scss/breakpoints";

.file-item-row {
	align-items: center;
	background: #fff;
	border-right: 1px solid #e0e0e0;
	border-left: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
	cursor: pointer;
	display: flex;
	height: 50px;
	margin-bottom: 10px;

	.file-item-row-image-thumb {
		object-fit: cover;
		object-position: center;
		height: 50px;
		width: 50px;
	}

	&:hover {
		background: #f3f3f3;
	}

	&:active {
		background: #dedede;
	}

	&:first-child {
		border-top: 1px solid #e0e0e0;
	}

	.material-icons {
		line-height: 50px;
		font-size: 24px;
		padding: 0 13px;
	}

	span.file-item-row-filename {
		border-left: 1px solid #e0e0e0;
		overflow: hidden;
		white-space: nowrap;
		line-height: 50px;
		text-overflow: ellipsis;
		flex: 1;
		padding-left: 12px;

		@media screen and (max-width: $break-mobile) {
			font-size: 13px;
		}
	}

	span.file-item-row-date_created {
		color: rgba(0, 0, 0, 0.56);
		font-size: 12px;
		margin-left: 12px;
		margin-right: 12px;
	}
}
